<template>
    <div>

        <app-section
            tag="header"
            :fullheight="true"

        >
            <template v-slot:before>
                <blob class="p-error__blob p-error__blob--1" :style="blobsStyles" :parallax="1" />
                <blob class="p-error__blob p-error__blob--2" :style="blobsStyles" :parallax="-.7" />
                <blob class="p-error__blob p-error__blob--3" :style="blobsStyles" :parallax="-2" />
            </template>

            <heading
                :header="page.heading"
                :center="true"
                titleClass="t-t1-serif"
                class="p-error__heading"
            >
            </heading>

            <div
                class="p-error__content | t-cms"
                v-reveal="true"
            >
                <btn
                    tag="router-link"
                    :href="'home'|resolver"
                    label="Go to the homepage"
                    :arrowUnder="true"
                    v-reveal="true"
                />
            </div>

        </app-section>

        <push />

    </div>
</template>

<script>

import AppSection from 'layout/AppSection';

import Blob from 'objects/Blob';

import Btn from 'components/Btn';
import Push from 'components/Push';
import Heading from 'components/Heading';

export default {
    name: 'Error404',
    components: {
        AppSection,
        Blob,
        Btn,
        Push,
        Heading
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: this.page.seo.title
        }
    },
    data: () => ({
        blobsStyles: '--blob-bg: var(--gradient-orange)'
    })
};

</script>

<style lang="scss">
.p-error404 {
    .c-push__bg {
        background-image: var(--gradient-orange);
    }
}
</style>

<style lang="scss" scoped>

.p-error {

    &__content {
        margin-top: 10vh;

        transition: opacity 0.3s ease 0.4s, transform 0.3s $in-out-sine 0.4s;
        opacity: 0;
        transform: translateY(100%);

        &.is-visible {
            opacity: 1;
            transform: translateY(0%);
        }
    }
}

.p-error__blob {
    position: absolute;

    &--1 {
        --size: 40vw;
        top: calc(50% - var(--size)/2);
        left: calc(50% - var(--size)/2);
    }

    &--2 {
        --size: 15%;
        top: 65%;
        right: 10%;
    }

    &--3 {
        --size: 10%;
        top: 90%;
        left: 10%;
    }

    @media #{md("xl")} {

        &--1 {
            --size: 35vw;
        }
    }
}
</style>
