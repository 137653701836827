<template>
    <div>
        <app-section>

            <heading
                :header="heading"
                :asset="asset"
                class="p-service__heading"
            >
                <template v-slot:after>
                    <anim-text
                        v-if="page.desc !== null"
                        :cms="true"
                        :text="page.desc"
                        class="p-service__desc"
                    />
                </template>
            </heading>

        </app-section>

        <builder :builder="page.builder" />

        <push />

    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import AnimText from 'objects/AnimText';
import Heading from 'components/Heading';
import Push from 'components/Push';
import Builder from 'builder/Builder';

import transforms from 'src/graphql/asset-transforms'

export default {
    name: 'Service',
    components: {
        AppSection,
        AnimText,
        Heading,
        Builder,
        Push,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: this.page.seo.title
        }
    },
    computed: {
        heading() {
            return {
                sup: 'Services',
                supLink: 'services',
                mainTitle: this.page.title,
                desc: this.page.customTitle,
            }
        },
        asset() {
            let image = this.page.image
            if(image && image.length > 0) {
                image = image[0]
                return {
                    url: image.portrait,
                    title: image.title,
                    width: transforms.portrait.width,
                    height: transforms.portrait.height,
                }
            } else {
                return false
            }
        }
    }
};

</script>

<style lang="scss">

.p-service {}

.p-service__desc {
    padding-top: calc(2 * var(--grid-gutter));
    border-top: $border-grey;

    > p strong {
        font-size: .75em;
        font-weight: 700;
        text-transform: uppercase;

        @media #{md("sm")} {
            font-size: .8125em;
        }
    }

    @media #{md("xs")} {
        width: calc(3/4 * 100%);
    }

    @media #{md("sm")} {
        width: calc(5/6 * 100%);
    }
}


</style>
