<template>
    <component
        :is="tag"
        :class="className"
    >

        <slot name="before" />

        <div class="grid">
            <div class="l-section__content">
                <slot />
            </div>
        </div>

        <slot name="after" />

    </component>
</template>

<script>

export default {
    name: 'AppSection',
    props: {
        tag: {
            type: String,
            default: 'section',
        },
        fullheight: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        className() {
            let classname = 'l-section'

            if (this.fullheight) {
                classname += ' l-section--fullheight'
            }

            return classname
        },
    },
};

</script>

<style lang="scss">

.l-section {

    @media #{md("sm", "max")} {
        padding-bottom: calc( var(--section-padding) / 2);

        &:not(:first-of-type) {
            padding-top: calc( var(--section-padding) / 2);
        }
    }

    padding-bottom: var(--section-padding);
    padding-top: var(--section-padding);


    &--fullheight {

        min-height: 50vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    @media (orientation: landscape) {

        &--fullheight {
            min-height: 100vh;
        }
    }
}

</style>
