<template>
    <div class="c-push">

        <app-canvas v-if="!this.bg" />

        <span
            v-else
            class="c-push__bg | u-full-width"
        ></span>

        <div class="grid">
            <div class="c-push__inner | t-content">
                <anim-text
                    tag="h3"
                    :text='title'
                    :class="[ bg ? 't-t2-serif' : 't-t1-serif']"
                />
                <btn
                    v-if="bg"
                    tag="router-link"
                    href="/contact"
                    title="Talk to us"
                    label="Talk to us"
                    :arrowUnder="true"
                />
            </div>
        </div>
    </div>
</template>

<script>

import AnimText from 'objects/AnimText';
import AppCanvas from 'objects/AppCanvas';
import Btn from 'components/Btn'

export default {
    name: 'Push',
    components: {
        AnimText,
        AppCanvas,
        Btn,
    },
    props: {
        bg: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        title() {
            return this.bg ? this.$store.state.global.general.customTitle : 'Brighter days are ahead'
        },
    },
};

</script>

<style lang="scss">

.c-push {

    .o-canvas {
        height: 100%;
    }
}

.c-push__bg {
    position: absolute;
    top: 0;
    display: block;
    height: 100%;
    background-image: var(--main-gradient);
}

.c-push__inner {
    padding-top: 20vh;
    padding-bottom: 20vh;
    text-align: center;
    width: 100%;

    @media #{md("xs")} {
        width: calc(5/6 * 100%);
        margin-right: auto;
        margin-left: auto;
    }

    @media #{md("sm")} {
        width: calc(2/3 * 100%);
    }

    @media #{md("lg")} {
        width: calc(1/2 * 100%);
    }
}

</style>
