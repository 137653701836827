<template>
    <span
        :class="className"
        v-reveal="reveal ? revealHandler : false"
    >
        <span class="o-deco__inner">
            <svg
                v-if="deco === 'curved-down'"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 31 69"
            >
                <path d="M14.2,8.7C19.1,3.9,25,0.9,31,0.9V0c-6.3,0-12.4,3.1-17.4,8.1c-5,4.9-9.1,11.7-11.4,19c-4.6,14.4-2.6,31,11.8,40.3l-2.8,0.7c-0.2,0.1-0.4,0.3-0.3,0.5c0.1,0.2,0.3,0.4,0.5,0.3l3.8-0.9c0.2-0.1,0.4-0.3,0.3-0.5l-0.9-3.8c-0.1-0.2-0.3-0.4-0.5-0.3c-0.2,0.1-0.4,0.3-0.3,0.5l0.7,2.8C0.5,57.7-1.5,41.5,3,27.4C5.3,20.2,9.3,13.5,14.2,8.7z"/>
            </svg>
            <svg
                v-else-if="deco === 'down-right'"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 66 98"
            >
                <path d="M65.2,92.5c0-0.3-0.3-0.4-0.6-0.4c-0.3,0-0.4,0.3-0.4,0.5l0.5,3C43.2,80.2,14.1,43.4,0.9,0L0,0.3c13.3,43.6,42.5,80.6,64.3,96.2l-3.1,0.6c-0.3,0-0.4,0.3-0.4,0.5c0,0.3,0.3,0.4,0.6,0.4l4.2-0.7c0.3,0,0.4-0.3,0.4-0.5L65.2,92.5z"/>
            </svg>
            <svg
                v-else-if="deco === 'line-down'"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 72 106"
            >
                <path d="M71.8,106l-1-0.1C74.6,58.6,26.2,16.5,0,0.8L0.5,0C26.8,15.8,75.7,58.2,71.8,106z"/>
            </svg>
            <svg
                v-else-if="deco === 'up-right'"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 80 365"
            >
                <path d="M79.4,0.4c0-0.3-0.3-0.4-0.5-0.4l-4.2,0.6c-0.3,0-0.4,0.3-0.4,0.5c0,0.3,0.3,0.4,0.5,0.4l3.1-0.4C61.7,22.9,52.6,51.3,47.2,82.8c-5.5,31.9-7.2,67.1-8.9,102.1c-1.7,35-3.3,69.7-8.4,100.8C24.7,316.8,16,344.1,0,364.4l0.7,0.6c16.1-20.5,24.9-48,30.1-79.2c5.2-31.2,6.8-66,8.4-100.9c1.7-35,3.4-70.1,8.9-102c5.4-31.4,14.4-59.6,30.5-81.2l0.4,3c0,0.3,0.3,0.4,0.5,0.4c0.3,0,0.4-0.3,0.4-0.5L79.4,0.4z"/>
            </svg>
            <svg
                v-else-if="deco === 'sparkle'"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 35 37"
            >
                <path d="M0.2,37L0,36.2c6.7-1.4,13.9-2.1,21.3-2.3l0,0.8C13.9,34.9,6.8,35.7,0.2,37z"/>
                <path d="M22.2,23.2c-3.9-3.7-7.9-7.5-11.7-11.1l0.5-0.6l-0.3,0.3l0.3-0.3c3.9,3.6,7.9,7.4,11.7,11.1L22.2,23.2z"/>
                <path d="M35,20.1l-0.8,0c0.2-6.6-0.7-13.3-2.4-19.8L32.5,0C34.3,6.7,35.1,13.4,35,20.1z"/>
            </svg>
        </span>
    </span>
</template>

<script>

import Blob from 'objects/Blob'

export default {
    name: 'Deco',
    components: {
        Blob
    },
    props: {
        deco: {
            type: String,
            required: true,
        },
        reveal: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isVisible: false,
    }),
    created() {
        this.isVisible = this.visible
    },
    methods: {
        revealHandler(state) {
            this.isVisible = state.isActive
        }
    },
    computed: {
        className() {
            let classname = 'o-deco'

            classname += ` -${this.deco}`

            if(this.isVisible) {
                classname += ' is-visible'
            }

            return classname
        },
    },
    watch: {
        visible(visible) {
            this.isVisible = visible
        }
    }
};

</script>

<style lang="scss">

.o-deco {
    --a-ad-in-delay: 0s;
    --a-ad-in-ease: #{$in-out-sine};
    --a-ad-in-duration: .6s;
    --a-ad-out-delay: 0s;
    --a-ad-out-ease: #{$in-sine};
    --a-ad-out-duration: 0s;

    --svg-width: 0;
    --svg-height: 0;

    display: block;

    &.-curved-down {
        --svg-width: 2em;
        --svg-height: calc(69/31 * var(--svg-width));
    }

    &.-down-right {
        --svg-width: 4.85em;
        --svg-height: calc(98/68 * var(--svg-width));
    }

    &.-line-down {
        --svg-width: 4.5em;
        --svg-height: calc(106/72 * var(--svg-width));
    }

    &.-up-right {
        --svg-width: 4em;
        --svg-height: calc(365/80 * var(--svg-width));
    }

    &.-sparkle {
        --svg-width: 2.75em;
        --svg-height: calc(37/35 * var(--svg-width));

        .o-deco__inner {

            @supports (clip-path: polygon(0 0, 100% 0, 100% 0, 0 0)) {
                clip-path: polygon(100% 100%, 100% 100%, 100% 100%, 100% 100%);
            }
        }
    }

    &.is-visible {

        .o-deco__inner {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            transition: clip-path var(--a-ad-in-duration) var(--a-ad-in-ease) var(--a-ad-in-delay);
        }

        @supports (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%)) {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            transition: clip-path var(--a-ad-in-duration) var(--a-ad-in-ease) var(--a-ad-in-delay);
        }

        @supports not (clip-path: polygon(0 0, 100% 0, 100% 0, 0 0)) {
            opacity: 1;
            transition: opacity var(--a-ad-in-duration) var(--a-ad-in-ease) var(--a-ad-in-delay);
        }

    }

    @media #{md("sm", "max")} {
        display: none;
    }
}

.o-deco__inner {
    display: block;

    @supports (clip-path: polygon(0 0, 100% 0, 100% 0, 0 0)) {
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
        transition: clip-path var(--a-ad-out-duration) var(--a-ad-out-ease) var(--a-ad-out-delay);
    }

    @supports not (clip-path: polygon(0 0, 100% 0, 100% 0, 0 0)) {
        opacity: 0;
        transition: opacity var(--a-ad-out-duration) var(--a-ad-out-ease) var(--a-ad-out-delay);
    }

    svg {
        display: block;
        width: var(--svg-width);
        height: var(--svg-height);
        fill: $color-dark;
    }

    html.reduced-motion & {
        transition: none;

        @supports (clip-path: polygon(0 0, 100% 0, 100% 0, 0 0)) {
            clip-path: none;
        }

        @supports not (clip-path: polygon(0 0, 100% 0, 100% 0, 0 0)) {
            opacity: 1;
        }
    }
}

</style>
