<template>
    <i :class="className">
        <blob
            v-if="blob"
            modifier="border"
        />
        <svg :class="`svg-${icon}`">
            <title v-if="title">{{ title }}</title>
            <use v-bind:xlink:href="path"></use>
        </svg>
    </i>
</template>

<script>

import Blob from 'objects/Blob'

export default {
    name: 'Icon',
    components: {
        Blob
    },
    props: {
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: '',
        },
        blob: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        path() {
            return `/dist/svg/sprite.svg#svg-${this.icon}`;
        },
        className() {
            let classname = `o-icon o-icon--${this.icon}`

            if (this.size) {
                classname += ` o-icon--${this.size}`
            }

            if (this.blob) {
                classname += ' o-icon--blob'
            }

            return classname;
        }
    },
};

</script>

<style lang="scss">

.o-icon {
    display: inline-block;
    vertical-align: middle;

    &--blob {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.5em;
        height: 3.5em;

        .o-blob {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    svg {
        display: block;
        fill: currentColor;
    }

    &--arrow-simple-left,
    &--arrow-simple-right {
        --svg-width: .25em;
        --svg-height: calc(7/4 * var(--svg-width));
    }

    &--arrow-stroke-down svg,
    &--arrow-stroke-right svg {
        fill: none;
        stroke-width: 2px;
        stroke: currentColor;
    }
}


/*----------  SVG Sizes  ----------*/

// Logo
.svg-logo {
    width: 13.75em;
    height: 1.77em;
}

.svg-logo-osl {
    width: 2.14em;
    height: .9em;
}

// Arrows

.svg-arrow-simple-left,
.svg-arrow-simple-right {
    width: var(--svg-width);
    height: var(--svg-height);
}

.svg-arrow-up-right {
    width: .75em;
    height: .72em;
}

.svg-arrow-left,
.svg-arrow-right {
    width: .9em;
    height: 1em;
}

.svg-arrow-up,
.svg-arrow-down {
    width: 1em;
    height: .9em;
}

.svg-arrow-stroke-down,
.svg-arrow-stroke-right {
    width: 1.875em;
    height: 1.875em;
}

// Misc
.svg-sunset {
    width: 1.875em;
    height: 1em;
}

.svg-sunset-circle {
    width: 9em;
    height: 9em;
}

// Socials
.svg-facebook,
.svg-instagram {
    width: 1em;
    height: 1em;
}

.svg-linkedin {
    width: .8em;
    height: .77em;
}

</style>
