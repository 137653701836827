<template>
    <div
        v-if="url"
        class="o-asset"
        :class="{'has-description':hasDescription}"
        v-reveal.once="reveal ? animate : false"
        @mouseenter="animate"
    >
        <div class="o-asset__container">
            <span
                v-if="cover"
                ref="image"
                class="o-asset__bg"
                :style="`--img-url: url('${url}')`"
            ></span>
            <img
                v-else
                ref="image"
                :src="url"
                :alt="alt || title"
                :width="width"
                :height="height"
                class="o-asset__img"
                @load="loaded"
                :loading="lazy ? 'lazy' : false"
            />

            <div
                v-if="hasAnimation"
                class="o-asset__canvas"
                ref="canvas"
            ></div>
        </div>
        <p
            v-if="hasDescription && desc"
            v-html="desc"
            class="o-asset__description"
        />
    </div>
</template>

<script>
/*
        alt() {
            console.log('alt', this.image, this.image.alt)
            return (this.image && this.image.alt) ? this.image.alt : false
        },
        description() {
            return (this.image && this.image.desc) ? this.image.desc : false
        },
*/
import gsap from 'gsap'
import debounce from 'lodash/debounce'
import * as PIXI from 'pixi.js'

import { round, reducedMotion } from 'src/utils'

import { isMobile } from 'mobile-device-detect'

export default {
    name: 'Asset',
    props: {
        url: String,
        alt: String,
        desc: String,
        title: String,
        width: Number,
        height: Number,
        cover: {
            type: Boolean,
            default: false,
        },
        reveal: {
            type: Boolean,
            default: true,
        },
        setAnimation: {
            type: Boolean,
            default: true,
        },
        lazy: {
            type: Boolean,
            default: true,
        },
        hasDescription: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        isAnimating: false,
        hasAnimation: true,
    }),
    mounted() {
        if(!this.url) {
            return
        }

        this.hasAnimation = (this.reveal || this.setAnimation) && !reducedMotion && !isMobile

        if (this.hasAnimation) {

            this.PIXIapp = new PIXI.Application({
                autoResize: true,
                transparent: true,
                resolution: window.devicePixelRatio || 1
            });

            this.$refs.canvas.appendChild(this.PIXIapp.view);

            this.PIXIapp.stage.interactive = true;

            this.PIXIcontainer = new PIXI.Container();
            this.PIXIapp.stage.addChild(this.PIXIcontainer);

            this.PIXIasset = PIXI.Sprite.from(this.url);
            this.PIXIcontainer.addChild(this.PIXIasset);
            this.PIXIasset.x = 0;
            this.PIXIasset.y = 0;

            this.PIXIdisplacementSprite = PIXI.Sprite.from('/static/image/displacement.jpg');
            // Make sure the sprite is wrapping.
            this.PIXIdisplacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;
            this.displacementFilter = new PIXI.filters.DisplacementFilter(this.PIXIdisplacementSprite);

            this.PIXIdisplacementSprite.position = this.PIXIasset.position;

            this.PIXIapp.stage.addChild(this.PIXIdisplacementSprite);

            this.PIXIasset.filters = [this.displacementFilter];

            this.displacementFilter.scale.x = 0;
            this.displacementFilter.scale.y = 0;

            this.setSizes()

            window.addEventListener('resize', this.onResize = debounce(this.setSizes, 100));
        }

    },
    computed: {
        ratio() {
            return round(this.width/this.height, 3)
        },
    },
    methods: {
        loaded() {
            this.$emit('loaded')
        },
        setSizes() {
            const parent = this.PIXIapp.view.parentNode
            const parentWidth = parent.clientWidth
            const parentHeight = parent.clientHeight
            this.PIXIapp.renderer.resize(parentWidth, parentHeight);

            if (this.cover) {
                const parentRatio = parentWidth/parentHeight

                // Parent Wider
                if(parentRatio > this.ratio) {
                    this.PIXIasset.height = this.height / (this.width / parentWidth);
                    this.PIXIasset.width = parentWidth;
                    this.PIXIasset.position.x = 0;
                    this.PIXIasset.position.y = (parentHeight - this.PIXIasset.height) / 2;
                // Parent Higher
                } else {
                    this.PIXIasset.width = this.width / (this.height / parentHeight);
                    this.PIXIasset.height = parentHeight;
                    this.PIXIasset.position.y = 0;
                    this.PIXIasset.position.x = (parentWidth - this.PIXIasset.width) / 2;
                }

            } else {
                this.PIXIasset.width = parentWidth
                this.PIXIasset.height = parentHeight
            }

        },
        animate() {

            if(!this.displacementFilter || this.isAnimating || !this.hasAnimation) {
                return
            }

            gsap.timeline({
                    ease: 'linear',
                    onStart: () => {
                        this.isAnimating = true
                    },
                    onComplete: () => {
                        this.isAnimating = false
                    },
                })
                .set(this.PIXIdisplacementSprite, {
                    x: 0,
                })
                .set(this.displacementFilter.scale, {
                    x: 0,
                    y: 0,
                })
                .set(this.$refs.canvas, {
                    opacity: 1
                })
                .to(this.PIXIdisplacementSprite,
                    {
                        x: 500,
                        duration: 2,
                    },
                    'in'
                )
                .to(this.displacementFilter.scale,
                    {
                        x: 50,
                        y: 50,
                        duration: 1,
                    },
                    'in'
                )
                .to(this.displacementFilter.scale,
                    {
                        x: 0,
                        y: 0,
                        duration: 1,
                    },
                    'in+=1'
                )
                .to(this.$refs.canvas,
                    {
                        duration: .2,
                        opacity: 0,
                    },
                    'in+=1.8'
                )
        },
    },
    destroyed() {

        if (this.hasAnimation) {
            //this.PIXIcontainer.destroy(true)
            window.removeEventListener('resize', this.onResize);
        }
    }
};

</script>

<style lang="scss">

.o-asset {

    &.has-description {
        margin-bottom: 3em;
    }
}

.o-asset__img {
    @include img;
}

.o-asset__container,
.o-asset__bg {
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
}

.o-asset__bg {
    position: absolute;
    background-image: var(--img-url);
    background-position: 50% 50%;
    background-size: cover;
}

.o-asset__description {
    margin-top: 1em;
    padding: 0 1em;
    line-height: 1.25em;
}

.o-asset__canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

</style>
