<template>
    <div
        v-if="text"
        class="o-quote"
        v-reveal="revealEnabled ? reveal : false"
    >
        <anim-text
            tag="blockquote"
            :text="`&ldquo;${text}&rdquo;`"
            :reveal="false"
            :visible="revealEnabled ? isRevealed : true"
            class="o-quote__text | t-t2-serif"
        />
        <anim-text
            v-if="author"
            tag="small"
            :text="author"
            :reveal="false"
            :visible="revealEnabled ? isRevealed : true"
            class="o-quote__author | t-small"
        />
    </div>
</template>

<script>

import AnimText from 'objects/AnimText'

export default {
    name: 'AppQuote',
    components: {
        AnimText,
    },
    props: {
        data: {
            type: Object|Array,
            required: true,
        },
        revealEnabled: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        isRevealed: false,
    }),
    computed: {
        quote() {
            if(Array.isArray(this.data)) {
                return this.data.length > 0 ? this.data[0] : false
            } else {
                return this.data
            }
        },
        text() {
            return (this.quote && typeof this.quote.text !== 'undefined') ? this.quote.text : false
        },
        author() {
            return (this.quote && typeof this.quote.author !== 'undefined') ? this.quote.author : false
        },
    },
    methods: {
        reveal(state) {
            this.isRevealed = state.isActive
            this.$emit('reveal', this.isRevealed)
        },
    },
};

</script>

<style lang="scss">

.o-quote {
    text-align: center;
}

.o-quote__text {
}
.o-quote__author {
    --a-in-delay: calc(var(--a-in-duration)/2);
    margin-top: 1em;
}

</style>
