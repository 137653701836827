<template>
    <div
        v-if="clientList.length > 0"
        class="b-client"
    >
        <blob class="b-client__blob" :parallax="-.6" />
        <h2
            v-if="data.customTitle !== null"
            class="b-client__title | t-small"
        >
            {{ data.customTitle }}
        </h2>

        <ul class="b-client__list">
            <li
                v-for="(client, i) in clientList"
                :key="`clients-${_uid}-${i}`"
                class="b-client__item"
            >
                <a
                    :href="client.website"
                    :title="client.clientName"
                    target="_blank"
                    rel="noopener"
                    class="b-client__link"
                >
                    <asset
                        v-bind="client.logo"
                        class="b-client__logo"
                        :reveal="false"
                        :setAnimation="false"
                    />
                </a>
            </li>
        </ul>
        <btn
            v-if="allClients.length !== clientList.length"
            label="Show all clients"
            :lineUnder="true"
            @click.native="showAllClients"
            class="b-client__more"
        />
    </div>
</template>

<script>

import Blob from 'objects/Blob'
import Asset from 'objects/Asset'
import Btn from 'components/Btn'

export default {
    name: 'BlockClient',
    components: {
        Blob,
        Asset,
        Btn,
    },
    data: () => ({
        allClients: [],
        clientList: [],
    }),
    props: {
        data: false,
    },
    created() {

        this.$store.dispatch('global/loadClients')
            .then(clients => {
                this.allClients = clients

                if (clients.length < 1) {
                    return false
                }

                this.data.selectedClients.forEach(client => {
                    this.clientList.push(this.allClients.find(c => c.id === client.clientId))
                })
            })
    },
    methods: {
        showAllClients() {
            this.allClients.forEach(client => {
                if(!this.clientList.find(c => c.id === client.id)) {
                    this.clientList.push(client)
                }
            })
        }
    }
};

</script>

<style lang="scss">

.b-client {
    padding: var(--box-padding);

    &:before {
        @include pseudo-el($width: 100vw, $bg: var(--main-color));
        position: absolute;
        top: 0;
        right: calc(50% - 50vw);
    }

    @media #{md("sm")} {

        &:before {
            right: 0;
        }
    }
}

.b-client__blob {
    --size: calc(var(--box-padding) * 2);

    position: absolute;
    top: -2em;
    right: calc(var(--size) / -4);

    @media #{md("md")} {
        top: calc(var(--size) / -2);
    }
}

.b-client__title {
    margin-bottom: 2rem;
}

.b-client__list {
    @include reset-list;
    display: grid;
    grid-template-columns: 1fr;

    @media #{md("xs")} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media #{md("sm")} {
        grid-template-columns: repeat(3, 1fr);
    }

    @media #{md("md")} {
        grid-template-columns: repeat(4, 1fr);
    }
}

.b-client__item {

    &:nth-child(n+2):before {
        @include pseudo-el($width: calc(100% - 10% * 2), $height: 1px, $bg: $color-dark);
        position: absolute;
        top: 0;
        left: 10%;
    }

    @media #{md("xs")} {

        &:nth-child(2):before {
            display: none;
        }

        &:nth-child(2n) {
            border-left: $border-dark
        }
    }

    @media #{md("sm")} {

        &:nth-child(3):before {
            display: none;
        }

        &:nth-child(2n) {
            border-left: 0
        }

        &:nth-child(3n),
        &:nth-child(3n+2) {
            border-left: $border-dark
        }
    }

    @media #{md("md")} {

        &:nth-child(4):before {
            display: none;
        }

        &:nth-child(3n),
        &:nth-child(3n+2) {
            border-left: 0
        }

        &:nth-child(4n),
        &:nth-child(4n+2),
        &:nth-child(4n+3) {
            border-left: $border-dark
        }
    }
}

.b-client__link {
    display: block;
    padding: 10% 20%;
}

.b-client__logo {
    height: 0;
    padding-top: 50%;

    .o-asset__container {
        position: absolute;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.b-client__more {
    display: block;
    margin-top: 2em;
    margin-right: auto;
    margin-left: auto;
}


</style>
