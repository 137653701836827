<template>
    <div class="o-canvas">
        <svg ref="svg" class="o-canvas__svg | u-gradient-blue-green" :viewBox="`0 0 ${svgWidth} ${svgHeight}`">
            <defs>
                <linearGradient
                    :id="`gradient-${id}`"
                    x1="0"
                    y1="50%"
                    x2="100%"
                    y2="50%"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        :offset="`${-50 + settings.gradientOffset}%`"
                        stop-color="var(--gradient-color-0)"
                    />
                    <stop
                        :offset="`${0 + settings.gradientOffset}%`"
                        stop-color="var(--gradient-color-1)"
                    />
                    <stop
                        :offset="`${50 + settings.gradientOffset}%`"
                        stop-color="var(--gradient-color-2)"
                    />
                    <stop
                        :offset="`${100 + settings.gradientOffset}%`"
                        stop-color="var(--gradient-color-3)"
                    />
                </linearGradient>

                <clipPath :id="`clip-lines-${id}`">
                    <polygon ref="clipLines"/>
                </clipPath>

                <clipPath :id="`clip-circle-${id}`">
                    <path ref="clipCircle"/>
                </clipPath>

            </defs>
            <path
                ref="circle"
                :fill="`url(#gradient-${id})`"
                :clip-path="`url(#clip-circle-${id})`"
                class="o-canvas__circle"
            ></path>
            <path
                v-for="line in 6"
                :key="`line-${line}`"
                ref="line"
                :stroke="`url(#gradient-${id})`"
                :clip-path="`url(#clip-lines-${id})`"
                class="o-canvas__line"
            ></path>
        </svg>
        <span ref="overlay" class="o-canvas__overlay"></span>
    </div>
</template>

<script>

import { EventBus } from 'src/event-bus'

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger);

import debounce from 'lodash/debounce';
import { round } from 'src/utils';

export default {
    name: 'AppCanvas',
    props: {
        animate: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        settings: {
            amplitude: 0.01,
            speed: 0,
            lineSpacing: 36,
            circleRadius: .35,
            length: 60,
            gradientOffset: 0,
        },
        perc: 0,
        svgWidth: 0,
        svgHeight: 0,
    }),
    mounted() {
        this.$paths = gsap.utils.toArray(this.$refs.line)

        this.setSizes()

        const vm = this
        this.tl = false

        if (this.animate) {

            this.tl = gsap.timeline({

                // Scroll animations
                scrollTrigger: {
                    trigger: this.$el,
                    start: 'top top',
                    end: '+=60% top',
                    scrub: .1,
                    pin: true,
                },
                onUpdate() {
                    vm.perc = round(this.progress())
                }
            })

            // Waves
            this.tl
                .fromTo(vm.settings,
                    {
                        amplitude: 0.01,
                        speed: 0,
                        gradientOffset: 0,
                    },
                    {
                        amplitude: 20,
                        speed: -200,
                        gradientOffset: 50,
                        duration: 1,
                        ease: 'sine.in',
                        onUpdate: vm.drawSVG
                    }
                )
//                .to(vm.settings, {
//                    amplitude: 20,
//                    speed: -200,
//                    gradientOffset: 50,
//                    duration: 1,
//                    ease: 'sine.in',
//                    onUpdate: vm.drawSVG
//                })
                // .to(this.$el, {
                //     yPercent: 50,
                //     duration: 1,
                // }, 0)
                .to(this.$refs.overlay, {
                    scaleY: 1,
                    duration: .5,
                }, '-=.5')


        } else {

            this.tl = gsap.timeline({

                // Scroll animations
                scrollTrigger: {
                    trigger: this.$el,
                    start: 'top center',
                    end: 'bottom bottom',
                    scrub: .1,
                },
                onUpdate() {
                    vm.perc = round(1 - this.progress())/2
                }
            })

            // Waves
            this.tl
                .fromTo(vm.settings,
                    {
                        amplitude: 2,
                        speed: 0,
                        gradientOffset: 25,
                    },
                    {
                        amplitude: 0.1,
                        speed: 100,
                        gradientOffset: 0,
                        duration: 1,
                        ease: 'sine.in',
                        onUpdate: vm.drawSVG
                    }
                )
        }

        EventBus.$on('bodyHeightUpdate', this.tl.scrollTrigger.refresh)

        // Window events
        window.addEventListener('resize', this.onResize = debounce(this.setSizes.bind(this), 100))

    },
    computed: {
        id() {
            return `canvas-${this._uid}`
        },
    },
    methods: {
        setSizes() {
            this.svgWidth = this.$el.offsetWidth
            this.svgHeight = this.$el.offsetHeight

            if(this.svgWidth < 768) {
                this.settings.circleRadius = .5
                this.settings.lineSpacing = 24

                //
                this.svgHeight = this.svgHeight - 200
            } else {
                this.settings.circleRadius = .35
                this.settings.lineSpacing = 36
            }

            this.xs = []
            for (let i = 0; i <= this.svgWidth; i++) {
                this.xs.push(i)
            }

            // Draw circle
            this.$refs.circle.setAttribute('d', `M${(this.svgWidth - this.settings.circleRadius * this.svgWidth)/2}, ${this.svgHeight/1.5} A50,50 0 1,1 ${(this.svgWidth + this.settings.circleRadius * this.svgWidth)/2},${this.svgHeight/1.5}`)

            this.drawSVG()
        },
        drawSVG() {

            if(!this.$refs.svg) {
                return
            }

            let y
            this.$paths.forEach(($path, i) => {
                let points = this.xs.map(x => {
                    y = this.svgHeight/1.5 + i * this.settings.lineSpacing + this.settings.amplitude * Math.sin( (x + this.settings.speed) / this.settings.length )
                    return `${x},${y}`
                })

                points = `M${points.join(' L')}`

                $path.setAttribute('d', points)
            })

            // Draw lines clip
            let points = `
                0,0
                ${this.svgWidth},0
                ${this.svgWidth},${this.svgHeight/1.5}
                ${this.svgWidth * (1/2 + this.perc * 2)},${this.svgHeight}
                ${this.svgWidth * (1/2 - this.perc * 2)},${this.svgHeight}
                0,${this.svgHeight/1.5}
            `

            this.$refs.clipLines.setAttribute('points', points)

            // Draw circle clip
            points = `
                M${(this.svgWidth - this.settings.circleRadius * this.svgWidth)/2}, ${this.svgHeight/1.5 + this.perc * this.svgHeight/1.5}
                A50, 50 0 1, 1
                ${(this.svgWidth + this.settings.circleRadius * this.svgWidth)/2}, ${this.svgHeight/1.5 + this.perc * this.svgHeight/1.5}
            `

            this.$refs.clipCircle.setAttribute('d', points)

        },
    },
    beforeDestroy() {

        // Window events
        window.removeEventListener('resize', this.onResize)

        EventBus.$off('bodyHeightUpdate', this.tl.scrollTrigger.refresh)
        this.tl.kill()
    }
};

</script>

<style lang="scss">

.o-canvas {
    --gradient-color-0: #FEB4B0;

    position: absolute;
    top: 0;
    left: calc(50% - 50vw);
    display: block;
    width: 100vw;
    height: 100vh;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: inherit;
        height: inherit;
    }
}

.o-canvas__line {
    stroke-width: 6px;
    fill: none;

    @media #{md("sm")} {
        stroke-width: 8px;
    }
}


.o-canvas__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 50%;
    @include linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(255, 255, 255));
    transform: scale(1, 0);
    transform-origin: 50% 100%;
    will-change: transform;
}

</style>
