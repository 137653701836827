<template>
    <div>
        <app-section
            tag="header"
            :fullheight="true"
        >
            <template v-slot:before>
                <blob class="p-about__blob p-about__blob--1" :parallax="1" />
                <blob class="p-about__blob p-about__blob--2" :parallax="-.7" />
            </template>

            <heading
                :header="page.heading"
                :center="true"
                titleClass="t-t1-serif"
                class="p-about__heading"
            />


        </app-section>

        <builder :builder="page.builder" />

        <push />

    </div>
</template>

<script>

import AppSection from 'layout/AppSection';

import Blob from 'objects/Blob';

import Heading from 'components/Heading';
import Push from 'components/Push';

import Builder from 'builder/Builder';

export default {
    name: 'About',
    components: {
        AppSection,
        Blob,
        Heading,
        Push,
        Builder,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: this.page.seo.title
        }
    },
};

</script>

<style lang="scss">

.p-about {}

.p-about__heading {

    @media #{md("md")} {

        .c-heading__inner {
            width: calc(3/4 * 100%);
        }
    }
}

.p-about__blob {
    position: absolute;

    &--1 {
        --size: 40vw;
        top: calc(50% - var(--size)/2);
        left: calc(50% - var(--size)/2);
    }

    &--2 {
        --size: 15%;
        top: 70%;
        right: 10%;
    }

    @media #{md("xl")} {

        &--1 {
            --size: 35vw;
        }
    }
}

</style>
