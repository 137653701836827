<template>
    <tag
        :is="tag"
        :href="tag == 'a' && href ? href : false"
        :to="tag == 'router-link' && href ? href : false"
        :rel="tag == 'a' ? 'noopener' : false"
        :class="className"
        v-cursor
    >
        <span
            class="c-btn__inner"
            @mouseenter="mouseEnter"
            @mouseleave="mouseLeave"
        >
            <span
                v-if="lineUpper"
                class="c-btn__line-top"
            ></span>
            <icon
                v-if="arrowLeft"
                icon="arrow-simple-left"
                class="c-btn__arrow-left"
            />
            <span
                class="c-btn__label"
                :class="{ 't-small' : !simple }"
            >
                <slot>{{ label }}</slot>
            </span>
            <icon
                v-if="arrowRight"
                icon="arrow-simple-right"
                class="c-btn__arrow-right"
            />
            <icon
                v-if="arrowUnder"
                icon="arrow-simple-right"
                class="c-btn__arrow-under"
            />
            <span
                v-else-if="lineUnder"
                class="c-btn__line-bottom"
            ></span>
        </span>
    </tag>
</template>

<script>

import Icon from 'objects/Icon';

import { isMobile } from 'mobile-device-detect'
import { reducedMotion } from 'src/utils';

export default {
    name: 'Btn',
    components: {
        Icon,
    },
    props: {
        tag: {
            type: String,
            default: 'button',
        },
        href: false,
        label: {
            type: String,
            default: 'Button Label',
        },
        simple: {
            type: Boolean,
            default: false,
        },
        arrowLeft: {
            type: Boolean,
            default: false,
        },
        arrowRight: {
            type: Boolean,
            default: false,
        },
        arrowUnder: {
            type: Boolean,
            default: false,
        },
        lineUnder: {
            type: Boolean,
            default: false,
        },
        lineUpper: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        hoverIn: false,
        hoverOut: false,
    }),
    methods: {
        mouseEnter() {
            if(!this.hasEvent || reducedMotion || isMobile) {
                return
            }

            this.hoverIn = true
            this.hoverOut = false
        },
        mouseLeave() {
            if(!this.hasEvent || reducedMotion || isMobile) {
                return
            }

            this.hoverIn = false
            this.hoverOut = true
        },
    },
    computed: {
        className() {
            let classname = 'c-btn'

            if(this.simple) {
                classname += ' c-btn--simple'
            }

            if(!this.hasEvent) {
                classname += ' no-event'
            }

            if(this.hoverIn) {
                classname += ' motion-in'
            } else if(this.hoverOut) {
                classname += ' motion-out'
            }

            return classname
        },
        hasEvent() {
            return this.href || this.tag === 'button'
        }
    },
}
</script>

<style lang="scss">

.c-btn {
    --a-link-in-delay: .3s;
    --a-link-in-duration: .4s;
    --a-link-out-delay: 0s;
    --a-link-out-duration: .3s;

    display: inline-block;
    font-size: 1rem;
    cursor: pointer;

    &:after {
        @include pseudo-el($width: calc(100% + 1em), $height: calc(100% + .5em));
        position: absolute;
        top: calc(-.5em/2);
        left: calc(-1em/2);
        border: 1px solid currentColor;
        border-radius: 53% 47% 64% 36%/55% 59% 41% 45%;
        clip-path: polygon(50% 50%, 0 100%, 0 100%, 0 100%, 0 100%, 0 100%);
        pointer-events: none;
    }

    &.no-event {
        cursor: initial;
    }

    &.motion-in {

        &:after {
            animation: a-link-stroke-in var(--a-link-in-duration) $out-sine var(--a-link-in-delay) forwards;
        }

        .c-btn__line-top,
        .c-btn__line-bottom,
        .c-btn__arrow-under:before {
            transform: scale(0, 1);
            transition: transform .3s $in-quad;
        }

        .c-btn__arrow-under svg {
            opacity: 0;
            transition: none;
        }

        .c-btn__arrow-left {
            transform: translate(.25em, 0);
            transition: transform .3s $in-quad;
        }

        .c-btn__arrow-right {
            transform: translate(-.25em, 0);
            transition: transform .3s $in-quad;
        }
    }

    &.motion-out {

        &:after {
            animation: a-link-stroke-out var(--a-link-out-duration) $in-sine var(--a-link-out-delay);
        }
    }

    &.-disabled {

        cursor: wait;
        opacity: 0.5;

        * {
            pointer-events: none;
        }
    }

    &--simple {

        .c-btn__inner {
            padding-top: 0;
            padding-bottom: 0;
        }

        .c-btn__label {
            font-size: inherit;
            font-weight: inherit;
            text-transform: none;
        }
    }

    &:hover {
    }
}

.c-btn__inner {
    display: inline-flex;
    align-items: center;
    padding-top: .5em;
    padding-bottom: .5em;
}


.c-btn__label {
}

// Line
.c-btn__line-top,
.c-btn__line-bottom {
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transform-origin: 0 50%;
    transition: transform .25s $out-quad var(--a-link-out-duration);
}

.c-btn__line-top {
    top: 0;
}

.c-btn__line-bottom {
    bottom: 0;
}


// Arrows
.c-btn__arrow-under {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    &:before {
        @include pseudo-el($width: calc(100% - 1px), $height: 1px, $bg: currentColor);
        position: absolute;
        bottom: calc((var(--svg-height) - 1px)/2);
        left: 0;
        transform-origin: 0 50%;
        transition: transform .25s $out-quad .3s;
    }

    svg {
        position: absolute;
        right: 0;
        bottom: 0;
        transition: opacity .1s ease-out calc(var(--a-link-out-duration) + .25s);
    }
}

.c-btn__arrow-left,
.c-btn__arrow-right {
    top: 1px;
    width: 1.5em;
    transition: transform .25s $out-quad .3s;

    &:before {
        @include pseudo-el($width: calc(100% - 1px), $height: 1px, $bg: currentColor);
    }

    svg {
        position: absolute;
        top: calc(50% - var(--svg-height)/2);
    }
}

.c-btn__arrow-right {
    margin-left: 1em;

    svg {
        right: 0;
    }
}

.c-btn__arrow-left {
    margin-right: 1em;

    svg {
        left: 0;
    }
}



/*----------  Animations  ----------*/

@keyframes a-link-stroke-in {
    0% {
        border-radius: 53% 47% 64% 36% / 55% 59% 41% 45%;
        clip-path: polygon(50% 50%, 0 100%, 0 100%, 0 100%, 0 100%, 0 100%);
    }
    25% {
        clip-path: polygon(50% 50%, 0 100%, 0 0, 0 0, 0 0, 0 0);
    }
    50% {
        clip-path: polygon(50% 50%, 0 100%, 0 0, 100% 0, 100% 0, 100% 0);
    }
    75% {
        clip-path: polygon(50% 50%, 0 100%, 0 0, 100% 0, 100% 100%, 100% 100%);
    }
    100% {
        border-radius: 60% 40% 35% 65% / 49% 52% 48% 51%;
        clip-path: polygon(50% 50%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%);
    }
}

@keyframes a-link-stroke-out {
    0% {
        border-radius: 60% 40% 35% 65% / 49% 52% 48% 51%;
        clip-path: polygon(50% 50%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%);
    }
    25% {
        clip-path: polygon(50% 50%, 0 100%, 0 0, 100% 0, 100% 100%, 100% 100%);
    }
    50% {
        clip-path: polygon(50% 50%, 0 100%, 0 0, 100% 0, 100% 0, 100% 0);
    }
    75% {
        clip-path: polygon(50% 50%, 0 100%, 0 0, 0 0, 0 0, 0 0);
    }
    100% {
        border-radius: 53% 47% 64% 36% / 55% 59% 41% 45%;
        clip-path: polygon(50% 50%, 0 100%, 0 100%, 0 100%, 0 100%, 0 100%);
    }
}



</style>
