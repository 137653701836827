<template>
    <div
        v-if="inputs.length > 0"
        class="c-form"
    >
        <form
            action="/"
            class="c-form__form"
            @submit.prevent="submitForm"
        >
            <div
                v-for="input in inputs"
                :key="`input-${input.name}`"
                class="c-form__el"
            >

                <app-input-radio
                    ref="field"
                    v-if="input.type == 'radio'"
                    v-model="input.value"
                    :label="input.label"
                    :name="input.name"
                    :required="input.required"
                    :options="input.options"
                    :defaultValue="input.defaultValue"
                    :disabled="input.disabled ? input.disabled : false"
                />
                <app-input-text
                    ref="field"
                    v-else
                    v-model="input.value"
                    :label="input.label"
                    :modifier="input.modifier"
                    :type="input.type"
                    :autocomplete="input.autocomplete"
                    :required="input.required"
                    :defaultValue="input.defaultValue"
                    :disabled="input.disabled ? input.disabled : false"
                />

            </div>

            <div
                v-if="error"
                class="c-form__error"
            >
                <slot name="error">
                    <p style="color:red">{{ errorText }}</p>
                </slot>
            </div>

            <div
                v-if="!success" class="c-form__submit">
                <btn
                    label="Send"
                    :class="{'-disabled':waiting}"
                    :lineUnder="true"
                    type="submit"
                />
            </div>


            <div
                v-if="success"
                class="c-form__success"
            >
                <slot name="success">
                    <h3 class="t-t3">{{ successText }}</h3>
                </slot>
            </div>

        </form>

    </div>
</template>

<script>

import AppInputText from 'objects/AppInputText';
import AppInputRadio from 'objects/AppInputRadio';
import Btn from 'components/Btn';

export default {
    name: 'AppForm',
    components: {
        AppInputText,
        AppInputRadio,
        Btn,
    },
    props: {
        formName: false,
        formTemplate: {
            type: String,
            default: 'contact',
        },
        inputs: Array,
        errorText: {
            type: String,
            required: true,
        },
        successText: {
            type: String,
            required: true,
        }
    },
    data: () => ({
        data: {},
        error: false,
        success: false,
        waiting: false
    }),
    computed: {
        csrfName: () => window.csrfTokenName,
        csrfToken: () => window.csrfTokenValue,
        defaultData() {
            const data = {
                message: {}
            }

            data[this.csrfName] = this.csrfToken
            data.message.template = this.formTemplate

            if (this.formName) {
                data.message.formName = this.formName
            }

            return data
        }
    },
    methods: {
        submitForm() {

            if ( this.waiting === true )
                return

            this.error = false
            this.success = false
            this.waiting = true

            const data = {... this.defaultData}

            let inputName
            this.inputs.forEach(input => {
                inputName = input.name
                if(typeof input.value !== 'undefined') {

                    if(inputName.startsWith('message[')) {
                        inputName = inputName.replace('message[', '').replace(/\]$/, '')
                        data.message[inputName] = input.value
                    } else {
                        data[inputName] = input.value
                    }
                }
            })

            fetch('/actions/contact-form/send', {
                    method: 'POST',
                    headers : {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                })
                .then(r => r.json())
                .then(data => {

                    if ( data.success === true ) {

                        this.success = true
                        this.waiting = false
                        this.$emit('success')

                        // Resset
                        setTimeout(this.reset, 3000);

                    } else {
                        this.waiting = false
                        this.error = true
                    }

                })
                .catch((e) => {
                    console.error('Submit error: ', e)
                    this.waiting = false
                })
        },
        reset() {
            this.success = false
            this.inputs.forEach(input => {
                input.value = ''
            })

            this.$refs.field.forEach(field => {
                field.reset()
            })

        }
    },
};

</script>

<style lang="scss">

.c-form__form {
    display: grid;
    grid-gap: var(--grid-gutter);
    grid-template-columns: 1fr;
}

.c-form__submit {
    text-align: right;
}

</style>
