<template>
    <div
        :class="className"
    >

        <label
            v-if="label && !disabled"
            :for="`input-${_uid}`"
            class="o-input-radio__label"
        >
            <span class="o-input-radio__text">
                {{ required ? `${label}*` : label }}
            </span>
        </label>

        <ul class="o-input-radio__group">
            <li
                v-for="(option, i) in options"
                :key="option.label"
                class="o-input-radio__item"
                v-cursor
            >
                <input
                    :id="`${name}-${i}`"
                    v-model="value"
                    :name="name"
                    :required="required"
                    :value="option.label"
                    type="radio"
                    class="o-input-radio__input"
                >
                <label
                    class="o-input-radio__label | t-small"
                    :for="`${name}-${i}`"
                    v-html="option.label"
                />
            </li>

        </ul>
    </div>
</template>

<script>

export default {
    name: 'AppInputRadio',
    props: {
        defaultValue: false,
        disabled: {
            type: Boolean,
            default: false,
        },
        label: false,
        name: false,
        options: {
            type: Array,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        value: '',
        isActive: false,
        isFilled: false,
    }),
    mounted() {
        if(this.defaultValue) {
            this.value = this.defaultValue
        }
    },
    computed: {
        className() {
            let className = 'o-input-list'

            if(this.disabled) {
                className += ' is-disabled'
            }

            if (this.isActive) {
                className += ' is-active'
            }

            if(this.isFilled) {
                className += ' is-filled'
            }

            return className
        }
    },
    methods: {
        reset() {
            this.value = ''
        },
    },
    watch: {
        value(val) {
            this.$emit('input', val)
            this.isFilled = val !== ''
        },
        defaultValue(value) {
            this.value = value
        },
    }
};

</script>

<style lang="scss">

.o-input-list {
    margin-top: 1em;
    color: $color-dark;

    &.is-disabled {
        pointer-events: none;
        color: $color-light;
        background-color: $color-grey;

        .o-input-text__input {
            color: $color-light;
        }
    }
}

.o-input-radio__group {
    @include reset-list;
    display: flex;
    flex-wrap: wrap;
    margin-top: 1em;
    margin-left: -1em;

    .o-input-radio__item {
        padding: .4em 1em;
    }
}

.o-input-radio__item {

    &:not(:last-child) {
        border-right: 1px solid $color-grey;
    }

    .o-input-radio__input {
        display: none;
        visibility: hidden;

        &:checked {
            & + .o-input-radio__label {
                color: $color-dark;
                border-bottom-color: $color-dark;
            }
        }
    }

    .o-input-radio__label {
        --height: 2em;
        display: block;
        color: $color-grey;
        border-bottom: 1px solid $color-grey;
        padding-bottom: .4em;
        line-height: 1.15;

        transition: color .2s ease-out, border-color .2s ease-out;
        cursor: pointer;
        user-select: none;

        html.reduced-motion & {
            transition: none;
        }
    }
}

</style>
