<template>
    <div>
        <app-section>

            <heading
                :header="heading"
                class="p-work__heading"
            />

            <div class="grid__row | u-jc-between@sm">
                <div class="grid__col--12 grid__col--10@xs grid__col--5@md grid__col--4@lg | t-content">
                    <h3
                        v-if="page.campaignName !== null"
                        class="t-small-framed"
                    >
                        {{ page.campaignName }}
                    </h3>
                    <anim-text
                        v-if="page.desc !== null"
                        :cms="true"
                        :text="page.desc"
                    />
                </div>
                <div
                    v-if="page.goals.length > 0"
                    class="p-work__goals | grid__col--12 grid__col--10@xs grid__col--6@md | t-content"
                >
                    <anim-text
                        tag="h3"
                        text="Goals"
                        class="t-small"
                    />
                    <ul
                        class="o-goals-list"
                        v-reveal
                    >
                        <li
                            v-for="(goal, i) in page.goals"
                            :key="`goal-${i}`"
                        >
                            <icon :icon=" goal.increase ? 'arrow-up-right' : 'arrow-right'" />
                            {{ goal.label }}
                        </li>
                    </ul>
                </div>
            </div>

        </app-section>

        <builder :builder="page.builder">
            <template v-slot:after-1>
                <div
                    v-if="page.selectedServices.length > 0 || page.customServices.length > 0"
                    class="o-service-list | t-content"
                >
                    <anim-text
                        tag="h2"
                        text="What we did"
                        class="o-service-list__title | t-small"
                    />

                    <ul class="o-service-list__list">
                        <li
                            v-for="service in page.selectedServices"
                            :key="`service-${service.slug}`"
                            class="o-service-list__item"
                        >
                            <router-link
                                :to="`/services/${service.slug}`"
                                :title="service.title"
                                v-cursor
                            >
                                <anim-text
                                    tag="span"
                                    :text="service.title"
                                    class="t-t2-serif"
                                />
                            </router-link>
                        </li>
                        <li
                            v-for="(service, i) in page.customServices"
                            :key="`custom-service-${i}`"
                            class="o-service-list__item o-service-list__item--custom"
                        >
                            <anim-text
                                tag="span"
                                :text="service.label"
                                class="t-t2-serif"
                            />
                        </li>
                    </ul>

                    <btn
                        tag="router-link"
                        href="/services"
                        label="Our Services"
                        :arrowUnder="true"
                        class="o-service-list__btn"
                    />
                </div>
            </template>
        </builder>

        <push />

    </div>

</template>

<script>

import AppSection from 'layout/AppSection';
import AnimText from 'objects/AnimText';
import Icon from 'objects/Icon';
import Btn from 'components/Btn';
import Heading from 'components/Heading';
import Push from 'components/Push';
import Builder from 'builder/Builder';

export default {
    name: 'Work',
    components: {
        AppSection,
        AnimText,
        Icon,
        Btn,
        Heading,
        Builder,
        Push,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: this.page.seo.title
        }
    },
    computed: {
        heading() {
            return {
                sup: 'Case study',
                supLink: 'works',
                mainTitle: this.page.title,
            }
        }
    }
};

</script>

<style lang="scss">

.p-work {}

.p-work__heading {
    margin-bottom: calc(var(--section-padding)/2);

    .c-heading__inner {
        width: 100%;
    }

    @media #{md("md")} {

        .c-heading__inner {
            width: calc(5/6 * 100%);
        }
    }
}

.p-work__goals {

    @media #{md("md", "max")} {

        &:nth-child(2) {
            margin-top: 2em;
        }
    }
}


/*----------  Goals  ----------*/

.o-goals-list {
    @include reset-list;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 2em;

    &.js-reveal {

        &:before {
            transform: scale(1, 0);
            transform-origin: 50% 100%;
        }

        li {
            opacity: 0;
            transform: translate(0, 1em) scale(1, .5);
            transform-origin: 50% 100%;

            &:nth-child(n+2):after {
                transform: scale(0, 1);
                transform-origin: 0 50%;
            }
        }

        &.is-visible {

            &:before {
                transform: scale(1);
                transition: transform .4s $in-out-sine .4s;
            }

            li {
                opacity: 1;
                transform: translate(0) scale(1);
                transform-origin: 50% 100%;

                &:after {
                    transform: scale(1);
                }

                @for $i from 1 through 10 {

                    &:nth-child(#{$i}) {
                        $i: $i - 1;
                        transition: all .4s $out-sine #{$i/20}s;

                        &:after {
                            transform: scale(1);
                            transition: transform .4s $out-sine #{$i/20}s;
                        }
                    }
                }
            }
        }
    }

    li {
        display: flex;
        align-items: baseline;
        padding-top: .5em;
        padding-bottom: .5em;

        &:nth-child(n+2):after {
            @include pseudo-el($height: 1px, $bg: $color-grey);
            position: absolute;
            top: 0;
            left: 0;
        }

        .o-icon {
            top: .1em;
            margin-right: 1em;
        }
    }

    @media #{md("xs")} {
        grid-template-columns: repeat(2, 1fr);

        &:before {
            @include pseudo-el($width: 1px, $bg: $color-grey);
            position: absolute;
            top: 0;
            left: calc(50% - 1px/2);
        }

        li {

            &:nth-child(2):after {
                display: none;
            }
        }
    }
}


/*----------  Service list  ----------*/

.o-service-list {
    padding-top: var(--section-padding);
    text-align: center;
}

.o-service-list__list {
    @include reset-list;
}

.o-service-list__item {
    padding-top: 1em;
    padding-bottom: 1em;

    &--custom {
        color: $color-grey;
    }

    a {
        display: inline-block;
    }

    .o-anim-text {

        div div {

            &:after {
                @include pseudo-el($width: auto, $height: 1px, $bg: currentColor);
                position: absolute;
                bottom: 0;
                right: -.1em;
                left: -.1em;
            }
        }
    }
}


</style>
