<template>
    <div>
        <app-base id="app"/>
    </div>
</template>

<script>

import 'assets/scss/styles.scss';

import Variables from 'src/mixins/variables';
import { reducedMotion } from 'src/utils';

import AppBase from 'layout/AppBase';

export default {
    name: 'app',
    components: {
        AppBase,
    },
    mixins: [ Variables ],
    metaInfo() {
        return {
            titleTemplate: `%s`
        }
    },
    created() {
        const $html = document.documentElement
        // Browser and OS classes
        $html.classList.add(`is-${this.browser.os}`)
        $html.classList.add(`is-${this.browser.name}`)

        if(this.isMobile) {
            $html.classList.add('is-mobile')
        } else {
            $html.classList.add('is-desktop')
        }

        if(reducedMotion) {
            $html.classList.add(`reduced-motion`)
        }
    }
}
</script>

<style lang="scss">
</style>
