<template>
    <header :class="className">
        <router-link
            to="/"
            @click.native="logoListener"
            class="l-header__logo"
            v-cursor
        >
            <icon
                icon="logo-osl"
                title="Outsmart Labs"
                :blob="true"
            />
            <icon
                icon="logo"
                title="Outsmart Labs"
            />
        </router-link>
        <headroom
            :disabled="!isSmallScreen"
            class="l-header__trigger"
        >
            <btn
                :lineUpper="true"
                :lineUnder="true"
                @click.native="toggleNav"
            >
                <anim-text
                    text="Menu"
                    class="c-btn__open"
                    :visible="!navIsOpen"
                    :reveal="false"
                />
                <anim-text
                    text="Close"
                    class="c-btn__close"
                    :visible="navIsOpen"
                    :reveal="false"
                />
            </btn>
        </headroom>
        <headroom
            :disabled="!isSmallScreen"
            class="l-header__cta"
        >
            <btn
                tag="router-link"
                href="/contact"
                label="Work with us"
                :lineUnder="true"
            />
        </headroom>
        <nav class="l-nav" itemscope itemtype="http://schema.org/SiteNavigationElement">
            <ul
                class="l-nav__list"
                :class="{ 'is-hovering' : isHovering }"
            >
                <li
                    v-for="(item, i) in nav"
                    :key="i"
                    class="l-nav__item | t-t2-serif"
                    itemprop="name"
                >
                    <router-link
                        :to="item.path"
                        :title='item.entry.title'
                        class="l-nav__link | t-link-gradient"
                        exact
                        itemprop="url"
                        @mouseenter.native="isHovering = true"
                        @mouseleave.native="isHovering = false"
                        v-cursor
                    >
                        <anim-text
                            :text="item.label ? item.label : item.entry.title"
                            class="l-nav__label"
                            :visible="navIsOpen"
                            :reveal="false"
                            itemprop="name"
                        />
                    </router-link>
                </li>
            </ul>
        </nav>
        <h1 class="l-header__headline | t-small">
            <span>Digital first agency</span>
        </h1>
        <div
            class="l-header__weather"
        >
            <span class="l-header__weather-text | t-small">Miami hq {{ temperature }}&deg;</span>
            <icon icon="sunset" />
        </div>
    </header>
</template>

<script>

import Icon from 'objects/Icon'
import Btn from 'components/Btn'
import AnimText from 'objects/AnimText';

import { headroom } from 'vue-headroom'
import { isMobile } from 'mobile-device-detect'

headroom.computed.style = () => ''

export default {
    name: 'AppHeader',
    components: {
        Icon,
        Btn,
        AnimText,
        headroom
    },
    data: () => ({
        navIsOpen: false,
        isReduced: false,
        temperature: 84,
        isHovering: false,
        noTransition: false,
    }),
    created() {
        // Fetch weather
        fetch('https://api.openweathermap.org/data/2.5/weather?id=4164138&appid=0e18610ad5c0e993f87de46fc2abafb1&units=imperial', {
            method: 'GET'
        })
        .then(r => r.json())
        .then(r => this.temperature = Math.floor(r.main.temp))
        .catch(e => console.error('Weather error: ', e))
    },
    mounted() {

        // Window event
        window.addEventListener('scroll', this.scroll)
    },
    computed: {
        nav() {
            return this.$store.state.global.nav
        },
        className() {
            let classname = 'l-header'

            if (this.isReduced) {
                classname += ' is-reduced'
            }

            if (this.noTransition) {
                classname += ' no-transition'
            }

            return classname
        },
        isSmallScreen() {
            if ( isMobile || window.innerWidth < 769)
                return true

            return false
        },
    },
    methods: {
        logoListener() {

            if (this.navIsOpen)
                this.closeNav()
        },
        toggleNav() {
            if (this.navIsOpen) {
                this.closeNav()
            } else {
                this.openNav()
            }
        },
        openNav() {
            this.noTransition = false
            this.navIsOpen = true

            this.currentScroll = window.scrollY
            document.documentElement.style.overflowY = 'hidden';
        },
        closeNav(transition=true) {
            if(!transition) {
                this.noTransition = true
            }
            this.navIsOpen = false

            // Add previous scroll
            document.documentElement.style.overflowY = 'scroll';
            window.scrollTo(0, this.currentScroll)
        },
        scroll() {
            this.isReduced = window.scrollY > 40
        },
    },
    watch: {
        navIsOpen(isOpen) {
            this.$emit('toggleNav', isOpen)
        },
        $route() {
            this.closeNav(false)
        },
    }
};

</script>

<style lang="scss">

.l-header {
    position: static;

    &.no-transition {

        .l-nav,
        .l-nav__item {
            transition: none !important;
        }
    }
}

.l-header__logo {
    z-index: 50;
    position: fixed;
    top: 1rem;
    left: 50%;
    transform: translate(-50%, 0);
    transition: transform .3s $out-sine;
    will-change: transform;

    @media #{md("sm", "max")} {

        .o-icon--logo-osl {
            opacity: 1;
            transition: all .2s $in-sine;

            .is-reduced & {
                opacity: 0;
                transform: scale(.9);
                transition: all .2s $out-sine;
            }
        }

        .o-icon--logo {
            display: none;
            visibility: hidden;
        }
    }

    @media #{md("sm")} {
        top: 1.6em;
        overflow: hidden;

        .o-icon--logo-osl {
            display: none;
            visibility: hidden;
        }

        .o-icon--logo {
            // Fixe logo crop
            padding-top: 1px;
            padding-bottom: 1px;

            transition: transform .3s $out-sine;

            .is-reduced & {
                transform: translate(0, -100%) scale(.95);
                transform-origin: 50% 0;
            }

            .nav-is-open & {
                transform: translate(0) scale(1);
                transition: transform .3s $out-sine .4s;
            }
        }
    }
}

.l-header__cta,
.l-header__trigger {
    z-index: 50;
    position: fixed;

    .headroom {
        transition: transform .3s $in-sine;

        &--unpinned:not(.headroom--top) {
            transform: translateY(-200%)
        }
    }
}

.l-header__trigger {
    top: 1.5rem;
    left: var(--grid-gutter);

    .c-btn__label {
        overflow: hidden;
    }

    .o-anim-text {
        --a-at-in-delay: var(--a-at-out-duration);
    }

    .c-btn__open {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        text-align: center;
    }
}

.l-header__cta {
/*
    @media #{md("sm", "max")} {
        bottom: 0;
        left: 0;
        width: 100%;
        padding-top: 1em;
        padding-bottom: 1em;
        background-image: linear-gradient($gradient-primary);
        text-align: center;
        transform: translate(0, 100%);

        &:after {
            animation: none;
        }

        .c-btn__inner {
            padding-top: 0;
        }

        .nav-is-open & {
            transform: translate(0);
        }

        &:hover:after {
            animation: none;
        }
    }
*/

    //@media #{md("sm")} {
        top: 1.5rem;
        right: var(--grid-gutter);
    //}
}

.l-header__weather,
.l-header__headline {
    --pos-x: var(--grid-gutter);

    z-index: 60;
    position: fixed;
    top: 50vh;
    overflow: hidden;

    span,
    .o-icon {
        display: block;
        will-change: transform;
        transition: transform .3s $out-sine;

        .nav-is-open & {
            transition: transform .3s $out-sine .4s;
        }
    }

    @media #{md("sm")} {
        --pos-x: calc(var(--grid-gutter) + .5rem);
    }
}

.l-header__headline {
    left: var(--pos-x);
    writing-mode: vertical-lr;
    transform: rotate(180deg) translate(0, 50%);

    .nav-is-open & span {
        transform: translate(0) !important;
    }

    @media #{md("sm", "max")} {

        span {
            transform: translate(100%, 0);
        }
    }

    @media #{md("sm")} {

        .is-reduced & span {
            transform: translate(100%, 0);
        }
    }
}

.l-header__weather {
    right: var(--pos-x);
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(0, -50%);

    .nav-is-open & {

        .o-icon {
            transform: translate(0) !important;
        }

        .l-header__weather-text {
            transform: rotate(180deg) translate(0) !important;
        }
    }

    .o-icon {
        margin-top: 1em;
    }

    @media #{md("sm", "max")} {

        .o-icon {
            transform: translate(calc(100% + 2px), 0);
        }
    }

    @media #{md("sm")} {

        .is-reduced & {

            .o-icon {
                transform: translate(100%, 0);
            }

            .l-header__weather-text {
                transform: rotate(180deg) translate(-1.3rem, 0);
            }
        }
    }
}

.l-header__weather-text {
    writing-mode: vertical-lr;
    transform: rotate(180deg);

    @media #{md("sm", "max")} {
        transform: rotate(180deg) translate(-1.3rem, 0);
    }
}


/*----------  Nav  ----------*/

.l-nav {
    z-index: 30;
    position: fixed;
    top: 0;
    left: 200%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: left 0s .6s;

    html.reduced-motion & {

        &,
        &:before,
        & * {
            transition: none;
        }
    }

    &:before {
        @include pseudo-el($bg: $color-blue-light);
        position: absolute;
        top: 0;
        left: 0;
        border-top-left-radius: 75% 50%;
        border-top-right-radius: 75% 50%;
        transform: translate(0, 50%) scale(1, .5);
        transform-origin: 50% 100%;
        transition: transform .4s $in-out-quad .2s, border-radius .4s $in-out-sine .2s;
        will-change: transform;
    }

    .nav-is-open & {
        left: 0;
        transition: left 0s 0s;

        &:before {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            transform: translate(0) scale(1);
            transition: transform .6s $in-out-quad, border-radius .6s $in-out-sine;
        }
    }
}

.l-nav__list {
    @include reset-list;
    width: 100%;
    text-align: center;
    transition: color .2s $out-sine;

    &.is-hovering {
        color: $color-grey-medium;
    }

    @media #{md("sm", "max")} {
        top: 1.1em;
        max-height: calc(100% - 10em);
        overflow-y: auto;
    }

    @media #{md("xs", "max")} {
        padding-right: 3rem;
        padding-left: 3rem;
    }

    @media #{md("xs")} {
        max-width: calc(3/4 * 100%);
    }

    @media #{md("sm")} {
        max-width: calc(2/3 * 100%);
    }

    @media #{md("lg")} {
        max-width: calc(1/2 * 100%);
        max-width: 45em
    }

    @media #{md("xxl")} {
        max-width: 55em;
    }
}

.l-nav__item {

    @for $i from 1 through 10 {

        &:nth-child(#{$i}) {
            $i: $i - 1;

            .l-nav__label {
                --a-at-in-delay: #{.4 + $i/15}s;
                --a-at-out-delay: #{$i/30}s;
            }

            &:not(:first-child):before {
                transition: transform .2s $in-sine #{$i/20}s;

                .nav-is-open & {
                    transition: transform .3s $out-sine #{.6 + $i/15}s;
                }
            }
        }
    }

    @media #{md("xs", "max")} {
        margin-top: .5em;
        margin-bottom: .5em;
    }

    @media #{md("xs")} {
        display: inline-flex;
        align-items: center;
        margin-bottom: .2em;
        margin-top: .2em;

        &:not(:first-child):before {
            content: "/";
            display: inline-block;
            margin-right: .2em;
            margin-left: .2em;
            transition: color .2s $out-sine;
            transform: scale(0);

            .nav-is-open & {
                transform: scale(1);
            }
        }
    }
}

.l-nav__link {
    display: inline-block;
    transition: color .2s $out-sine;

    &:hover {
        color: $color-dark;
    }

    &.is-current {
        pointer-events: none;
    }

    .nav-is-closed &:before {
        opacity: 0;
        transform: scale(1, 0);
    }
}

.l-nav__label {

    .l-nav__link.is-current & {
        color: $color-grey-medium;

        &:before {
            @include pseudo-el($height: 1px, $bg: currentColor);
            position: absolute;
            bottom: 0;
            left: 0;
            transform: scale(0, 1);
            transition: transform var(--a-at-out-duration) var(--a-at-out-ease) var(--a-at-out-delay);

            .nav-is-open & {
                transform: scale(1);
                transition: transform var(--a-at-in-duration) var(--a-at-in-ease) var(--a-at-in-delay);
            }
        }
    }
}


</style>
