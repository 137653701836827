<template>
    <div>
        <app-section>

            <heading
                :header="heading"
                :asset="page.image.length > 0 ? page.image[0] : false"
                :grid="true"
                :share="true"
            >
                <template v-slot:after>
                    <div class="t-content">
                        <div
                            v-if="isArticle"
                            class="o-user"
                        >
                            <div class="o-user__header | o-inline-list-secondary">
                                <div
                                    v-if="page.author"
                                    class="o-user__profile"
                                >
                                    <blob
                                        v-if="authorPhoto"
                                        :image="authorPhoto"
                                        class="o-user__photo"
                                        :parallax="0"
                                    />
                                    <span class="o-user__name">By {{ page.author.fullName }}</span>
                                </div>
                                <time
                                    class="o-user__postdate | t-small"
                                    :datetime="page.dateTime"
                                >
                                    {{ page.date }}
                                </time>
                            </div>
                            <anim-text
                                v-if="page.author && page.author.desc !== null"
                                :cms="true"
                                :text="page.author.desc"
                                class="o-user__desc | t-cms"
                            />
                        </div>
                        <anim-text
                            v-if="page.desc !== null"
                            :cms="true"
                            :text="page.desc"
                        />
                    </div>
                </template>
            </heading>

        </app-section>

        <builder :builder="page.builder" />

        <nav class="p-post__nav">
            <btn
                v-if="btnReturn"
                tag="router-link"
                :href="btnReturn.path"
                :title="btnReturn.label"
                :label="btnReturn.label"
                :arrowLeft="true"
            />
            <div
                v-if="isArticle"
                class="p-post__siblings"
            >
                <btn
                    v-if="page.prev !== null"
                    tag="router-link"
                    :href="`/blog/${page.prev.slug}`"
                    :title="page.prev.title"
                    label="Previous"
                    :arrowLeft="true"
                />
                <btn
                    v-if="page.next !== null"
                    tag="router-link"
                    :href="`/blog/${page.next.slug}`"
                    :title="page.next.title"
                    label="Next"
                    :arrowRight="true"
                />
            </div>
        </nav>

        <push />

    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import Blob from 'objects/Blob'
import AnimText from 'objects/AnimText';
import Heading from 'components/Heading';
import Btn from 'components/Btn';
import Push from 'components/Push';
import Builder from 'builder/Builder';

import transforms from 'src/graphql/asset-transforms'

export default {
    name: 'Article',
    components: {
        AppSection,
        Blob,
        AnimText,
        Heading,
        Builder,
        Btn,
        Push,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: this.page.seo.title
        }
    },
    computed: {
        currentRoute() {
            return this.$router.currentRoute
        },
        isArticle() {
            return this.currentRoute.name === 'article'
        },
        isJob() {
            return this.currentRoute.name === 'job'
        },
        heading() {
            return {
                sup: this.isArticle ? 'Blog' : this.isJob ? 'Careers' : false,
                mainTitle: this.page.title,
                desc: this.page.customTitle,
            }
        },
        btnReturn() {
            let btn = false

            if(this.isArticle) {
                btn = {
                    label: 'Go back to Blog',
                    path: '/blog',
                }
            } else if(this.isJob) {
                btn = {
                    label: 'Go back to Careers',
                    path: '/careers',
                }
            }

            return btn
        },
        authorPhoto() {
            const author = this.page.author
            if(author && author.image.length > 0) {
                let img = author.image[0]
                img.url = img.user
                img.width = transforms.user.width
                img.height = transforms.user.height
                return img
            }

            return false
        }
    }
};

</script>

<style lang="scss">

.p-post {}

.p-post__nav {
    margin-top: var(--section-padding);
    margin-bottom: var(--section-padding);

    @media #{md("sm")} {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.p-post__siblings {
    display: flex;
    align-items: center;

    @media #{md("sm", "max")} {
        width: 100%;
    }
}


/*==================================
=            User infos            =
==================================*/

.o-user {}

.o-user__header {
    display: flex;

    @media #{md("xs", "max")} {
        flex-direction: column;
    }
}

.o-user__profile {
    display: flex;
    align-items: flex-end;
    margin-right: auto;
}

.o-user__name,
.o-user__photo {
    flex-shrink: 0;
}

.o-user__photo {
    --size: 6em;
    margin-right: 1em;
}

.o-user__name {

    @media #{md("xs", "max")} {
        top: -2rem;
    }
}

.o-user__postdate {

    @media #{md("xs", "max")} {
        margin-top: -2rem;
        margin-left: 7rem !important;
        padding-top: 0;

        &:before {
            display: none !important;
        }
    }
}

.o-user__desc {
    padding-top: 1em;
    padding-bottom: 1em;
    color: $color-grey-dark;
    border-bottom: $border-grey;
}


</style>
