<template>
    <div
        v-if="selectedWorks.length > 0"
        class="b-slider | u-full-width"
        v-reveal="toggleReveal"
    >
        <blob
            v-for="i in 6"
            :key="`slider-blob-${i}`"
            :class="`b-slider__blob b-slider__blob--${i}`"
            :parallax="i % 3 === 0 ? -.3 * i : .2 * i"
        />

        <flickity
            ref="flkty"
            :options="flktyOptions"
            class="b-slider__flkty"
            v-cursor.gradient="{ label: 'Drag' }"
        >
            <article
                v-for="(work, i) in selectedWorks"
                :key="`slider-work-${i}`"
                class="o-slide"
            >
                <div class="o-slide__inner">
                    <span class="o-slide__label">
                        <h3 class="t-small-framed">{{ work.campaignName}}</h3>
                    </span>
                    <anim-text
                        ref="title"
                        :text="work.title"
                        tag="h2"
                        class="o-slide__title | t-t1"
                        :reveal="false"
                        :visible="isVisible && ((i === selectedIndex) || (i === selectedIndex + 1))"
                    />
                    <div class="o-slide__btn">
                        <btn
                            tag="router-link"
                            :href="`/work/${work.slug}`"
                            label="Learn more"
                            :arrowUnder="true"
                        />
                    </div>
                    <div
                        v-if="work.image.length > 0"
                        class="o-slide__asset"
                    >
                        <blob
                            :image="work.image[0]"
                            @loaded="resize"
                        />
                    </div>
                </div>
            </article>
        </flickity>

        <slider-nav
            v-if="selectedWorks.length > 1"
            :currentIndex="selectedIndex"
            :total="totalSlides"
            @prev="$refs.flkty.select(selectedIndex - 1)"
            @next="$refs.flkty.select(selectedIndex + 1)"
        />


    </div>
</template>

<script>

import Blob from 'objects/Blob'
import Icon from 'objects/Icon'
import Asset from 'objects/Asset'
import AnimText from 'objects/AnimText'
import SliderNav from 'objects/SliderNav'
import Btn from 'components/Btn'

import Flickity from 'vue-flickity'
import { EventBus } from 'src/event-bus'

export default {
    name: 'Push',
    components: {
        Blob,
        Icon,
        Asset,
        AnimText,
        SliderNav,
        Btn,
        Flickity,
    },
    data: () => ({
        flktyOptions: {
            contain: true,
            cellAlign: 'left',
            prevNextButtons: false,
            pageDots: false,
        },
        selectedIndex: 0,
        isVisible: false,
    }),
    props: {
        data: false
    },
    mounted() {
        const flkty = this.$refs.flkty

        flkty.on('select', i => {
            this.selectedIndex = i
        })

        let x, y
        flkty.on('dragMove', (e) => {
            x = Math.round(e.x)
            y = Math.round(e.y)
            EventBus.$emit('cursor-update-position', {x, y})
        })
    },
    computed: {
        selectedWorks() {
            return this.data.selectedWorks
        },
        totalSlides() {
            return this.selectedWorks.length
        },
    },
    methods: {
        resize() {
            this.$refs.flkty.resize()
        },
        toggleReveal(state) {
            this.isVisible = state.isActive
        },
    },
};

</script>

<style lang="scss">

.b-slider {
    padding-top: var(--section-padding);
    padding-bottom: var(--section-padding);

    .flickity-viewport {
        overflow: hidden;
    }
}

.b-slider__blob {
    position: absolute;

    &--1 {
        --size: 6%;
        bottom: 30%;
        left: 10%;
    }
    &--2 {
        --size: 15%;
        bottom: 5%;
        left: 20%;
    }
    &--3 {
        --size: 2%;
        top: 20%;
        left: 60%;
    }
    &--4 {
        --size: 20%;
        bottom: 15%;
        right: 20%;
    }
    &--5 {
        --size: 12%;
        right: 10%;
        top: 40%;
    }
    &--6 {
        --size: 5%;
        right: 5%;
        bottom: 20%;
    }
}

.b-slider__flkty {}



/*----------  Slides  ----------*/

.o-slide {
    width: calc(3/4 * 100%);
    overflow: hidden;

    &:not(:first-child) {
        margin-left: var(--grid-gutter);
    }

    &.is-selected {

        .o-slide__inner {

            &:before {
                opacity: 0;
                transform: scale(0, 1);
            }
        }
    }

    @media #{md("xs")} {
        width: calc(2/3 * 100%);
    }

    @media #{md("sm")} {
        width: calc(3/4 * 100%);
    }
}

.o-slide__inner {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--grid-gutter);
    padding: var(--box-padding);

    &:before {
        @include pseudo-el($bg: var(--main-color));
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: 100% 50%;
        transition: opacity .3s ease-in-out, transform .8s $out-quart;
    }

    @media #{md("sm")} {
        grid-template-columns: repeat(2, 1fr);
    }
}

.o-slide__label {
    display: inline-block;
}

.o-slide__title {
    --a-at-in-delay: .2s;
}

.o-slide__btn {

    .c-btn__label {
        padding-right: 2em;
    }

    @media #{md("sm")} {
        grid-area: 3 / 1 / 4 / 2;
    }
}

.o-slide__asset {

    @media #{md("sm", "max")} {
        margin-top: 1em;
    }

    @media #{md("sm")} {
        grid-area: 1 / 2 / 4 / 2;

        .o-blob {
            margin-top: 4em;
        }
    }
}


</style>
