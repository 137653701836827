<template>
    <div
        v-if="data && data.images.length > 0"
        class="b-image-list"
    >
        <blob class="b-image-list__blob" :parallax="-.7" />
        <background
            shape="ridges-small"
            class="b-image-list__ridges"
        />
        <div class="b-image-list__inner">
            <figure
                v-for="(image, i) in data.images"
                :key="`image-list-${_uid}-${i}`"
                class="b-image-list__item"
            >
                <asset v-bind="image"/>
                <anim-text
                    tag="figcaption"
                    :text="image.title"
                    class="b-image-list__name"
                />
            </figure>
        </div>
    </div>
</template>

<script>

import Blob from 'objects/Blob'
import Background from 'objects/Background'
import Asset from 'objects/Asset'
import AnimText from 'objects/AnimText'

export default {
    name: 'BlockImageList',
    components: {
        Blob,
        Background,
        Asset,
        AnimText,
    },
    props: {
        data: false,
    },
};

</script>

<style lang="scss">

.b-image-list {
}

.b-image-list__blob {
    --size: 25%;

    position: absolute;
    left: 0;
    top: 50%;
}

.b-image-list__ridges {
    --bg-width: 70%;
    z-index: 1;
    top: 5%;
    left: 30%;

    .o-bg__svg {
        transform: rotate(60deg);
    }
}

.b-image-list__inner {
    display: grid;
    grid-gap: 2em;

    @media #{md("sm")} {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 4em var(--box-padding)
    }

    @media #{md("md")} {
        padding-right: var(--box-padding);
        padding-left: var(--box-padding);
    }
}

.b-image-list__item {
    z-index: 2;

    @media #{md("xs")} {

        &:nth-child(2n) {
            padding-right: calc(1/6 * 100%);
        }

        &:nth-child(2n+1) {
            padding-left: calc(1/6 * 100%);
        }
    }

    @media #{md("sm")} {


        &:nth-child(2n) {
            padding-right: 0;
        }

        &:nth-child(2n+1) {
            padding-left: 0;
        }

        &:nth-child(3n+1) {
            z-index: 0;
        }

        &:nth-child(3n+2) {
            margin-top: 25%;
        }

        &:nth-child(3n) {
            grid-column: 1 / 3;
            padding-right: var(--box-padding);
            padding-left: var(--box-padding);
        }
    }
}

.b-image-list__name {
    margin-top: .75em;
}

</style>
