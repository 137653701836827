<template>
    <span
        :class="className"
        :style="styles"
        v-reveal="hasParallax ? animateParallax : false"
    >
        <span class="o-blob__inner">
            <asset
                v-if="image"
                :url="image.url"
                :title="image.title"
                class="o-blob__img"
                :cover="true"
                :reveal="false"
                :setAnimation="false"
                ref="asset"
            />
        </span>
    </span>
</template>

<script>

import Asset from 'objects/Asset'
import { reducedMotion } from 'src/utils';

import gsap from 'gsap';

export default {
    name: 'Blob',
    components: {
        Asset,
    },
    props: {
        modifier: false,
        animate: {
            type: Boolean,
            default: true,
        },
        parallax: {
            type: Number,
            default: 0,
        },
        image: false,
    },
    computed: {
        hasParallax() {
            return this.parallax !== 0
        },
        className() {
            let classname = 'o-blob'

            if (this.modifier) {
                classname += ` o-blob--${this.modifier}`
            }

            if (this.animate && !reducedMotion) {
                classname += ' is-animated'
            }

            return classname
        },
        styles() {
            let styles = false

            if (this.animate && !reducedMotion) {
                styles = `--a-blob-duration: ${this.randomNumber(12, 20)}s;`
            }

            return styles
        }
    },
    methods: {
        randomNumber: (min, max) => Math.floor(Math.random() * (max - min + 1) + min),
        animateParallax(state) {
            gsap.set(this.$el, {
                y: Math.round(state.progress * this.parallax * 100),
                rotate: Math.round(state.progress * this.parallax * -10)
            })
        }
    }
};

</script>

<style lang="scss">

.o-blob {
    --size: 100%;
    --blob-bg: var(--main-gradient);
    --a-blob-duration: 15s;
    --a-blob-duration-2x: calc(2 * var(--a-blob-duration));

    display: block;
    width: var(--size);
    height: 0;
    padding-top: var(--size);

    &--border {
        --blob-bg: none;

        .o-blob__inner {
            border: 1px solid currentColor;
        }
    }

    &.is-animated {

        .o-blob__inner {
            animation: a-blob var(--a-blob-duration) infinite linear, a-rotate var(--a-blob-duration-2x) infinite linear;
            will-change: border-radius;

            .is-mobile-safari &,
            .is-mobile.is-safari & {
                animation: a-blob var(--a-blob-duration) infinite linear;
            }
        }

        .o-blob__img {
            animation: a-blob-rotate var(--a-blob-duration-2x) infinite linear reverse;
            will-change: transform;

            .is-mobile-safari &,
            .is-mobile.is-safari & {
                animation: none;
                will-change: initial;
            }
        }
    }
}

.o-blob__inner {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: var(--blob-bg);
    border-radius: 53% 47% 64% 36% / 55% 59% 41% 45%;
    overflow: hidden;
}

.o-blob__img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
}

// Blob animation
@keyframes a-blob {
    0% {
        border-radius: 53% 47% 64% 36% / 55% 59% 41% 45%;
    }
    25% {
        border-radius: 34% 66% 48% 52% / 30% 36% 64% 70%;
    }
    50% {
        border-radius: 60% 40% 35% 65% / 49% 52% 48% 51%;
    }
    75% {
        border-radius: 43% 57% 58% 42% / 26% 68% 32% 74%;
    }
    100% {
        border-radius: 53% 47% 64% 36% / 55% 59% 41% 45%;
    }
}

@keyframes a-rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(1turn);
    }
}

@keyframes a-blob-rotate {
    0% {
        transform: rotate(0) scale(1.2);
    }
    100% {
        transform: rotate(1turn) scale(1.2);
    }
}

</style>
