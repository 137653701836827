<template>
    <div
        v-if="image"
        class="b-image"
        :class="{'has-description': hasDescription}"
    >
        <background
            v-if="!data.blobImage"
            shape="ridges-small"
            class="b-image__ridges"
        />
        <blob
            v-if="data.blobImage"
            :image="image"
            class="b-image__asset"
            :parallax="-.2"
        />
        <asset
            v-else
            v-bind="image"
            class="b-image__asset"
            :hasDescription="hasDescription"
        />
        <blob
            class="b-image__blob b-image__blob--1"
            :parallax=".7"
        />

        <blob
            v-if="!data.blobImage"
            class="b-image__blob b-image__blob--2"
            :parallax="-.4"
        />
    </div>
</template>

<script>

import Blob from 'objects/Blob'
import Asset from 'objects/Asset'
import Background from 'objects/Background'

export default {
    name: 'BlockImage',
    components: {
        Blob,
        Asset,
        Background,
    },
    props: {
        data: false,
    },
    computed: {
        hasDescription() {
            return (this.data && this.data.hasDescription) ? true : false
        },
        image() {
            if(this.data && this.data.image.length > 0) {
                return this.data.image[0]
            } else {
                return false
            }
        }
    }
};

</script>

<style lang="scss">

.b-image {
    --image-offset: calc(50% - 50vw);

    .b-row__col:nth-child(2n) & {

        .b-image__asset {
            right: var(--image-offset);
            left: auto;
        }
    }

    &.has-description {

        .b-image__asset {
            z-index: 4;
        }
    }

    @media #{md("md")} {
        --image-offset: calc(-1 * var(--main-padding) - var(--block-padding) * 2);
    }
}

.b-image__ridges {
    --bg-width: 100%;
    top: -20%;
    left: auto;
    right: -20%;

    .o-bg__svg {
        transform: rotate(-60deg);
    }
}

.b-image__asset {
    z-index: 1;
    left: var(--image-offset);

    .o-asset__description {
        z-index: 3;
    }
}

.b-image__blob {
    --size: 25%;
    position: absolute;
    bottom: 0;
    right: 0;

    &--1 {
        --size: 25%;
        z-index: 2;
        right: 5%;
        bottom: -10%;
    }

    &--2 {
        --size: 35%;
        top: 0;
        left: calc(-1 * var(--main-padding));
    }
}

</style>
