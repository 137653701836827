<template>
    <div>
        <app-section>

            <template v-slot:before>
                <background
                    shape="ridges"
                    class="p-contact__bg"
                />
            </template>

            <heading
                :header="page.heading"
                titleClass="t-t2-serif"
                :asset="page.image.length > 0 ? page.image[0] : false"
                class="p-contact__heading"
            >

                <template v-slot:after>

                    <div v-if="coordinate" class="p-contact__coordinates | t-cms">
                        <p
                            class="p-contact__address"
                        >
                            {{ coordinate.details.address }}
                        </p>
                        <p
                            class="p-contact__phone"
                        >
                            <a href="tel:3052090807">(305) 209-0807</a>
                        </p>
                    </div>

                    <GmapMap
                        class="p-contact__map"
                        :zoom="14"
                        :center="getCoordinates()"
                        :options="googleMapsOpts"
                    >
                        <GmapCustomMarker
                            :marker="getCoordinates()"
                        >
                            <span class="p-contact__marker">
                                <img src="/static/map/marker-gradient.svg" />
                            </span>
                        </GmapCustomMarker>
                    </GmapMap>

                    <app-form
                        :inputs="formInputs"
                        errorText="Something went wrong. Please try again."
                        successText="Your message was sent!"
                        @success="sendGAEvent"
                        class="p-contact__form"
                    />
                </template>
            </heading>

            <div
                v-if="page.offices.length > 0"
                class="o-offices"
            >
                <blob class="o-offices__blob o-offices__blob--1" :parallax="-.4" />
                <blob class="o-offices__blob o-offices__blob--2" :parallax=".6" />

                <ul class="o-offices__list">
                    <li
                        v-for="(office, i) in page.offices"
                        :key="`office-${i}`"
                        class="o-offices__office"
                    >
                        <asset
                            v-if="office.image.length > 0"
                            v-bind="office.image[0]"
                            class="o-offices__image"
                        />

                        <div class="o-offices__content">
                            <anim-text
                                v-if="office.city"
                                tag="h3"
                                :text="office.city"
                                class="o-offices__city | t-t2-serif"
                            />
                            <anim-text
                                v-if="office.desc"
                                :cms="true"
                                :text="office.desc"
                                class="o-offices__desc"
                            />
                        </div>
                    </li>
                </ul>
            </div>

        </app-section>

        <push :bg="false" />

    </div>
</template>

<script>

import AppSection from 'layout/AppSection';

import Blob from 'objects/Blob';
import Asset from 'objects/Asset';
import AnimText from 'objects/AnimText';
import Background from 'objects/Background';

import Push from 'components/Push';
import AppForm from 'components/AppForm';
import Heading from 'components/Heading';

import { mapStyles } from 'vendors/google-maps'
import {gmapApi} from 'vue2-google-maps'
import GmapCustomMarker from 'vue2-gmap-custom-marker'

export default {
    name: 'Contact',
    components: {
        AppSection,
        Blob,
        Asset,
        AnimText,
        Background,
        Push,
        AppForm,
        Heading,
        GmapCustomMarker
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: this.page.seo.title
        }
    },
    data: () => ({
        formInputs: [
            {
                label: 'Full Name',
                name: 'fromName',
                value: '',
                modifier: 'text',
                type: 'text',
                required: true,
                autocomplete: 'name',
            },
            {
                label: 'Email Address',
                name: 'fromEmail',
                value: '',
                modifier: 'text',
                type: 'email',
                required: true,
                autocomplete: 'email',
            },
            {
                label: 'What budget range are we working with?',
                name: 'message[budget]',
                type: 'radio',
                required: true,
                defaultValue: '$10K - $50K',
                options: [
                    {
                        id: '10-50',
                        label: '$10K - $50K',
                    },
                    {
                        id: '50-150',
                        label: '$50K - $150K',
                    },
                    {
                        id: '150-500',
                        label: '$150K - $500K',
                    },
                    {
                        id: '500plus',
                        label: '>$500K',
                    }
                ]
            },
            {
                label: 'What’s your project?',
                name: 'message[body]',
                value: '',
                modifier: 'text',
                type: 'textarea',
                required: true,
            }
        ],
        googleMapsOpts: {
            disableDefaultUi: true,
            zoomControl: false,
            zoomControlOptions: {
                style: 0,
                position: 6
            },
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            draggable: false,
            styles: mapStyles
        },
    }),
    computed: {
        google: gmapApi,
        coordinate() {
            return this.$store.state.global.footer
        }
    },
    methods: {
        getCoordinates: (position) => ({ lat: 25.7745759, lng: -80.1919441 }),
        sendGAEvent() {
            this.$ga.event('Form', 'Send', 'Contact form')
        }
    }
};

</script>

<style lang="scss">

.p-contact__bg {
    left: calc(50% - var(--bg-width)/2);
    transform: translate(0, -50%);
}

.p-contact__heading {

    @media #{md("md")} {
        grid-template-columns: 5fr 6fr;
        grid-column-gap: calc(1/12 * 100%);
    }
}

.p-contact__map {
    width: 100%;
    height: 20em;
    margin: 2em 0;
    pointer-events: none;
    & > *:first-child {
        height: 100%;
    }
}

.p-contact__marker {
    position: absolute;
    top: calc(50% - 60px/2);
    left: calc(50% - 60px/2);
    display: block;
    width: 60px;
    height: 60px;

    img {
        width: 100%;
    }
}

.p-contact__form {

    @media #{md("xs")} {
        width: calc(5/6 * 100%);
    }

    @media #{md("sm")} {
        width: calc(100% - var(--grid-gutter));
    }

    @media #{md("md")} {
        width: 100%;
    }

    @media #{md("lg")} {
        margin-top: 2em;
    }
}


/*----------  Office list  ----------*/

.o-offices {
}

.o-offices__blob {
    position: absolute;

    &--1 {
        --size: 35%;

        top: 10%;
        right: 45%;
    }

    &--2 {
        --size: 10%;

        z-index: 1;
        top: 50%;
        left: 55%;
    }
}

.o-offices__list {
    @include reset-list;
    display: grid;
    grid-gap: var(--box-padding);
    margin-top: var(--section-padding);
    //margin-bottom: var(--section-padding);

    @media #{md("sm")} {
        grid-template-columns: repeat(2, 1fr);
    }
}

.o-offices__office {

    @media #{md("sm")} {

        &:nth-child(2) {
            margin-top: 40%;
        }

        &:nth-child(2n+1) {

            .o-offices__image {
                left: calc(-1 * (var(--main-padding) + var(--box-padding) * 2/4));
            }
        }
    }
}

.o-offices__image {
    margin-bottom: 2em;

    @media #{md("sm")} {
        width: calc(100% + var(--main-padding) + var(--box-padding) * 2/4);
    }
}

.o-offices__content {
    z-index: 2;

    @media #{md("sm")} {
        padding-left: calc(1/6 * 100%);
    }
}

.o-offices__city {
}

.o-offices__desc {
    margin-top: 1em;
}


</style>
