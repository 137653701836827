<template>
    <div
        v-if="data"
        class="b-feedback"
        v-reveal="reveal"
    >
        <icon
            icon="sunset-circle"
            class="b-feedback__icon"
        />
        <div class="b-feedback__inner">
            <div class="b-feedback__col | t-content">
                <anim-text
                    v-if="data.customTitle !== null"
                    tag="h3"
                    :reveal="false"
                    :visible="isRevealed"
                    :text="data.customTitle"
                    class="t-t2-serif"
                />
                <anim-text
                    v-if="data.position !== null"
                    :reveal="false"
                    :visible="isRevealed"
                    :text="data.position"
                    class="t-small"
                />
            </div>
            <div
                v-if="data.desc !== null"
                class="b-feedback__col | t-content"
            >
                <anim-text
                    tag="h4"
                    :reveal="false"
                    :visible="isRevealed"
                    text="Feedback"
                    class="t-small"
                />
                <anim-text
                    :cms="true"
                    :reveal="false"
                    :visible="isRevealed"
                    :text="data.desc"
                />
            </div>
        </div>
    </div>
</template>

<script>

import Icon from 'objects/Icon'
import AnimText from 'objects/AnimText'

export default {
    name: 'BlockFeedback',
    components: {
        Icon,
        AnimText,
    },
    props: {
        data: false,
    },
    data: () => ({
        isRevealed: false,
    }),
    methods: {
        reveal(state) {
            this.isRevealed = state.isActive
        }
    }
};

</script>

<style lang="scss">

.b-feedback {}

.b-feedback__icon {
    z-index: 1;
    position: absolute;
    top: calc(-9em/2);
    right: var(--grid-gutter);

    @media #{md("sm", "max")} {
        font-size: .75rem;
    }
}

.b-feedback__inner {
    display: grid;
    grid-gap: 1.5em;
    padding: calc(9em/2) var(--box-padding) var(--box-padding);
    background-color: var(--main-color);

    @media #{md("sm")} {
        padding-top: calc(9em/2 + 1/24 * 100%);
        grid-template-columns: repeat(2, 1fr);
    }

    @media #{md("md")} {
        grid-gap: var(--box-padding);
    }
}

</style>
