<template>
    <div
        v-if="heading && hasContent"
        :class="className"
        v-reveal="reveal"
    >
        <header class="c-heading__inner | t-content">
            <deco
                v-if="sparkle"
                deco="sparkle"
                :visible="isRevealed"
                class="c-heading__sparkle"
            />
            <router-link
                v-if="sup && supLink"
                :to="supLink|resolver"
            >
                <anim-text
                    tag="h1"
                    :text="sup"
                    :reveal="false"
                    :visible="isRevealed"
                    class="c-heading__sup | t-small"
                />
            </router-link>
            <anim-text
                v-else-if="sup"
                tag="h1"
                :text="sup"
                :reveal="false"
                :visible="isRevealed"
                class="c-heading__sup | t-small"
            />
            <anim-text
                v-if="title"
                :tag="sup ? 'h2' : 'h1'"
                :text="title"
                :reveal="false"
                :visible="isRevealed"
                class="c-heading__title"
                :class="titleClass"
            />
            <anim-text
                v-if="desc"
                :cms="true"
                :text="desc"
                :reveal="false"
                :visible="isRevealed"
                class="c-heading__desc"
            />

            <ul
                v-if="share"
                class="o-share"
            >
                <li class="o-share__social">
                    <a
                        :href="`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`"
                        title="Share on Facebook"
                        target="_blank"
                        rel="noopener"
                        class="o-share__link | t-small t-link-gradient"
                    >
                        F
                    </a>
                </li>
                <li class="o-share__social">
                    <a
                        :href="`https://twitter.com/share?url=${currentUrl}`"
                        title="Share on Twitter"
                        target="_blank"
                        rel="noopener"
                        class="o-share__link | t-small t-link-gradient"
                    >
                        T
                    </a>
                </li>
                <li class="o-share__social">
                    <a
                        :href="`https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`"
                        title="Share on LinkedIn"
                        target="_blank"
                        rel="noopener"
                        class="o-share__link | t-small t-link-gradient"
                    >
                        L
                    </a>
                </li>
            </ul>
        </header>

        <div
            v-if="asset"
            class="c-heading__asset"
        >
            <asset v-bind="asset" />
        </div>

        <div
            v-if="$slots.after"
            class="c-heading__after"
        >
            <slot name="after" />
        </div>

    </div>
</template>

<script>

import Deco from 'objects/Deco'
import Asset from 'objects/Asset'
import AnimText from 'objects/AnimText'

export default {
    name: 'Heading',
    components: {
        Deco,
        Asset,
        AnimText,
    },
    props: {
        header: {
            type: Array|Object,
            required: true
        },
        center: {
            type: Boolean,
            default: false,
        },
        grid: {
            type: Boolean,
            default: false,
        },
        titleClass: {
            type: String,
            default: 't-t1',
        },
        share: {
            type: Boolean,
            default: false,
        },
        sparkle: {
            type: Boolean,
            default: false,
        },
        asset: false,
    },
    data: () => ({
        isRevealed: false,
    }),
    computed: {
        className() {
            let classname = 'c-heading'

            if(this.center) {
                classname += ' c-heading--center'
            }

            if (this.grid || this.asset) {
                classname += ' c-heading--grid'
            }

            return classname
        },
        heading() {

            // If object, return object
            if (!Array.isArray(this.header)) {
                return this.header
            }

            // Return first instance if array
            return this.header.length > 0 ? this.header[0] : false
        },
        title() {
            return this.heading.mainTitle !== null ? this.heading.mainTitle : false
        },
        sup() {
            return this.heading.sup !== null ? this.heading.sup : false
        },
        supLink() {
            return this.heading.supLink !== null ? this.heading.supLink : false
        },
        desc() {
            return this.heading.desc !== null ? this.heading.desc : false
        },
        hasContent() {
            return this.sup || this.title || this.desc
        },
        currentUrl() {
            return window.location.href
        },
    },
    methods: {
        reveal(state) {
            this.isRevealed = state.isActive
        }
    }
};

</script>

<style lang="scss">

.c-heading {

    &--center {

        .c-heading__inner {
            margin-right: auto;
            margin-left: auto;
            text-align: center;
        }
    }

    &--grid {
        display: grid;
        grid-gap: var(--grid-gutter);
    }

    @media #{md("xs")} {

        &--center {

            .c-heading__inner {
                width: calc(5/6 * 100%);
            }
        }
    }

    @media #{md("sm")} {

        &--center {

            .c-heading__inner {
                width: calc(2/3 * 100%);
            }
        }

        &--grid {
            grid-template-columns: repeat(2, 1fr);

            .c-heading__inner {
                width: 100%;
            }
        }
    }

    @media #{md("lg")} {

        &--center {

            .c-heading__inner {
                width: calc(7/12 * 100%);
            }
        }
    }
}

.c-heading__inner {
    width: 100%;

    .o-anim-text {
        @for $i from 1 through 3 {
            &:nth-child(#{$i}) {
                $i: $i - 1;
                --a-at-in-delay: calc(var(--a-at-in-duration)/2 * #{$i});
            }
        }
    }

    @media #{md("xs")} {
        width: calc(5/6 * 100%);
    }

    @media #{md("sm")} {
        width: calc(1/2 * 100%);
    }

    @media #{md("lg")} {
        width: calc(5/12 * 100%);
    }

}

.c-heading__sparkle {
    --a-ad-in-delay: .6s;

    position: absolute;
    bottom: 100%;
    right: 100%;
    display: inline-block;

    @media #{md("xs", "max")} {
        display: none;
    }
}

.c-heading__sup {
}

.c-heading__title {
}

.c-heading__sub {
}

.c-heading__desc {
    margin-top: 1em;
}

.c-heading__asset {
    margin-top: calc(-1 * var(--section-padding));

    @media #{md("sm", "max")} {
        width: calc(3/4 * 100%);
        grid-row-start: 1;
        grid-row-end: 2;
        margin-left: auto;
    }

    @media #{md("sm")} {
        grid-area: 1 / 2 / 4 / 3;
    }
}

.c-heading__after {

    @media #{md("sm")} {
        grid-area: 2 / 1 / 3 / 2;
    }
}


/*----------  Social sharer  ----------*/

.o-share {
    @include reset-list;
    display: inline-flex;

    @media #{md("sm", "max")} {
        position: absolute;
        bottom: calc(100% + var(--grid-gutter));
        left: 0;
        flex-direction: column;
        margin-top: 0 !important;
    }
}

.o-share__social {

    &:not(:first-child) {
        border-top: $border-grey;
    }

    @media #{md("sm")} {
        border: $border-grey;

        &:not(:first-child) {
            margin-left: -1px;
        }
    }
}

.o-share__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $accessible-size;
    height: $accessible-size;

    &:before {
        top: calc(#{$accessible-size}/2 + .75em);
        left: calc(50% - 1em/2);
        width: 1em;
    }
}
</style>
