<template>
    <div
        v-if="data"
        class="b-big-text"
    >
        <anim-text
            :cms="true"
            :text="data.desc"
        />
    </div>
</template>

<script>

import AnimText from 'objects/AnimText'

export default {
    name: 'BlockBigText',
    components: {
        AnimText,
    },
    props: {
        data: false,
    },
};

</script>

<style lang="scss">

.b-big-text {
    font-size: 1.2em;

    @media #{md("xs")} {
        margin-right: auto;
        margin-left: auto;
        width: calc(5/6 * 100%);
    }

    @media #{md("md")} {
        width: calc(3/4 * 100%);
        font-size: 1.5em;
    }

    @media #{md("lg")} {
        width: calc(2/3 * 100%);
    }
}

</style>
