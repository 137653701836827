<template>
    <div>
        <app-section
            tag="div"
            :fullheight="true"
            class="p-home__header"
        >
            <template v-slot:before>
                <app-canvas :animate="true" />
            </template>

            <heading
                :header="page.heading"
                :center="true"
                :sparkle="true"
                titleClass="t-t1-serif"
            />

            <template v-slot:after>
                <div class="p-home__header-btn">
                    <icon
                        icon="arrow-down"
                        :blob="true"
                        class="p-home__header-arrow"
                        @click.native="scrollToServices"
                    />
                    <btn
                        tag="span"
                        label="What do you need?"
                        :lineUnder="true"
                        @click.native="scrollToServices"
                    />
                </div>
            </template>

        </app-section>

        <div
            class="o-blobs"
            ref="blobs"
            :class="{ 'is-visible' : blobsIsVisible }"
            :style="blobsStyles"
        >

            <span
                v-for="i in 8"
                :key="`home-blob-${i}`"
                :class="`o-blobs__item o-blobs__item--${i}`"
            >
                <blob class="o-blobs__blob" />
            </span>
        </div>

        <app-section
            :fullheight="true"
            class="p-home__services"
            id="services"
        >
            <div class="grid__row | u-jc-center">
                <div class="t-content | grid__col--12 grid__col--10@sm grid__col--9@md grid__col--8@lg">
                    <anim-text
                        tag="h2"
                        :text='page.servicesTitle'
                        class="t-t1-serif"
                    />
                    <btn
                        tag="router-link"
                        :href="'services'|resolver"
                        label="Our services"
                        :arrowUnder="true"
                    />
                </div>
            </div>

            <div>
                <section-list
                    type="service"
                    @updateHover="updateBlobsColor"
                />
            </div>

        </app-section>

        <app-section
            tag="div"
            :fullheight="true"
            class="p-home__about"
        >

            <div class="grid__row | u-jc-center">
                <div class="t-content | grid__col--12 grid__col--10@sm grid__col--9@md grid__col--8@lg">
                    <anim-text
                        tag="h2"
                        :text='page.aboutTitle'
                        class="t-t1-serif"
                        @show="updateBlobsColor('aqua')"
                    />
                    <btn
                        tag="router-link"
                        :href="'about'|resolver"
                        label="Our methodology"
                        :arrowUnder="true"
                    />
                </div>
            </div>

        </app-section>


        <app-section
            tag="div"
            :fullheight="true"
            id="works"
            class="p-home__works"
        >
            <template v-slot:before>
                <background
                    shape="ridges"
                    :cover="true"
                />
            </template>

            <div class="grid__row | u-jc-center">
                <div class="t-content | grid__col--12 grid__col--10@sm grid__col--9@md grid__col--8@lg">
                    <anim-text
                        tag="h2"
                        :text='page.worksTitle'
                        class="t-t1-serif"
                        @show="updateBlobsColor('blue')"
                    />
                    <btn
                        tag="router-link"
                        :href="'works'|resolver"
                        label="Case studies"
                        :arrowUnder="true"
                    />
                </div>
            </div>

        </app-section>

        <builder :builder="page.builder" class="p-home__builder" />

        <push />

    </div>
</template>

<script>

import AppSection from 'layout/AppSection';

import AppCanvas from 'objects/AppCanvas';
import Icon from 'objects/Icon';
import Blob from 'objects/Blob';
import AnimText from 'objects/AnimText';
import Background from 'objects/Background';

import Btn from 'components/Btn';
import Push from 'components/Push';
import Heading from 'components/Heading';
import SectionList from 'components/SectionList';

import Builder from 'builder/Builder';

import { EventBus } from 'src/event-bus'

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

import { round } from 'src/utils'

export default {
    name: 'Home',
    components: {
        AppCanvas,
        AppSection,
        Icon,
        Blob,
        Background,
        AnimText,
        Btn,
        Heading,
        Push,
        SectionList,
        Builder,
    },
    data: () => ({
        blobsIsVisible: false,
        blobsStyles: false,
    }),
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: this.page.seo.title
        }
    },
    mounted() {

        // Sticky
        this.trigger = ScrollTrigger.create({
            trigger: '#services',
            start: 'top 25%',
            endTrigger: '#works',
            end: '25% top',
            onToggle: (self) => {
                this.blobsIsVisible = self.isActive
            },
        })

        EventBus.$on('bodyHeightUpdate', this.onResize = () => {
            this.trigger.refresh(true)
        })

    },
    methods: {
        updateBlobsColor(color) {
            // Random number between -1 and 1
            const random = round((Math.random() * 2 - 1), 2)
            let styles = `--blobs-coef: ${random};`

            if(color) {
                styles += ` --blobs-gradient: var(--gradient-${color});`
            }

            this.blobsStyles = styles
        },
        scrollToServices() {
            gsap.to(window, {
                duration: 1,
                scrollTo: {
                    y: '#services',
                    offsetY: 20,
                },
                ease: 'circ.inOut',
            })
        }
    },
    beforeDestroy() {
        EventBus.$off('bodyHeightUpdate', this.onResize)
    }
};

</script>

<style lang="scss">

.p-home {

    &__header {
        min-height: calc(100vh - 4em);

        @media #{md("sm", "max")} {
            padding-top: 0;
        }
    }
}

.p-home__header-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;

    .c-btn,
    .o-icon {
        cursor: pointer;
    }

    position: absolute;
    bottom: 5vh;
    left: 0;

    @media #{md("sm")} {
        bottom: 3vh;
    }
}

.p-home__header-arrow {
    margin-bottom: 1em;
}

.p-home__services {

    @media #{md("sm", "max")} {
        margin-top: 20vh;
    }

    margin-top: 50vh;
}

.p-home__about,
.p-home__builder,
.p-home__services,
.p-home__works .l-section__content {
    z-index: 1;
}




/*----------  Blobs  ----------*/

.o-blobs {
    --blobs-coef: 0;
    --blobs-gradient: var(--main-gradient);

    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &.is-visible {

        .o-blobs__item {
            opacity: 1;
            transform: scale(1) rotate(calc(var(--blobs-coef) * 20deg)) translate(calc(var(--blobs-coef) * -70%), calc(var(--blobs-coef) * 50%));
            transition: all .8s $in-out-circ;

            &:nth-of-type(odd) {
                transform: scale(calc(.5 + var(--blobs-coef)/2)) rotate(calc(var(--blobs-coef) * -12deg)) translate(calc(var(--blobs-coef) * 20%), calc(var(--blobs-coef) * -40%));
            }

            &:nth-of-type(3n+1),
            &:nth-of-type(5n+1) {
                transform: scale(calc(1.15 - var(--blobs-coef)/4)) rotate(calc(var(--blobs-coef) * 10deg)) translate(calc(var(--blobs-coef) * -20%), calc(var(--blobs-coef) * -30%));
            }

            html.reduced-motion & {
                transform: none;

            }
        }
    }
}

.o-blobs__item {
    position: absolute;
    display: block;
    opacity: 0;
    transform: scale(0) rotate(calc(var(--blobs-coef) * 90deg));
    transition: opacity .2s $in-sine, transform .2s $in-circ;

    &--1 {
        top: 20%;
        left: 2%;
        width: 3%;
    }

    &--2 {
        bottom: 40%;
        left: 5%;
        width: 5%;
    }

    &--3 {
        bottom: 20%;
        left: 20%;
        width: 10%;
    }

    &--4 {
        top: 10%;
        left: 30%;
        width: 25%;
    }

    &--5 {
        right: 35%;
        top: 30%;
        width: 8%;
    }

    &--6 {
        right: 20%;
        bottom: 30%;
        width: 15%;
    }

    &--7 {
        top: 20%;
        right: 10%;
        width: 4%;
    }

    &--8 {
        right: 0;
        bottom: 15%;
        width: 8%;
    }
}

.o-blobs__blob {
    --blob-bg: var(--blobs-gradient, --main-gradient);
    transition: background .4s ease-out;
}

</style>
