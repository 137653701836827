import { fetchApi } from 'src/graphql/config';
import queries from 'src/graphql/queries';

import router from 'src/router'

/*
** Global
*/

const parseDate = (item) => {

    // Date
    let date = new Date(item.postDate)

    let dd = date.getDate()
    dd = dd < 10 ? `0${dd}` : dd
    let mm = date.getMonth() + 1
    mm = mm < 10 ? `0${mm}` : mm
    const yyyy = date.getFullYear()
    item.date = `${mm}/${dd}/${yyyy}`
    item.dateTime = `${yyyy}-${mm}-${dd}`

    return item
}

// State
const state = {
    articles: [],
    blogList: [],
    careersList: [],
    jobs: [],
};

// Getters
const getters = {
    getArticleBySlug: state => slug => state.articles.find(article => article.slug === slug),
    getArticleIndexBySlug: state => slug => state.articles.findIndex(article => article.slug === slug),
    getJobBySlug: state => slug => state.jobs.find(job => job.slug === slug),
    getJobIndexBySlug: state => slug => state.jobs.findIndex(job => job.slug === slug),
};

// Actions
const actions = {
    loadArticle(store, slug) {
        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Search if page already exists
            const article = store.getters.getArticleBySlug(slug)

            // If page already exists, resolve with the page value
            if(typeof article !== 'undefined' && article.singleFetch) {
                resolve(article)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // If page doesn't exist, load page
            } else {
                fetchApi(queries.article(slug))

                    .then(r => {
                        let data = r.entry
                        data.singleFetch = true
                        data = parseDate(data)

                        fetchApi(queries.articleSiblings(data.id))
                            .then(siblings => {
                                data = Object.assign({}, data, siblings)

                                if (article) {
                                    const index = store.getters.getArticleIndexBySlug(slug)
                                    store.commit('updateArticle', {article: data, index})
                                } else {
                                    store.commit('addArticle', data)
                                }

                                resolve(data)

                                // End load
                                store.dispatch('loader/endLoad', null, {root: true});
                            })

                    })
                    .catch((e) => {

                        router.push({path: '/blog'})

                        setTimeout(() => {
                            store.dispatch('loader/endLoad', null, {root: true});
                        }, 10);

                        setTimeout(() => {
                            store.dispatch('loader/endLoad', null, {root: true});
                        }, 2000);
                    })
            }

        })
    },
    loadJob(store, slug) {
        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Search if page already exists
            const job = store.getters.getJobBySlug(slug)

            // If page already exists, resolve with the page value
            if(typeof job !== 'undefined' && job.singleFetch) {
                resolve(job)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // If page doesn't exist, load page
            } else {
                fetchApi(queries.job(slug))

                    .then(r => {
                        let data = r.entry
                        data.singleFetch = true
                        data = parseDate(data)

                        if (job) {
                            const index = store.getters.getJobIndexBySlug(slug)
                            store.commit('updateJob', {job: data, index})
                        } else {
                            store.commit('addJob', data)
                        }

                        resolve(data)

                        // End load
                        store.dispatch('loader/endLoad', null, {root: true});

                    })
                    .catch((e) => {

                        router.push({path: '/careers'})

                        setTimeout(() => {
                            store.dispatch('loader/endLoad', null, {root: true});
                        }, 10);

                        setTimeout(() => {
                            store.dispatch('loader/endLoad', null, {root: true});
                        }, 2000);
                    })
            }

        })
    },
    loadList(store, name) {
        return new Promise((resolve, reject) => {

            const listName = `${name}List`
            const isBlog = name === 'blog'
            const isCareers = name === 'careers'

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // If latest jobs already exists, resolve with the set value
            if(store.state[listName].length > 0) {
                resolve(store.state[listName])

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // If latest job doesn't exist, load it
            } else {

                fetchApi(queries[listName])
                    .then(data => {
                        const entries = data.entries
                        entries.forEach((entry, i) => {
                            if(!store.getters.getArticleBySlug(entry.slug)) {
                                entry = parseDate(entry)
                                entry.singleFetch = false

                                if(isBlog) {
                                    store.commit('addArticle', entry)
                                } else if(isCareers) {
                                    store.commit('addJob', entry)
                                }
                            }
                        })

                        store.commit('setList', { list: listName, entries: entries })

                        resolve(entries)

                        // End load
                        store.dispatch('loader/endLoad', null, {root: true});
                    });
            }
        })
    },
};

// Mutations
const mutations = {
    setList(state, { list, entries }) {
        state[list] = entries;
    },
    addArticle(state, article) {
        state.articles.push(article);
    },
    updateArticle(state, {article, index}) {
        state.articles[index] = article;
    },
    addJob(state, job) {
        state.jobs.push(job);
    },
    updateJob(state, {job, index}) {
        state.jobs[index] = job;
    },
};


// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
