/*
** Craft asset transform sizes
** Sizes:
** xs => 0 -> 480
** sm => 480 -> 768
** lg => 768 -> 1200
** xlg => 1200 -> 1920
*/

/** Portrait **/
export const portrait = {
    width: 720,
    height: 1080,
    url: `portrait: url @transform (width: 720, height: 1080)`,
}

/** User **/
export const user = {
    width: 150,
    height: 150,
    url: `user: url @transform (width: 150, height: 150)`,
}

export default {
    portrait,
    user,
}
