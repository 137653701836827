<template>
    <div
        :class="className"
        v-reveal="reveal ? revealHandler : false"
    >
            <svg
                v-if="shapeIsRidges || shapeIsRidgesSmall"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 896 636"
                class="o-bg__svg"
            >
                <defs>
                    <linearGradient
                        :id="id"
                        x1="0"
                        y1="1"
                        x2="1"
                        y2="0"
                    >
                        <stop
                            offset="0%"
                            stop-color="var(--main-color-gradient-1)"
                        />
                        <stop
                            offset="100%"
                            stop-color="var(--main-color-gradient-2)"
                        />
                    </linearGradient>
                </defs>
                <path :fill="`url(#${id})`" d="M877,306.2c-17.3-33.8-43.9-49.4-74.6-67.5c-2.5-1.4-5-2.9-7.5-4.4c-29.7-16.8-69-40.6-109.3-76.1l-0.1-0.1c-15.9-13.1-32.2-28.1-48-42.7c-22.5-20.8-45.8-42.4-68.8-58.8c-37.8-28.2-77.1-44.8-120.2-50.6C409.4,0.7,373,0.1,329.5,0C290.8,1,250.7,2.1,211,27c-37,20.9-75.4,64-111.1,124.5c-39.3,65.9-69.8,143.8-81.1,174.2C1.4,376-5.5,407.2,4.9,438.2c9.1,26.6,31.1,53.9,73.6,91.3l0.1,0.1c34.7,28.5,88.7,68.8,155.2,88.9c36.4,11.7,77.4,17.6,122.4,17.6c29.1,0,60-2.5,92.4-7.4c57-8.5,112.7-20.8,161.9-31.6c18-3.9,34.9-7.7,51.4-11.1c7.9-1.8,15.7-3.6,23.3-5.3c52.4-11.7,97.6-21.9,131.8-43.2c38.4-21.8,64.1-57.8,74.2-104C901.1,389.8,895.4,338.7,877,306.2z M883.9,432.1c-9.7,44.3-34.2,78.7-70.9,99.5l-0.1,0.1c-33.1,20.6-77.7,30.6-129.4,42.2c-7.6,1.7-15.4,3.4-23.3,5.3c-16.5,3.4-33.5,7.2-51.4,11.1c-49.1,10.8-104.7,23-161.4,31.5c-81.4,12.3-152.4,9-211.3-9.9c-65.3-19.7-118.3-59.3-152.6-87.5C42,487.9,20.6,461.5,12,436.1c-8.8-26.2-5.4-52.5,13.8-108.2c11.2-30.2,41.5-107.6,80.5-173.1c35.1-59.6,72.6-101.7,108.6-122l0.1-0.1c38-23.9,77-24.9,114.6-25.9c43.1,0.1,79.2,0.7,117.9,5.8c41.7,5.6,79.8,21.7,116.6,49.2c22.6,16.3,45.8,37.6,68.1,58.3c15.9,14.7,32.3,29.8,48.3,43c40.8,35.9,80.4,60,110.4,76.9c2.5,1.5,5,3,7.5,4.4c29.8,17.5,55.5,32.6,71.9,64.7l0.1,0.1C888,340.5,893.5,389.8,883.9,432.1z"/>
                <path v-if="shapeIsRidges" :fill="`url(#${id})`" d="M850.2,306.8c-16.3-31.8-41.2-46.4-70-63.3c-2.3-1.4-4.6-2.7-7.1-4.2c-27.8-15.7-64.6-38-102.3-71.3l-0.1-0.1c-14.9-12.2-30.2-26.4-45-40c-21.1-19.5-43-39.7-64.5-55.2c-35.5-26.5-72.4-42-112.7-47.5c-36.7-4.9-70.8-5.4-111.6-5.5c-36.3,0.9-73.9,1.9-111.2,25.3c-34.7,19.6-70.7,60-104.2,116.8c-36.9,61.8-65.4,134.7-76,163.3c-16.3,47.1-22.8,76.4-13,105.5c8.5,25,29.1,50.6,69,85.6l0.1,0.1c32.6,26.8,83.1,64.5,145.5,83.3c34.1,11,72.5,16.5,114.8,16.5c27.3,0,56.2-2.3,86.7-6.9c53.4-8,105.6-19.5,151.7-29.6c16.8-3.7,32.7-7.2,48.2-10.4c7.4-1.7,14.7-3.3,21.8-4.9c49.1-11,91.5-20.5,123.6-40.5c36.1-20.5,60.1-54.3,69.6-97.6C872.8,385.3,867.4,337.3,850.2,306.8z M856.3,424.9c-9.1,41.4-32,73.7-66.3,93.1l-0.1,0.1c-30.9,19.3-72.8,28.7-121.2,39.5c-7.1,1.6-14.4,3.2-21.8,4.9c-15.4,3.2-31.3,6.7-48.2,10.4c-46,10.1-98.1,21.6-151.2,29.5c-76.2,11.6-142.7,8.4-197.9-9.3c-61.1-18.4-110.8-55.5-142.9-81.9c-38.8-34.2-58.9-58.9-67-82.7c-8.2-24.5-5.1-49.1,13-101.2c10.5-28.3,38.9-100.8,75.4-162.1c32.9-55.8,68-95.3,101.6-114.3l0.1-0.1c35.6-22.3,72.1-23.3,107.2-24.2c40.4,0.1,74.1,0.6,110.4,5.4c39,5.3,74.7,20.4,109.2,46.1c21.2,15.2,42.8,35.2,63.8,54.6c14.9,13.7,30.2,27.9,45.3,40.3c38.2,33.7,75.4,56.2,103.5,72.1c2.4,1.4,4.7,2.8,7,4.1c27.9,16.4,51.9,30.5,67.3,60.5l0.1,0.1C860.1,339.1,865.2,385.3,856.3,424.9z"/>
                <path :fill="`url(#${id})`" d="M820.2,307.4c-13.8-29.3-34.5-40.9-58.3-54.4c-4.2-2.4-8.6-4.8-13-7.4l-0.1-0.1c-29.2-15.8-61.9-39-94.6-67.2c-12.4-10.4-25-21.8-37.3-32.7c-21-18.8-42.7-38.3-64-54.1C514.8,63.1,481.6,49,448.4,47c-33.9-4.5-65.4-4.9-103.1-5.1c-33.6,0.9-68.4,1.8-103,23.5c-32.1,18.2-65.5,55.5-96.5,108.1c-34.1,57.2-60.5,124.6-70.3,151c-15.1,43.6-21.1,70.7-12,97.7c7.9,23.1,27,46.8,63.9,79.3l0.1,0.1c30.1,24.8,76.9,59.7,134.6,77.1c31.6,10.2,67.1,15.3,106.2,15.3c25.3,0,52-2.1,80.2-6.4l0.1,0c78.4-14.9,137.1-26.5,184.7-36.4C689,539,734.3,528.1,768,507.8c34.5-20.3,56.2-50.3,64.5-89.1C841.3,379.8,836.1,333.1,820.2,307.4z M825.2,417.2c-8,37-28.6,65.5-61.3,84.7c-32.7,19.8-77.3,30.4-132.2,42.3c-47.5,9.9-106.1,21.5-184.4,36.4c-70.4,10.7-131.8,7.8-182.8-8.6c-56.5-17-102.4-51.3-132-75.7c-35.9-31.6-54.4-54.4-61.8-76.3c-7.6-22.6-4.7-45.3,12-93.5c9.7-26.2,35.9-93.2,69.7-149.9c30.4-51.5,62.8-88,93.9-105.6l0.1-0.1c32.8-20.6,66.5-21.5,99-22.3c37.3,0.1,68.5,0.6,102.1,5l0.3,0c31.7,1.9,63.6,15.5,100.5,43.1c21.1,15.6,42.6,34.9,63.5,53.6c12.3,11,25,22.4,37.4,32.9c33,28.4,66.2,51.9,95.8,68c4.5,2.6,8.8,5.1,13.1,7.5c22.8,12.9,42.6,24,55.5,51.5l0.2,0.3C828.6,334.6,833.6,380.4,825.2,417.2z"/>
                <path v-if="shapeIsRidges" :fill="`url(#${id})`" d="M790.2,308.1c-12.8-27-31.7-37.7-53.7-50.1c-3.9-2.2-7.9-4.4-11.9-6.8l-0.1-0.1c-26.8-14.5-56.8-35.9-86.8-61.7c-11.3-9.5-23-20-34.2-30.1c-19.3-17.3-39.2-35.2-58.8-49.7c-35-26.1-65.6-39.2-96.2-41c-31.2-4.1-60.1-4.5-94.7-4.7c-30.9,0.8-62.9,1.6-94.8,21.6c-29.6,16.7-60.2,51.1-88.7,99.4c-31.3,52.5-55.6,114.5-64.6,138.8c-13.9,40.1-19.4,65.1-11.1,89.9c7.3,21.3,24.8,43.1,58.8,73l0.1,0.1c27.7,22.8,70.7,54.9,123.8,70.9c29.1,9.4,61.7,14,97.7,14c23.2,0,47.8-2,73.7-5.9l0.1,0c72-13.7,125.9-24.3,169.7-33.5c51.1-11.1,92.7-21.1,123.7-39.8c31.7-18.7,51.7-46.3,59.4-82.1C809.6,374.8,804.8,331.8,790.2,308.1z M794.3,409.1c-7.3,33.9-26.2,60-56.2,77.7c-30,18.1-71,27.9-121.3,38.9c-43.6,9.1-97.5,19.8-169.4,33.4c-64.6,9.8-121,7.1-167.7-7.9c-51.8-15.6-94-47.1-121.2-69.5c-32.9-29-49.9-49.9-56.7-70c-6.9-20.7-4.3-41.5,11-85.7c8.9-24,33-85.6,64-137.7c27.9-47.3,57.7-80.8,86.1-96.9l0.1-0.1c30.1-18.9,61-19.7,90.8-20.5c34.3,0.1,63,0.5,93.7,4.6l0.3,0c29,1.7,58.3,14.2,92.1,39.5c19.4,14.3,39.2,32.1,58.3,49.2c11.3,10.1,23,20.6,34.4,30.2c30.4,26.1,60.8,47.7,88,62.5c4.1,2.4,8.1,4.7,12,6.9c20.9,11.8,39,22,50.8,47.2l0.2,0.3C797.3,333.3,801.9,375.3,794.3,409.1z"/>
                <path :fill="`url(#${id})`" d="M753.8,308.9c-11.4-24.2-28.4-33.8-48.1-44.9c-3.5-1.9-7-4-10.6-6.1l-0.1-0.1c-23.9-13-50.7-32-77.4-55C607.4,194.4,597,185,587,176c-17.2-15.4-35-31.4-52.5-44.4c-31.4-23.4-58.8-35.1-86.2-36.7h-0.1c-13-0.3-26.1-1.4-38.8-2.4c-42.6-3.4-86.6-6.9-130.3,18c-28.2,16.8-55.6,47.2-79.2,87.8c-24.5,41-46,95.2-57.6,124.3L142,323c-12.5,38.6-17.2,57.9-9.8,79.9c7,20,25.2,43,52.6,66.5c23.6,19.9,62,46.9,110.7,62.2c28.8,9.3,59.5,13.9,90.7,13.9c20.7,0,41.7-2,62.6-6.1c64.3-12.2,112.4-21.7,151.5-29.9c45.7-9.9,82.8-18.8,110.5-35.6c28.4-16.7,46.3-41.5,53.2-73.5C771.2,368.6,766.9,330.1,753.8,308.9z M756.7,399.1c-6.5,30.1-23.3,53.4-50,69.1c-26.8,16.1-63.3,24.9-108.2,34.6c-38.9,8.1-87,17.6-151.3,29.9c-51.2,10-102.8,7.4-149.4-7.6c-47.5-14.9-85-41.3-108-60.8c-26.4-22.7-43.9-44.6-50.5-63.5c-6.4-19.1-3.2-35.6,9.8-75.8c11.5-29,33-82.8,57.1-123.3c23.1-39.7,49.6-69.2,76.7-85.3c41.6-23.7,82.5-20.5,125.7-17c12.8,1,26,2.1,39.2,2.4c25.8,1.5,51.8,12.7,81.9,35.2c17.3,12.7,34.9,28.6,52,43.9c10.1,9,20.5,18.4,30.7,27c27.1,23.3,54.3,42.6,78.7,55.8c3.7,2.2,7.2,4.2,10.7,6.1c19.4,10.9,34.7,19.6,45.2,41.9l0.2,0.3C759.4,331.7,763.5,369,756.7,399.1z"/>
                <path v-if="shapeIsRidges" :fill="`url(#${id})`" d="M712.4,309.9c-9.9-21-24.7-29.3-41.7-38.9c-3-1.7-6.1-3.4-9.2-5.3l-0.1-0.1c-20.6-11.2-43.7-27.6-66.8-47.4c-8.7-7.3-17.7-15.4-26.3-23.2c-14.9-13.3-30.2-27.1-45.4-38.3c-27.2-20.3-50.9-30.4-74.7-31.8l-0.1,0c-11.2-0.3-22.5-1.2-33.5-2c-36.8-2.9-74.9-6-112.8,15.6c-24.4,14.5-48.1,40.8-68.6,76c-21.1,35.4-39.8,82.2-49.8,107.4l-0.1,0.2c-10.8,33.5-14.9,50.1-8.5,69.3c6.1,17.3,21.8,37.2,45.6,57.6c20.4,17.2,53.6,40.6,95.7,53.8c24.9,8,51.4,12,78.5,12c17.9,0,36-1.8,54.1-5.3c55.5-10.6,97-18.8,130.8-25.8c39.5-8.6,71.6-16.3,95.6-30.8c24.7-14.5,40.2-36,46.2-63.7C727.5,361.6,723.8,328.3,712.4,309.9z M714,387.9c-5.6,25.9-20,45.8-42.9,59.3c-23,13.9-54.5,21.4-93.2,29.8c-33.6,7-75.1,15.2-130.6,25.8c-44.1,8.6-88.6,6.4-128.7-6.5c-40.9-12.9-73.2-35.6-93.1-52.4c-22.8-19.5-37.8-38.4-43.5-54.6c-5.5-16.3-2.7-30.6,8.5-65.1c9.9-25,28.4-71.4,49.3-106.4c19.9-34.2,42.7-59.6,66-73.5c35.8-20.4,71-17.6,108.2-14.6c11,0.9,22.4,1.8,33.9,2.1c22.2,1.3,44.5,10.9,70.4,30.3c14.9,11,30.1,24.7,44.9,37.9c8.7,7.8,17.7,15.9,26.5,23.3c23.4,20.2,47,36.8,68,48.3c3.2,1.9,6.3,3.6,9.2,5.3c16.7,9.4,29.8,16.8,38.8,36l0.2,0.3C716.3,330,719.8,362.1,714,387.9z"/>
                <path :fill="`url(#${id})`" d="M678.6,312.2c-8.8-18.5-22.4-26.1-36.7-34.2c-2.6-1.5-5.3-3-8-4.6l-0.1-0.1c-18-9.8-38.2-24.1-58.4-41.4c-6.2-5.3-12.4-10.9-18.5-16.3c-35.4-31.6-72.1-64.4-110.4-65.4c-9.8-0.2-19.7-1-29.3-1.8c-32.4-2.6-65.8-5.2-99.2,13.8c-21.5,12.7-42.3,35.8-60.3,66.7c-18.6,31-34.9,72-43.7,94l-0.1,0.2c-9.5,29.4-13.1,44-7.4,60.9c5.4,15.2,19.2,32.7,40,50.5c34.1,30.2,89.7,58,154.2,58c15.1,0,30.6-1.5,46.5-4.9c48.7-9.2,85.1-16.4,114.7-22.6c34.6-7.5,62.8-14.3,83.9-27c21.7-12.8,35.4-31.6,40.7-56.1C691.8,357.7,688.6,328.4,678.6,312.2z M679.1,380.6c-4.9,22.5-17.4,39.9-37.4,51.6c-20.1,12.1-47.7,18.7-81.6,26.1c-29.5,6.2-65.9,13.3-114.6,22.6c-15.3,3.2-30.4,4.7-44.9,4.7c-62.3,0-116.1-26.9-149.1-56.1c-19.9-17-33.1-33.5-38-47.6c-4.8-14.2-2.3-26.6,7.4-56.7c8.7-21.9,24.9-62.4,43.2-93c17.4-29.9,37.4-52,57.7-64.1c31.3-17.8,62-15.3,94.6-12.7c9.7,0.8,19.7,1.6,29.8,1.8c35.4,1,71,32.7,105.4,63.5c6.1,5.4,12.4,11,18.6,16.4c20.6,17.7,41.2,32.3,59.7,42.3c2.8,1.6,5.5,3.2,8.1,4.6c14.5,8.2,26,14.7,33.9,31.3l0.2,0.3C681.1,330.1,684.2,358.1,679.1,380.6z"/>
                <path v-if="shapeIsRidges" :fill="`url(#${id})`" d="M650.6,312.9c-7.8-16.3-19.7-23.1-32.4-30.2c-2.3-1.3-4.7-2.6-7-4l-0.1-0.1c-15.8-8.5-33.5-21.1-51.2-36.3c-5.4-4.7-10.9-9.6-16.2-14.3c-31.2-27.8-63.4-56.6-97.2-57.5c-8.6-0.2-17.3-0.9-25.7-1.6c-28.5-2.3-57.9-4.6-87.4,12.1c-18.9,11.2-37.3,31.5-53.1,58.7c-16.3,27.3-30.7,63.3-38.4,82.6l-0.1,0.2c-8.4,25.9-11.6,38.8-6.5,53.7c4.7,13.5,16.9,28.9,35.2,44.5c30,26.6,79,51,135.6,51c13.3,0,27-1.3,40.9-4.3c42.7-8.1,74.7-14.4,100.7-19.8c30.5-6.6,55.2-12.5,73.8-23.7c19.2-11.3,31.3-27.9,35.9-49.5C662.3,353,659.4,327.2,650.6,312.9z M650.2,373c-4.2,19.7-15.2,34.8-32.6,45.1c-17.6,10.6-41.8,16.4-71.5,22.8c-25.9,5.4-57.8,11.7-100.6,19.8c-13.4,2.8-26.6,4.1-39.3,4.1c-54.5,0-101.6-23.5-130.6-49.2c-17.4-14.9-28.9-29.3-33.2-41.6c-4.2-12.3-2-23.2,6.5-49.5c7.7-19.2,21.9-54.8,37.9-81.6c15.2-26.1,32.7-45.6,50.5-56.1c27.3-15.5,54.2-13.4,82.8-11.1c8.5,0.7,17.3,1.4,26.2,1.6c30.9,0.8,62.1,28.7,92.2,55.6c5.3,4.8,10.9,9.7,16.3,14.4c18.1,15.5,36.2,28.4,52.5,37.2c2.4,1.4,4.8,2.8,7.1,4.1c12.7,7.1,22.7,12.8,29.5,27.3l0.2,0.3C652,328.9,654.6,353.3,650.2,373z"/>
                <path :fill="`url(#${id})`" d="M618.4,313.6c-6.6-13.9-16.7-19.6-27.4-25.6c-1.9-1.1-3.9-2.2-5.9-3.4l-0.1-0.1c-13.2-7.2-28.1-17.7-42.9-30.4c-4.5-3.9-9.2-8.1-13.6-12.1c-26.3-23.4-53.4-47.7-82-48.5c-7.2-0.2-14.5-0.7-21.6-1.3c-24-1.9-48.8-3.9-73.8,10.3c-16,9.5-31.5,26.6-44.8,49.5c-13.7,22.9-25.8,53.2-32.3,69.5l-0.1,0.2c-7.1,21.8-9.8,32.7-5.5,45.4c4.1,11.6,14.1,24.2,29.8,37.6c25.3,22.4,66.5,43,114.4,43c11.2,0,22.8-1.1,34.5-3.6c15.8-3.3,31-6.2,45.6-8.9c41.9-7.9,78-14.7,101-27.6c16.3-9.7,26.5-23.8,30.4-42C628.3,347.5,625.9,325.7,618.4,313.6z M617,364.2c-3.6,16.6-12.5,28.9-27.1,37.6c-21.8,12.3-57.4,18.9-98.6,26.7c-14.7,2.8-29.9,5.6-45.8,9c-59.3,12.6-112.3-11.2-142.2-37.7c-14.5-12.4-24.2-24.5-27.8-34.7c-3.5-10.3-1.8-18.9,5.5-41.3c6.4-16.1,18.4-46,31.8-68.4c12.7-21.9,27.3-38.1,42.2-46.9c22.8-13,45.3-11.2,69.2-9.3c7.2,0.6,14.6,1.2,22,1.3c25.7,0.7,51.8,24,77.1,46.6c4.5,4,9.1,8.1,13.7,12.1c15.2,13.1,30.5,23.9,44.2,31.3c2,1.2,4,2.3,6,3.4c10.6,6,18.9,10.7,24.6,22.6l0.2,0.3C618.5,327.4,620.7,347.8,617,364.2z"/>
                <path v-if="shapeIsRidges" :fill="`url(#${id})`" d="M585.6,314.4c-5.4-11.3-13.7-16-22.4-20.9c-1.5-0.9-3.1-1.8-4.7-2.7l-0.1-0.1c-10.6-5.7-22.5-14.2-34.4-24.4c-3.7-3.2-7.4-6.5-11-9.7c-21.2-19-43.2-38.6-66.6-39.2c-5.8-0.1-11.7-0.6-17.3-1.1c-19.5-1.5-39.6-3.2-59.9,8.4c-13,7.7-25.5,21.6-36.3,40.1c-11.1,18.5-20.8,42.9-26,56l-0.1,0.2c-5.8,17.7-7.9,26.6-4.4,37c3.3,9.4,11.5,19.7,24.2,30.6c20.5,18.2,53.9,34.8,92.6,34.8c9.1,0,18.4-0.9,28-2.9c12.7-2.7,24.9-5,36.7-7.2c33.8-6.4,63-11.8,81.6-22.3c13.3-7.9,21.6-19.5,24.8-34.3C593.7,342,591.7,324.3,585.6,314.4z M583.1,355.4c-2.8,13.1-9.9,22.9-21.5,29.8c-17.5,9.8-46.1,15.2-79.2,21.4c-11.9,2.2-24.1,4.5-36.9,7.2c-9,1.9-17.8,2.8-26.4,2.8c-36.6,0-68.1-15.8-87.5-33c-11.6-9.9-19.3-19.5-22.2-27.7c-2.7-8.1-1.4-15,4.4-32.8c5.2-13,14.8-37,25.5-55c10.2-17.5,21.9-30.5,33.7-37.6c18.1-10.3,36.2-8.9,55.3-7.4c5.8,0.5,11.8,0.9,17.8,1.1c20.4,0.5,41.4,19.2,61.6,37.3c3.6,3.2,7.4,6.6,11.1,9.8c12.3,10.5,24.6,19.3,35.7,25.3c1.6,1,3.2,1.9,4.8,2.8c8.4,4.7,15.1,8.5,19.5,17.9l0.2,0.3C584.3,326.1,586,342.3,583.1,355.4z"/>
                <path :fill="`url(#${id})`" d="M554.1,315c-4.3-8.9-10.7-12.5-17.5-16.4c-1.2-0.7-2.4-1.3-3.6-2.1l-0.1-0.1c-8.1-4.4-17.2-10.8-26.3-18.6c-2.8-2.4-5.7-5-8.4-7.4c-16.4-14.7-33.4-29.9-51.7-30.4c-4.4-0.1-8.9-0.5-13.3-0.8c-15.1-1.2-30.7-2.4-46.5,6.6c-10.1,6-19.8,16.7-28.2,31.1c-8.5,14.3-16,33.1-20.1,43.2l-0.1,0.2c-4.5,13.8-6.2,20.7-3.4,28.9c2.6,7.4,8.9,15.4,18.8,23.8c15.9,14.1,41.7,27,71.8,27c7,0,14.3-0.7,21.7-2.3c9.8-2,19.1-3.8,28.2-5.5c26-4.9,48.5-9.1,63-17.3c10.4-6.2,17-15.2,19.5-26.9C560.4,336.6,558.8,322.8,554.1,315z M550.5,346.7c-2.1,9.9-7.4,17.2-16.1,22.4c-13.3,7.5-35.2,11.6-60.5,16.3c-9.1,1.7-18.5,3.5-28.4,5.6c-36.1,7.7-68.5-6.8-86.7-23c-8.8-7.5-14.6-14.8-16.8-20.9c-2-6-1-11.2,3.4-24.7c4-9.9,11.3-28.3,19.6-42.1c7.8-13.3,16.6-23.2,25.6-28.5c13.7-7.8,27.4-6.7,41.9-5.5c4.5,0.3,9.1,0.7,13.7,0.8c15.4,0.4,31.3,14.7,46.7,28.4c2.8,2.5,5.7,5.1,8.5,7.5c9.5,8.1,19,14.9,27.5,19.5c1.3,0.7,2.5,1.4,3.7,2.1c6.3,3.6,11.3,6.4,14.7,13.4l0.2,0.3C551.5,324.6,552.8,336.8,550.5,346.7z"/>
                <path v-if="shapeIsRidges" :fill="`url(#${id})`" d="M527.9,315.7c-3.3-6.9-8.2-9.7-13.5-12.6c-0.9-0.5-1.8-1-2.7-1.5l-0.1-0.1c-6-3.3-12.8-8-19.5-13.9c-2.1-1.8-4.2-3.7-6.3-5.6c-12.4-11.1-25.3-22.6-39.3-23c-3.3-0.1-6.6-0.3-9.9-0.6c-11.5-0.9-23.3-1.9-35.4,5.1c-7.7,4.6-15.1,12.7-21.4,23.6c-6.4,10.8-12.1,24.9-15.1,32.4l-0.1,0.2c-3.4,10.5-4.7,15.8-2.5,22.2c2,5.7,6.8,11.8,14.3,18.2c12,10.7,31.6,20.5,54.4,20.5c5.3,0,10.8-0.5,16.4-1.7c7.3-1.5,14.3-2.8,21.1-4.1c19.6-3.7,36.5-6.9,47.5-13c8-4.8,13.1-11.7,15-20.7C532.7,332.3,531.5,321.7,527.9,315.7z M523.4,339.6c-1.5,7.1-5.4,12.5-11.6,16.2c-9.8,5.5-26.2,8.6-45.1,12.1c-6.8,1.3-13.9,2.6-21.3,4.2c-26.7,5.7-50.6-5.1-64.1-17c-6.5-5.6-10.8-10.9-12.3-15.3c-1.4-4.3-0.7-8.1,2.5-18c3-7.4,8.4-21.1,14.6-31.4c5.8-9.9,12.3-17.2,18.9-21.1c10-5.7,20.1-4.9,30.9-4c3.3,0.3,6.8,0.5,10.3,0.6c11.2,0.3,23,10.9,34.4,21c2.1,1.9,4.3,3.8,6.4,5.7c7.1,6.1,14.3,11.2,20.8,14.7c0.9,0.6,1.9,1.1,2.8,1.6c4.8,2.7,8.2,4.6,10.6,9.7l0.2,0.3C524.1,323.4,525.1,332.5,523.4,339.6z"/>
                <path :fill="`url(#${id})`" d="M500.2,316.4L500,316c-2.9-4.6-7.6-7.5-12.5-10.6c-3.5-2.2-7.1-4.5-10.8-7.7l-0.2-0.2c-2.5-1.8-5-4-7.6-6.4c-7.1-6.4-14.5-13-23-12.1c-2.8,0.3-5.7,0-8.8-0.4c-6.4-0.7-13.6-1.5-20.8,3.5c-10.1,5.9-19.5,23.1-24,36.7c-5.2,13.1-1.6,19.3,8,27.5l0.2,0.1c12.1,9.1,23.9,13.7,35.4,13.7c3.9,0,7.7-0.5,11.5-1.6c7-1.9,13.9-3.1,19.9-4.1c9.9-1.6,18.5-3,24.6-7.3C503.2,340.7,505,326.4,500.2,316.4z M487.9,341.6l-0.3,0.2c-4.8,3.3-12.6,4.6-21.6,6.1c-6.3,1-13.3,2.2-20.7,4.2c-12.5,3.4-25.9-0.2-40-10.8c-8.6-7.3-9.6-10.9-5.9-20l0.1-0.2c4.2-12.6,13-28.4,21-33.1l0.3-0.2c4.9-3.4,9.6-2.9,15.6-2.3c3.3,0.4,6.8,0.7,10.4,0.4c5.1-0.5,11.2,5,17.1,10.2c2.6,2.3,5.3,4.7,8,6.7c4,3.5,8,6.1,11.6,8.3c4.5,2.8,8,5.1,10.2,8.2C497.8,328.6,493.9,338.1,487.9,341.6z"/>
                <path v-if="shapeIsRidges" :fill="`url(#${id})`" d="M471,317.2l-0.2-0.4c-1.5-2.3-3.7-3.7-5.9-5.1c-1.4-0.9-2.9-1.8-4.3-3.1l-0.2-0.2c-1-0.7-2-1.6-3-2.6c-3.2-2.9-6.8-6.1-11.4-5.7c-0.9,0.1-2,0-3.2-0.1c-2.8-0.3-6.5-0.7-10.2,1.8c-5.3,3.2-9.4,11.8-10.9,16.5c-2.6,6.4-0.5,9.9,3.9,13.7l0.2,0.1c5.5,4.1,10.9,6.2,16.3,6.2c1.8,0,3.7-0.2,5.5-0.7c2.8-0.8,5.5-1.2,8.2-1.7c4.4-0.7,8.2-1.3,11.2-3.4C471.8,329.6,473.7,322.9,471,317.2z M462.7,326.9l-0.2,0.2c-1.6,1.1-4.7,1.6-8.2,2.2c-2.7,0.4-5.7,0.9-9,1.8c-4.5,1.2-9.5-0.1-14.8-4.1c-2.9-2.5-3.1-3.2-1.9-6.2l0.1-0.2c1.9-5.6,5.4-11.4,7.9-12.8l0.3-0.2c1.3-0.9,2.6-0.8,5-0.6c1.4,0.2,3.1,0.3,4.9,0.2c1.2-0.1,3.8,2.2,5.5,3.8c1.1,1,2.2,2,3.4,2.9c1.8,1.6,3.5,2.7,5.1,3.6c1.6,1,2.9,1.8,3.6,2.8C465.3,322.5,464.9,325.7,462.7,326.9z"/>
            </svg>
    </div>
</template>

<script>

import Icon from 'objects/Icon';

import { EventBus } from 'src/event-bus'

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger);

export default {
    name: 'Background',
    props: {
        shape: {
            type: String,
            default: 'ridges',
        },
        reveal: {
            type: Boolean,
            default: true,
        },
        cover: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isRevealed: false,
    }),
    mounted() {
        this.$paths = gsap.utils.toArray(this.$el.querySelectorAll('path'))
    },
    computed: {
        className() {
            let classname = `o-bg`

            if(this.shapeIsRidges || this.shapeIsRidgesSmall) {
                classname += ` -ridges`
            } else {
                classname += ` -${this.shape}`
            }

            if(this.cover) {
                classname += ` o-bg--cover`
            }

            return classname
        },
        id() {
            return `gradient-${this._uid}`
        },
        shapeIsRidges() {
            return this.shape === 'ridges'
        },
        shapeIsRidgesSmall() {
            return this.shape === 'ridges-small'
        },
    },
    methods: {
        revealHandler(state) {
            this.isRevealed = state.isActive
        },
        show() {
            gsap.fromTo(this.$paths,
                {
                    scale: .5,
                    opacity: 0,
                },
                {
                    stagger: .03,
                    scale: .9,
                    opacity: 1,
                    duration: 1,
                    delay: .4,
                    overwrite: 'all',
                    ease: 'elastic.out',
                }
            )
        },
        hide(duration=.25) {
            gsap.to(this.$paths, {
                stagger: .02,
                scale: .5,
                opacity: 0,
                duration,
                overwrite: 'all',
                ease: 'expo.in',
            })
        },
        parallax(scale) {
            gsap.to(this.$paths, {
                stagger: .02,
                duration: .2,
                scale,
                ease: 'sine.out',
            })
            gsap.to(this.$el, {
                duration: .4,
                x: `${Math.abs(1 - scale) * 20}%`,
                ease: 'sine.out',
            })
        },
    },
    watch: {
        isRevealed(revealed) {
            if(revealed) {
                this.show()
            } else {
                this.hide(0)
            }
        }
    }
}
</script>

<style lang="scss">

.o-bg {
    --bg-width: 100%;

    position: absolute;
    top: 0;
    left: 0;
    width: var(--bg-width);
    height: 0;
    padding-top: var(--bg-height);

    &.-ridges {
        --bg-width: calc(10/12 * 100%);
        --bg-height: calc(636/896 * var(--bg-width));

        will-change: transform;
    }

    &--cover {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

    }

    &.js-reveal {
        opacity: 0;

        &.is-visible {
            opacity: 1;
        }
    }

    @media #{md("sm")} {

        &.-ridges {
            --bg-width: calc(9/12 * 100%);
        }
    }
}

.o-bg__svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

</style>
