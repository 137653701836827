<template>
    <div class="b-team">
        <anim-text
            tag="h2"
            text="Team"
            class="b-team__title | t-small"
        />
        <ul class="b-team__list">
            <li
                v-for="member in team"
                :key="`member-${member.slug}`"
                class="b-team__member | o-member"
                :class="{ 'o-member--long' : member.desc !== null }"
            >

                <blob
                    v-if="member.image.length > 0"
                    :image="member.image[0]"
                    class="o-member__photo"
                />

                <div class="o-member__details">
                    <anim-text
                        tag="h3"
                        :text="member.title"
                        class="o-member__name | t-t2-serif"
                    />

                    <anim-text
                        tag="h4"
                        :text="member.position"
                        class="o-member__job | t-small"
                    />

                    <a
                        v-if="member.phone !== null"
                        :href="`tel:${phoneLink(member.phone)}`"
                        :title="`Call ${member.title}`"
                        target="_blank"
                        rel="noopener"
                        class="o-member__phone | t-link-gradient"
                        v-cursor
                    >
                        <anim-text :text="member.phone" />
                    </a>

                    <a
                        v-if="member.email !== null"
                        :href="`mailto:${member.email}`"
                        title="Send an email"
                        target="_blank"
                        rel="noopener"
                        class="o-member__email | t-link-gradient"
                        v-cursor
                    >
                        <anim-text :text="member.email" />
                    </a>
                    <ul
                        v-if="member.socials.length > 0"
                        class="o-member__socials | o-socials"
                    >
                        <li
                            v-for="(social, i) in member.socials"
                            :key="`member-${member.slug}-social-${i}`"
                        >
                            <a
                                :href="social.website"
                                :title="`${member.title} - ${social.media}`"
                                target="_blank"
                                rel="noopener"
                                class="t-link-gradient"
                                v-cursor
                            >
                                <icon :icon="social.media" />
                            </a>
                        </li>
                    </ul>
                </div>
                <anim-text
                    v-if="member.desc !== null"
                    :cms="true"
                    :text="member.desc"
                    class="o-member__desc"
                />
                <div
                    v-if="member.signature.length > 0"
                    class="o-member__signature"
                >
                    <anim-text
                        :text="member.title"
                        class="t-small"
                    />
                    <asset
                        v-bind="member.signature[0]"
                        :reveal="false"
                        :setAnimation="false"
                    />

                </div>
            </li>
        </ul>
    </div>
</template>

<script>

import Blob from 'objects/Blob'
import Icon from 'objects/Icon'
import Asset from 'objects/Asset'
import AnimText from 'objects/AnimText'

export default {
    name: 'Push',
    components: {
        Blob,
        Icon,
        Asset,
        AnimText,
    },
    data: () => ({
        team: []
    }),
    created() {
        this.$store
            .dispatch('global/loadTeam')
            .then(team => {
                this.team = team
            })
    },
    methods: {
        phoneLink(number) {
            number = number.replace(/\D/g,'')
            return `+1${number}`
        }
    },
};

</script>

<style lang="scss">

.b-team {}

.b-team__title {
    margin-bottom: 2rem;
}

.b-team__list {
    @include reset-list;
}



/*----------  Member  ----------*/

.o-member {
    display: grid;
    grid-gap: 2em;
    margin-bottom: 5vh;

    &--long {
        margin-top: calc(25% - 2 * var(--box-padding));
        padding: var(--box-padding);
        background-color: var(--main-color);

        .o-member__photo {
            margin-top: -25%;
        }
    }

    @media #{md("xs")} {
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: var(--box-padding);

        &:nth-child(even) {
            grid-auto-flow: dense;

            .o-member__photo {
                grid-column: 3 / 4;
            }

            .o-member__details {
                grid-column: 1 / 3;
            }
        }

        .o-member__photo {
            margin-left: auto;
        }
    }

    @media #{md("sm")} {
        grid-template-columns: repeat(2, 1fr);
        padding-bottom: 0;

        &:nth-child(even) {
            grid-auto-flow: dense;

            .o-member__photo {
                grid-column: 2 / 3;
            }

            .o-member__details {
                grid-column: 1 / 2;
            }
        }

        &--long {

            .o-member__photo {
                margin-top: -50%;
            }
        }
    }
}

.o-member__photo {
}

.o-member__details {

    @media #{md("xs", "max")} {
        text-align: center;
    }

    @media #{md("xs")} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        grid-column: 2 / 4;
    }

    @media #{md("sm")} {
        grid-column: 2 / 3;
    }
}

.o-member__name {
    margin-bottom: .5em;
}

.o-member__job {
    padding-bottom: .5em;
}

.o-member__phone .o-anim-text {
    --a-at-in-delay: calc(var(--a-at-in-duration)/3);
}

.o-member__email .o-anim-text {
    --a-at-in-delay: calc(var(--a-at-in-duration)/2);
}

.o-member__socials {

    @media #{md("xs", "max")} {
        justify-content: center;
    }
}

.o-member__desc {
    padding-top: 2em;
    padding-bottom: 2em;
    border-top: $border-grey;

    @media #{md("xs")} {
        grid-column: 1 / 4;
        column-count: 2;
        column-gap: var(--box-padding);
        // Fix overflow bug
        transform: translateX(0);

        p {
            break-inside: avoid;
        }
    }

    @media #{md("sm")} {
        grid-column: 1 / 3;
    }
}

.o-member__signature {
    margin-top: -3em;
    padding-top: 1em;
    padding-bottom: 2em;
    border-top: $border-grey;

    .o-asset__img {
        max-width: 10em;
    }

    @media #{md("xs")} {
        grid-column: 1 / 4;
    }

    @media #{md("sm")} {
        grid-column: 2 / 3;
    }
}


/*----------  Social list  ----------*/

.o-socials {
    @include reset-list;
    left: -1em;
    display: flex;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $accessible-size;
        height: $accessible-size;
    }

    .t-link-gradient:before {
        top: calc(50% + .75em);
        left: calc(50% - 1em/2);
        width: 1em;
    }
}

</style>
