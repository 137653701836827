<template>
    <div
        v-if="data"
        class="b-content"
    >
        <heading
            :header="data.heading"
            titleClass="t-t2-serif"
            class="b-content__heading"
        />
        <anim-text
            :cms="true"
            :text="data.body"
            class="b-content__body"
        />

        <btn
            v-if="data.button[0] && data.button[0].buttonEntry[0]"
            tag="router-link"
            :href="`/${data.button[0].buttonEntry[0].uri}`"
            :label="data.button[0].buttonLabel || data.button[0].buttonEntry[0].title"
            class="b-content__button"
        />
    </div>
</template>

<script>

import AnimText from 'objects/AnimText'
import Heading from 'components/Heading'
import Btn from 'components/Btn';

export default {
    name: 'BlockContent',
    components: {
        AnimText,
        Heading,
        Btn
    },
    props: {
        data: false,
    },
};

</script>

<style lang="scss">

.b-content {
    z-index: 5;
}

.b-content__heading {

    & + .b-content__body {
        margin-top: 3em;
    }

    .c-heading__inner {
        width: 100%;
    }
}

.b-content__body {
}

.b-content__button {
    margin-top: 1rem;
}

</style>
