<template>
    <div>
        <app-section>

            <heading
                :header="page.heading"
                class="p-listing__heading"
            >
                <template v-slot:after>
                    <div class="p-listing__filters">
                        <div class="p-listing__sort | o-inline-list">
                            <btn
                                label="Newest"
                                :lineUnder="true"
                                :class="{ 'is-active' : sortItems === 1 }"
                                @click.native="sortItems = 1"
                            />
                            <btn
                                label="Oldest"
                                :lineUnder="true"
                                :class="{ 'is-active' : sortItems === -1 }"
                                @click.native="sortItems = -1"
                            />
                        </div>
                        <div
                            v-if="isBlog"
                            class="o-inline-list"
                        >
                            <h3 class="t-small">Category</h3>
                            <app-select
                                placeholder="All"
                                :options="categories"
                                as="title:id"
                                v-model="filterCategory"
                            />
                        </div>
                    </div>
                </template>
            </heading>

            <div
                v-if="totalItems > 0"
                class="c-listing"
            >

                <blob
                    v-for="i in 5"
                    :key="`blog-blob-${i}`"
                    :class="`c-listing__blob c-listing__blob--${i}`"
                />

                <transition-group
                    name="a-listing"
                    class="c-listing__list"
                    tag="ul"
                >

                    <li
                        v-for="item in itemsListed"
                        :key="`item-${item.slug}`"
                        class="c-listing__item"
                    >
                        <router-link
                            :to="`${currentRoute.path}/${item.slug}`"
                            :title="item.title"
                            class="c-listing__link"
                        >
                            <span
                                class="c-listing__image"
                            >
                                <asset
                                    v-if="item.image.length > 0"
                                    v-bind="item.image[0]"
                                    :cover="true"
                                />
                            </span>
                            <span class="c-listing__content">
                                <icon
                                    icon="arrow-stroke-right"
                                    class="c-listing__arrow"
                                />
                                <ul
                                    v-if="isBlog && item.articleCategories.length > 0"
                                    class="c-listing__categories | o-inline-list"
                                >
                                    <li
                                        v-for="cat in item.articleCategories"
                                        :key="`item-${item.slug}-cat-${cat.slug}`"
                                    >
                                        <span class="t-small">
                                            {{ cat.title }}
                                        </span>
                                    </li>
                                </ul>
                                <h3 class="c-listing__title | t-t2-serif">{{ item.title }}</h3>
                                <span
                                    v-if="isBlog"
                                    class="c-listing__info | o-inline-list-secondary"
                                >
                                    <time
                                        class="t-small"
                                        :datetime="item.dateTime"
                                    >
                                        {{ item.date }}
                                    </time>
                                    <span v-if="item.author">{{ item.author.fullName }}</span>
                                </span>
                            </span>
                        </router-link>
                    </li>
                </transition-group>


                <div
                    v-if="paginationCount < totalItems"
                    class="c-listing__more"
                >
                    <btn
                        label="Load More"
                        :lineUnder="true"
                        @click.native="loadMore"
                    />
                </div>
            </div>
        </app-section>

        <push />
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import Blob from 'objects/Blob';
import Icon from 'objects/Icon';
import Asset from 'objects/Asset';
import AppSelect from 'objects/AppSelect';
import Btn from 'components/Btn';
import Push from 'components/Push';
import Heading from 'components/Heading';

import uniqBy from 'lodash/uniqBy';

export default {
    name: 'Listing',
    components: {
        AppSection,
        Blob,
        Icon,
        Asset,
        AppSelect,
        Btn,
        Push,
        Heading,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: this.page.seo.title
        }
    },
    data: () => ({
        allItems: [],
        filteredItems: [],
        sortItems: 1,

        itemsListed: [],
        pagination: 8,
        paginationCount: 0,

        filterCategory: '',
    }),
    created() {

        this.$store
            .dispatch('listing/loadList', this.currentRoute.name)
            .then(items => {
                // Add items to data
                this.allItems = [...items]

                // Order items
                this.filteredItems = this.allItems.sort((a, b) => this.sortItems > 0 ? new Date(b.postDate) - new Date(a.postDate) : new Date(a.postDate) - new Date(b.postDate))

                this.setItems(true)

            })
    },
    methods: {
        loadMore() {
            this.paginationCount += this.pagination

            this.setItems()
        },
        setItems(resetPagination=false) {
            //console.log('setItems', this.totalItems)

            if (resetPagination) {
                this.paginationCount = this.pagination
            }

            this.itemsListed = this.filteredItems.slice(0, this.paginationCount)

        },
    },
    computed: {
        currentRoute() {
            return this.$router.currentRoute
        },
        categories() {
            let categories = [...new Set(this.allItems.map(article => article.articleCategories))]

            // Flatten array and remove duplicates
            categories = categories.flat()
            categories = uniqBy(categories, 'id');

            return categories
        },
        totalItems() {
            return this.filteredItems.length
        },
        isBlog() {
            return this.currentRoute.name === 'blog'
        },
        isCareers() {
            return this.currentRoute.name === 'careers'
        },
    },
    watch: {
        sortItems(order) {
            this.filteredItems.reverse()
            this.setItems()
        },
        filterCategory(filter) {
            if(!!filter) {
                this.filteredItems = this.allItems.filter(article => article.articleCategories.find(cat => cat.id === filter))
            } else {
                this.filteredItems = this.allItems
            }

            this.setItems(true)
        },

    }
};

</script>

<style lang="scss">

.p-listing__heading {

    @media #{md("sm")} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: var(--box-padding);
        align-items: flex-end;
        margin-bottom: calc(var(--section-padding)/2);

        .c-heading__inner {
            width: 100%;
        }

        .c-heading__after {
            grid-area: 1 / 2 / 2 / 3;
        }
    }

    @media #{md("md")} {
        grid-template-columns: 1fr 2fr;

    }
}

.p-listing__filters {
    display: flex;
    justify-content: space-between;

    @media #{md("md", "max")} {
        flex-direction: column;
        margin-top: 5vh;
    }

    @media #{md("md")} {
        align-items: center;
    }
}

.p-listing__sort {

    .c-btn {
        color: $color-grey;
        transition: color .2s ease-out;

        &.is-active {
            color: $color-dark;
        }
    }

    @media #{md("md", "max")} {
        margin-bottom: 1.5em;
    }
}



.c-listing__blob {
    position: absolute;

    &--1 {
        --size: 7%;
        top: 5%;
        right: 0;
    }

    &--2 {
        --size: 20%;
        top: 20%;
        right: -10%;
    }
    &--3 {
        --size: 5%;
        top: 75%;
        left: 40%;
    }

    &--4 {
        --size: 10%;
        bottom: 25%;
        left: 0;
    }

    &--5 {
        --size: 7%;
        bottom: 0;
        right: 20%;
    }
}

.c-listing__more {
    margin-top: 7.5vh;
    text-align: center;
}


/*----------  Blog list  ----------*/

.c-listing__list {
    @include reset-list;
}

.c-listing__item {
    padding-top: 1em;
    padding-bottom: 1em;
    transform-origin: 50% 100%;
}

.c-listing__link {
    display: block;
    overflow: hidden;

    @media #{md("xs")} {
        padding-left: 35%;

        &:hover {

            .c-listing__image {
                transform: scale(1);
            }

            .c-listing__arrow {
                transform: translate(1em, 0);
            }
        }
    }

    @media #{md("md")} {
        padding-left: 30%;
    }
}

.c-listing__image {
    background-image: var(--main-gradient);

    .o-asset {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @media #{md("xs", "max")} {
        width: 100%;
        height: 0;
        padding-top: 100%;
    }

    @media #{md("xs")} {
        position: absolute;
        top: 0;
        left: 0;
        width: 35%;
        height: 100%;
        transform: scale(1.1);
        transform-origin: 100% 50%;
        transition: transform .3s $out-quad;
    }

    @media #{md("md")} {
        width: 30%;
    }
}

.c-listing__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media #{md("xs")} {
        flex: 1 0 75%;
        padding: 1em 1em 1em 2em;
        border-top: $border-grey;
        border-bottom: $border-grey;
    }

    @media #{md("md")} {
        padding-right: 5%;
        padding-left: 15%;
    }
}

.c-listing__arrow {
    margin-left: auto;

    @media #{md("xs")} {
        transition: transform .3s $out-quad;
    }
}

.c-listing__categories {
    @include reset-list;
}

.c-listing__title {
    margin-bottom: .75em;
}

.c-listing__info {

    @media #{md("xs")} {
        margin-bottom: 2em;
    }
}

.c-listing__date {}

.c-listing__author {}


/*----------  Animation  ----------*/

.a-listing-enter {
    opacity: 0;
    transform: translate(0, 1em) scale(.95);
}

.a-listing-enter-active {
    transition: opacity .4s ease-out .3s, transform .4s $out-expo .3s;
}

.a-listing-leave-to {
    opacity: 0;
    transform: translate(0, 1em) scale(.95);
}

.a-listing-leave-active {
    transition: opacity .2s ease-in, transform .2s $in-quad;
}

.a-listing-leave-active {
    position: absolute;
    width: 100%;
}


</style>
