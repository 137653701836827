import {fetchApi} from 'src/graphql/config';
import queries from 'src/graphql/queries';
import {linkResolver} from 'src/router';

import router from 'src/router'

/*
** Global
*/

const matchingSocials = {
    tiktok: 'TikTok',
    linkedin: 'LinkedIn'
}

// State
const state = {
    nav: [],
    footer: {},
    general: {},
    clients: [],
    currentPage: {},
    currentPageName: null,
    workList: [],
    serviceList: [],
    team: [],
};

// Getters
const getters = {
    getCurrentPage: state => state.currentPage,
    getCurrentPageName: state => state.currentPageName,
};

// Actions
const actions = {
    // Initial load for navigation, globals, etc...
    initLoad(store) {
        return Promise.all([
            store.dispatch('loadNav'),
            store.dispatch('loadFooter'),
            store.dispatch('loadGeneral')
        ]);
    },
    loadNav(store) {
        fetchApi(queries.nav)
            .then(data => {

                // Format nav and add route path to each nav item
                const nav = data.globalSet.navNodes;

                nav.forEach(item => {
                    item.entry = item.entry[0];
                    item.path = linkResolver(item.entry.sectionHandle);
                });

                store.commit('setNav', nav);
            });
    },
    loadFooter(store) {
        fetchApi(queries.footer)
            .then(data => {

                data = {
                    socials: data.socials.socials.map(({media, website}) => ({
                        media,
                        website,
                        label: matchingSocials[media] || media,
                    })),
                    details: data.details
                }

                store.commit('setFooter', data);
            });
    },
    loadGeneral(store) {
        fetchApi(queries.general)
            .then(data => {
                store.commit('setGeneral', data.globalSet);
            });
    },
    loadClients(store) {

        return new Promise((resolve) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            let clients = store.state.clients

            if(clients.length > 0) {

                resolve(clients)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});
            } else {
                fetchApi(queries.clients)
                    .then(data => {
                        clients = data.globalSet.clients
                        store.commit('setClients', clients);
                        resolve(clients)

                        // End load
                        store.dispatch('loader/endLoad', null, {root: true});
                    });

            }
        })
    },

    loadContent(store, to) {

        return new Promise((resolve) => {

            // Timer in seconds
            const timer = new Date

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Load page content
            let fetchContent;
            const handle = to.meta.section;
            const type = to.meta.type;
            const parent = to.meta.parent;

            // For channel entries, load specific page with id
            if (type === 'channel' || type === 'structure') {
                const slug = to.params.slug;
                fetchContent = store.dispatch('pages/loadEntry', { handle, slug, singleFetch: true }, {root: true});

            // For single entries, load page with handle
            } else {
                fetchContent = store.dispatch('pages/loadSingle', handle, {root: true});
            }

            fetchContent
                .then((page) => {

                    // Duration in ms of the fetchContent + 800ms for the loader cover delay (see loader component) + 10ms for latency
                    let delay = (timer - new Date) + 800 + 10
                    delay = delay < 0 ? 0 : delay

                    setTimeout(() => {
                        store.commit('setPage', page);
                        resolve(page);

                        // Let builder render
                        setTimeout(() => {
                            store.dispatch('loader/endLoad', null, {root: true});
                        }, 10);

                    }, delay);
                })
                .catch(() => {

                    let path = parent ? `/${parent}` : '/'
                    router.push({path})

                    setTimeout(() => {
                        store.dispatch('loader/endLoad', null, {root: true});
                    }, 10);

                    setTimeout(() => {
                        store.dispatch('loader/endLoad', null, {root: true});
                    }, 2000);
                });

        });
    },
    updatePage(store, page) {
        store.commit('setPage', page)
    },
    updatePageName(store, name) {
        store.commit('setPageName', name)
    },
    loadList(store, handle) {

        const stateKey = `${handle}List`

        return new Promise((resolve) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            const list = store.state[stateKey]

            if (list.length > 0) {
                resolve(list)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            } else {

                fetchApi(queries[stateKey])

                    .then(r => {
                        store.commit('setList', {key: stateKey, list: r.entries})
                        resolve(r.entries)

                        // End load
                        store.dispatch('loader/endLoad', null, {root: true});
                    })
            }
        })
    },
    loadTeam(store) {

        return new Promise((resolve) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            const team = store.state.team

            if (team.length > 0) {
                resolve(team)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            } else {

                fetchApi(queries.team)

                    .then(r => {
                        store.commit('setTeam', r.entries)
                        resolve(r.entries)

                        // End load
                        store.dispatch('loader/endLoad', null, {root: true});
                    })
            }
        })

    }
};

// Mutations
const mutations = {
    setNav(state, nav) {
        state.nav = nav;
    },
    setFooter(state, data) {
        state.footer = data;
    },
    setGeneral(state, data) {
        state.general = data;
    },
    setClients(state, clients) {
        state.clients = clients;
    },
    setPage(state, page) {
        state.currentPage = page;
    },
    setPageName(state, name) {
        state.currentPageName = name
    },
    setList(state, {key, list}) {
        state[key] = list
    },
    setTeam(state, team) {
        state.team = team
    },
};


// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
