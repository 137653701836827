<template>
    <div
        v-if="data && data.headingList.length > 0"
        :class="className"
    >
        <blob class="b-stats__blob b-stats__blob--1" :parallax=".9" />
        <blob class="b-stats__blob b-stats__blob--2" :parallax="-.3" />

        <div class="b-stats__inner">
            <div
                v-for="(item, i) in data.headingList"
                :key="`stats-${_uid}-${i}`"
                class="b-stats__item"
            >
                <heading
                    :header="{ sup: item.sup, mainTitle: item.mainTitle, desc: item.desc}"
                    titleClass="t-t2-serif"
                />
            </div>
        </div>
    </div>
</template>

<script>

import Blob from 'objects/Blob'
import Heading from 'components/Heading'

export default {
    name: 'BlockStatsList',
    components: {
        Blob,
        Heading,
    },
    props: {
        data: false,
        highlight: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        className() {
            let classname = 'b-stats'

            if (this.highlight) {
                classname += ' b-stats--highlight'
            }

            return classname
        }
    }
};

</script>

<style lang="scss">

.b-stats {

    .c-heading__inner {
        width: 100%;
    }


    @media #{md("xs")} {
        padding-right: var(--box-padding);
        padding-left: var(--box-padding);
    }

    @media #{md("sm")} {
        padding-right: 0;
        padding-left: 0;

        &--highlight {

            .b-stats__inner {
                grid-column-gap: var(--box-padding);
                grid-template-columns: repeat(2, 1fr);

                &:before {
                    @include pseudo-el($width: 1px, $height: calc(100% - 2 * 2em), $bg: $color-grey);
                    position: absolute;
                    top: 2em;
                    left: calc(50% - 1px/2);
                }
            }

            .b-stats__item:last-child {
                grid-area: 1 / 2 / 99 / 2;
                display: flex;
                flex-direction: column;
                justify-content: center;
                border-top: 0;
            }
        }
    }

    @media #{md("md")} {

        &--highlight {

            .b-stats__inner {
                grid-column-gap: calc(2 * var(--box-padding));
            }
        }
    }
}

.b-stats__inner {

    @media #{md("sm")} {
        display: grid;
    }
}

.b-stats__blob {
    position: absolute;

    &--1 {
        --size: 7%;

        bottom: 15%;
        left: 47%;
    }

    &--2 {
        --size: 50%;

        top: 0;
        right: -20%;
    }
}

.b-stats__item {
    padding-top: 1em;
    padding-bottom: 1em;

    &:not(:first-child) {
        border-top: $border-grey;
    }

    @media #{md("xs")} {
        padding-top: 2em;
        padding-bottom: 2em;
    }
}

</style>
