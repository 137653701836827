<template>
    <div :class="className">
        <loader />

        <app-header @toggleNav="toggleNav" />

        <main
            ref="main"
            class="l-main"
        >
            <router-view v-if="currentPage" :page="currentPage" />
        </main>

        <app-footer />
        <app-cursor />
    </div>
</template>

<script>

import AppHeader from 'layout/AppHeader';
import AppFooter from 'layout/AppFooter';
import AppCursor from 'objects/AppCursor';

import Loader from 'objects/Loader';

export default {
    name: 'AppBase',
    components: {
        AppHeader,
        AppFooter,
        Loader,
        AppCursor,
    },
    data: () => ({
        navIsOpen: false,
    }),
    computed: {
        className() {
            let classname = 'l-wrapper'

            // Add nav state
            classname += this.navIsOpen ? ' nav-is-open' : ' nav-is-closed'

            if (this.currentPageName) {
                classname += ` p-${this.currentPageName}`
            }

            // Color
            if ( this.currentPage ) {

                const color = this.currentPage.color
                if (color) {
                    classname += ` -color-${color}`
                }
            }

            return classname
        },
        currentPage() {
            return this.$store.getters['global/getCurrentPage']
        },
        currentPageName() {
            return this.$store.getters['global/getCurrentPageName']
        },
    },
    methods: {
        toggleNav(isOpen) {
            this.navIsOpen = isOpen
        },
    },
    watch: {
        $route(to, from) {
            this.$store.dispatch('global/updatePageName', to.name)
        },
    },
}

</script>

<style lang="scss">

</style>
