<template>
    <div
        :class="className"
        v-cursor
    >
        <textarea
            v-if="type === 'textarea'"
            :id="`input-${_uid}`"
            class="o-input-text__input"
            v-model="value"
            :required="required"
            @focus="isActive = true"
            @blur="isActive = false"
        ></textarea>
        <input
            v-else
            :id="`input-${_uid}`"
            class="o-input-text__input"
            v-model="value"
            :type="type"
            :required="required"
            :autocomplete="autocomplete !== false"
            :name="autocomplete"
            @focus="isActive = true"
            @blur="isActive = false"
        />
        <label
            v-if="label && !disabled"
            :for="`input-${_uid}`"
            class="o-input-text__label"
        >
            <span class="o-input-text__text">
                {{ required ? `${label}*` : label }}
            </span>
        </label>
    </div>
</template>

<script>

export default {
    name: 'AppInputText',
    props: {
        label: false,
        required: {
            type: Boolean,
            default: false,
        },
        modifier: {
            type: String,
            default: 'text',
        },
        type: {
            type: String,
            default: 'text',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        autocomplete: false,
        defaultValue: false
    },
    data: () => ({
        value: '',
        isActive: false,
        isFilled: false,
    }),
    mounted() {
        if(this.defaultValue) {
            this.value = this.defaultValue
        }
    },
    computed: {
        className() {
            let className = 'o-input-text'

            if(this.textarea) {
                className += ' o-input-text--textarea'
            }

            if(this.disabled) {
                className += ' is-disabled'
            }

            if (this.isActive) {
                className += ' is-active'
            }

            if(this.isFilled) {
                className += ' is-filled'
            }

            return className
        },
        textarea() {
            return this.type === 'textarea'
        }
    },
    methods: {
        reset() {
            this.value = ''
        },
    },
    watch: {
        value(val) {
            this.$emit('input', val)
            //console.log(val !== '')
            this.isFilled = val !== ''
        },
        defaultValue(value) {
            this.value = value
        },
    }
};

</script>

<style lang="scss">

.o-input-text {
    --height: 3em;

    color: $color-dark;
    border-bottom: $border-grey;
    transition: border-color .2s ease-out;

    html.reduced-motion & {
        transition: none;
    }

    &.is-active {
        border-color: $color-dark
    }

    &.is-active,
    &.is-filled {

        .o-input-text__text {
            transform: translate(0, -100%) scale(.75);
        }
    }

    &.is-filled:not(.is-active) {

        .o-input-text__text {
            color: $color-grey;
        }

    }

    &.is-disabled {
        pointer-events: none;
        color: $color-light;
        background-color: $color-grey;

        .o-input-text__input {
            color: $color-light;
        }
    }
}

.o-input-text__input {
    width: 100%;
    height: var(--height);
    color: $color-dark;

    @at-root textarea#{&} {
        height: calc(var(--height) * 3);
        padding-top: .9em;
        padding-bottom: .9em;
        resize: none;
    }
}

.o-input-text__label {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: var(--height);
    pointer-events: none;
}

.o-input-text__text {
    display: inline-block;
    transform-origin: 0 0;
    transition: all .2s $out-sine;

    html.reduced-motion & {
        transition: none;
    }
}

</style>
