import Vue from 'vue'
import { linkResolver } from 'src/router'


// Prefix url for translation
Vue.filter('resolver', function (handle, slug) {
    if (!handle) {
        return '/'
    }

    return linkResolver(handle, slug)
})
