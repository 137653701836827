<template>
    <div
        :class="className"
        :style="styles"
    >
        <div class="o-slider-nav__inner">
            <button
                class="o-slider-nav__arrow o-slider-nav__arrow--prev"
                :class="{ 'is-disabled' : currentIndex === 0 }"
                @click="$emit('prev')"
                v-cursor
            >
                <icon :icon="vertical ? 'arrow-up' : 'arrow-left'" />
            </button>
            <span
                class="o-slider-nav__number o-slider-nav__number--first | t-small"
                :class="{ 'is-current' : currentIndex === 0 }"
            >
                1
            </span>
            <span class="o-slider-nav__line">
                <span class="o-slider-nav__indicator"></span>
            </span>
            <span
                class="o-slider-nav__number o-slider-nav__number--last | t-small"
                :class="{ 'is-current' : currentIndex === total - 1 }"
            >
                {{ total }}
            </span>
            <button
                class="o-slider-nav__arrow o-slider-nav__arrow--next"
                :class="{ 'is-disabled' : currentIndex === total - 1 }"
                @click="$emit('next')"
                v-cursor
            >
                <icon :icon="vertical ? 'arrow-down' : 'arrow-right'" />
            </button>
        </div>
    </div>
</template>

<script>

import Icon from 'objects/Icon'

export default {
    name: 'SliderNav',
    components: {
        Icon,
    },
    props: {
        currentIndex: {
            type: Number,
            required: true,
        },
        total: {
            type: Number,
            required: true,
        },
        vertical: {
            type: Boolean,
            required: false,
        },
    },
    computed: {
        className() {
            let classname = 'o-slider-nav'

            classname += this.vertical ? ' o-slider-nav--y' : ' o-slider-nav--x'

            return classname
        },
        styles() {
            let style = `--total-items: ${this.total}; --current-item: ${this.currentIndex};`

            return style
        },
    },
};

</script>

<style lang="scss">


.o-slider-nav {
    margin-top: 1em;
    color: $color-grey;
    text-align: center;

    &--x {

        .o-slider-nav__arrow {

            &--prev {
                margin-right: 1em;
            }

            &--next {
                margin-left: 1em;
            }
        }

        .o-slider-nav__number {

            &--first {
                padding-right: 1.5em;
            }

            &--last {
                padding-left: 1.5em;
            }
        }
    }

    &--y {

        .o-slider-nav__inner {
            flex-direction: column;
        }

        .o-slider-nav__arrow {

            &--prev {
                margin-bottom: 1em;
            }

            &--next {
                margin-top: 1em;
            }
        }

        .o-slider-nav__indicator {
            width: inherit;
            height: calc(100%/var(--total-items));
            transform: translate(0, calc(var(--current-item) * 100%));
        }

        .o-slider-nav__line {
            width: 1px;
            height: calc(3em * var(--total-items));
        }

        .o-slider-nav__number {

            &--first {
                padding-bottom: 1.5em;
            }

            &--last {
                padding-top: 1.5em;
            }
        }
    }
}

.o-slider-nav__inner {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
}

.o-slider-nav__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $accessible-size;
    height: $accessible-size;
    cursor: pointer;
    transition: color .2s ease-out, transform .1s;

    &:hover {
        color: $color-dark;
    }

    &:active {
        transform: scale(.9);
    }

    &.is-disabled {
        opacity: 0;
        pointer-events: none;
    }
}

.o-slider-nav__number {
    transition: color .4s $out-quad;

    &.is-current {
        color: $color-dark;
    }
}

.o-slider-nav__line {
    top: .1em;
    display: block;
    width: calc(3em * var(--total-items));
    height: 1px;
    background-color: currentColor;
}

.o-slider-nav__indicator {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: calc(100%/var(--total-items));
    height: inherit;
    background-color: $color-dark;
    transform: translate(calc(var(--current-item) * 100%), 0);
    transition: transform .4s $out-quad;
}

</style>
