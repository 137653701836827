<template>
    <footer class="l-footer">
        <div class="grid">
            <div class="l-footer__inner">
                <ul class="l-footer__list l-footer__list--socials">
                    <li v-for="social in footerData.socials" :key="social.media">
                        <btn
                            tag="a"
                            :href="social.website"
                            :title="social.label"
                            target="_blank"
                            rel="noopener"
                            :simple="true"
                            :label="social.label"
                        />
                    </li>
                </ul>
                <ul class="l-footer__list l-footer__list--pages">
                    <li v-for="page in pages" :key="`footer-link-${page.name}`">
                        <btn
                            tag="router-link"
                            :href="page.handle | resolver"
                            :title="page.name"
                            :simple="true"
                            :label="page.name"
                        />
                    </li>
                </ul>
                <div v-if="footerData && footerData.details" class="l-footer__details | t-content">
                    <div v-if="footerData.details.address" class="l-footer__address">
                        <a
                            v-if="footerData.details.hyperlink"
                            :href="footerData.details.hyperlink"
                            title="See in Google Map"
                            target="_blank"
                            rel="noopener"
                            v-cursor
                        >
                            <address v-html="footerData.details.address.replace('\n', '<br>')"></address>
                        </a>

                        <address v-else v-html="footerData.details.address.replace('\n', '<br>')"></address>
                    </div>
                    <span class="l-footer__phone">
                        (305) 209-0807
                    </span>
                    <div v-if="footerData.details.customTitle" class="l-footer__email">
                        <a
                            :href="`mailto:${footerData.details.customTitle}`"
                            title="Email us"
                            target="_blank"
                            rel="noopener"
                            class="t-link-gradient"
                            v-cursor
                        >
                            {{ footerData.details.customTitle }}
                        </a>
                    </div>
                </div>
            </div>
            <div class="l-footer__bottom">
                <span class="l-footer__copy | t-small">&copy; {{ year }} OUTSMART LABS</span>
                <router-link :to="'privacy' | resolver" class="l-footer__privacy | t-small" v-cursor
                    >Privacy Policy</router-link
                >
            </div>
        </div>
    </footer>
</template>

<script>
import Btn from "components/Btn";

export default {
    name: "AppFooter",
    components: {
        Btn
    },
    data: () => ({
        pages: [
            {
                name: "Work",
                handle: "works"
            },
            {
                name: "Services",
                handle: "services"
            },
            {
                name: "About us",
                handle: "about"
            },
            {
                name: "Blog",
                handle: "blog"
            },
            {
                name: "Careers",
                handle: "careers"
            },
            {
                name: "Contact",
                handle: "contact"
            }
        ]
    }),
    computed: {
        year: () => new Date().getFullYear(),
        footerData() {
            return this.$store.state.global.footer;
        }
    }
};
</script>

<style lang="scss">
.l-footer {
}

.l-footer__inner {
    display: grid;
    grid-gap: 2em var(--grid-gutter-half);
    grid-template-columns: 1fr;
    padding-top: 2em;
    padding-bottom: 2em;

    @media #{md("sm")} {
        grid-template-columns: 2fr 4fr 3fr;
        padding-bottom: 3em;
    }

    @media #{md("md")} {
        grid-template-columns: 1fr 2fr 3fr;
    }
}

.l-footer__list {
    @include reset-list;

    &--pages {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: var(--grid-gutter-half);
    }

    &--socials {
        .c-btn__label {
            text-transform: capitalize;
        }
    }

    a {
        display: inline-block;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
    }
}

.l-footer__details {
    @media #{md("xs")} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: var(--grid-gutter-half);
    }

    @media #{md("sm")} {
        display: block;
        text-align: right;
    }
}

.l-footer__address {
    a {
        display: inline-block;
    }
}

.l-footer__phone {
    display: block;

    @media #{md("xs")} {
        margin-top: 0 !important;
    }

    @media #{md("sm")} {
        margin-top: 1rem !important;
    }
}

.l-footer__email {
    .t-link-gradient {
        &:before {
            opacity: 1;
            transform: scale(1);
        }
    }
}

.l-footer__bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 1em;
    padding-bottom: 1em;

    &:before {
        @include pseudo-el($width: 100vw, $height: 1px, $bg: $color-grey);
        position: absolute;
        top: 0;
        left: calc(50% - 50vw);
    }
}

.l-footer__copy {
}
</style>
