<template>
    <div
        v-if="items.length > 0"
        class="c-section-list"
        v-reveal="reveal"
    >
        <div
            v-if="!flickityEnabled"
            class="c-section-list__assets"
        >
            <blob
                v-for="(item, i) in items"
                ref="blob"
                :key="`section-list-asset-${i}`"
                :class="{ 'is-visible' : selectedIndex === i }"
                :image="item.image[0]"
                :style="item.color ? `--blob-bg: var(--gradient-${item.color});` : false"
            />
        </div>
        <flickity
            ref="flkty"
            :options="flktyOptions"
            class="c-section-list__list"
            :class="{ 'is-dragging' : isDragging }"
        >
            <div
                v-for="(item, i) in items"
                :key="`section-list-item-${i}`"
                class="c-section-list__item"
                :class="{ 'is-active' : (selectedIndex === i)}"
            >
                <router-link
                    :to="`/${pathHandle}/${item.slug}`"
                    class="c-section-list__link"
                    v-cursor.gradient="{ label: blobLabel, gradient: item.color ? `--gradient-${item.color}` : false}"
                >
                    <anim-text
                        ref="title"
                        :text="isServices && item.customTitle ? item.customTitle : item.title"
                        class="c-section-list__label | t-t2"
                        :reveal="false"
                        :visible="(flickityEnabled && (i === selectedIndex)) || (!flickityEnabled && isVisible)"
                        @mouseenter.native="!flickityEnabled ? selectedIndex = i : false"
                    />
                    <blob
                        :image="item.image[0]"
                        class="c-section-list__blob"
                        :animate="flickityEnabled"
                        :style="item.color ? `--blob-bg: var(--gradient-${item.color});` : false"
                    />
                    <btn
                        tag="span"
                        class="c-section-list__cta"
                        :label="blobLabel"
                        :arrowUnder="true"
                    />
                </router-link>
            </div>
        </flickity>

        <slider-nav
            :currentIndex="selectedIndex"
            :total="items.length"
            :vertical="!flickityEnabled"
            @prev="updateSlide(selectedIndex - 1)"
            @next="updateSlide(selectedIndex + 1)"
        />
    </div>
</template>

<script>

import Blob from 'objects/Blob'
import Btn from 'components/Btn';
import AnimText from 'objects/AnimText'
import SliderNav from 'objects/SliderNav'

import Variables from 'src/mixins/variables';

import Flickity from 'vue-flickity'
import debounce from 'lodash/debounce'

export default {
    name: 'SectionList',
    components: {
        Blob,
        Btn,
        AnimText,
        SliderNav,
        Flickity,
    },
    mixins: [ Variables ],
    data: () => ({
        items: [],
        flktyOptions: {
            watchCSS: true,
            cellAlign: 'center',
            prevNextButtons: false,
            pageDots: false,
        },
        initialIndex: -1,
        selectedIndex: 0,
        flickityEnabled: false,
        flickityIsSet: false,
        isDragging: false,
        isVisible: false,
    }),
    props: {
        type: {
            type: String,
            default: 'service'
        }
    },
    created() {
        this.$store
            .dispatch('global/loadList', this.type)
            .then(items => {
                this.items = items

                this.$nextTick(this.setSlider)

            })

        // Window events
        window.addEventListener('resize', this.onResize = debounce(this.setSlider, 101))
    },
    computed: {
        blobLabel() {
            return ( this.type === 'work' ) ? `Discover` : `Explore`
        },
        pathHandle() {

            if ( this.type === 'work' )
                return 'work'
            else
                return `${this.type}s`
        },
        isServices() {
            return this.type === 'service'
        },
        isWorks() {
            return this.type === 'work'
        },
    },
    methods: {
        setSlider() {

            if(this.flickityIsSet || this.flickityEnabled || this.W.w >= 768) {
                this.flickityEnabled = this.W.w < 768
                return
            }

            this.flickityIsSet = true
            this.flickityEnabled = true

            const flkty = this.$refs.flkty

            flkty.on('select', i => {
                this.initialIndex = i
                this.selectedIndex = i
            })

            flkty.on('dragStart', () => this.isDragging = true );
            flkty.on('dragEnd', () => this.isDragging = false );

        },
        updateSlide(index) {
            //console.log('updateSlide', index)
            this.selectedIndex = index
            this.$refs.flkty.select(index)
        },
        prev() {},
        reveal(state) {
            this.isVisible = state.isActive

            if(!this.isVisible) {
                this.initialIndex = -1
            }

            if(this.isVisible) {

                // Highlight first item
                if(this.flickityEnabled) {
                    this.$refs.flkty.select( this.selectedIndex )
                } else {
                    this.initialIndex = this.selectedIndex
                }
            }
        }
    },
    watch: {
        selectedIndex(index) {

            if (index > -1) {
                this.$emit('updateHover', this.items[index].color)
            }
        },
        initialIndex(index) {
        },
    },
    destroyed() {

        // Window events
        window.removeEventListener('resize', this.onResize)
    }
};

</script>

<style lang="scss">

.c-section-list {
    padding-top: 2em;
    padding-bottom: 2em;
    text-align: left;

    .o-slider-nav {

        margin-top: 0;

        @media #{md("sm", "max")} {
            .o-slider-nav__line {
                width: calc(4vw * var(--total-items));
            }
        }
        @media #{md("sm")} {

            position: absolute;
            top: 50%;
            left: calc(var(--main-padding)/-2);
            transform: translate(-50%, -50%);

            .o-slider-nav__line {
                height: calc(2em * var(--total-items));
            }
        }

        @media (min-width: #{$grid-max-width}) {
            left: calc(var(--main-padding)/-2 - 1em);
        }
    }
}

.c-section-list__assets {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    width: 50%;
    height: 100%;

    .o-blob {
        position: absolute;
        top: calc(50% - var(--size)/2);
        left: 0;
        opacity: 0;
        transform: scale(.95);
        transition: opacity .2s $in-quad, transform .2s $in-quad;

        &.is-visible {
            z-index: 1;
            opacity: 1;
            transform: scale(1);
            transition: opacity .3s $out-quad, transform .3s $out-quad;
        }
    }
}

.c-section-list__list {

    @media #{md("sm", "max")} {
        left: calc(50% - 50vw);
        width: 100vw;

        &:after {
            content: 'flickity';
            display: none;
            visibility: hidden;
        }

        .flickity-viewport {
            width: 100%;
            overflow: hidden;
        }

/*
        .flickity-page-dots {
            @include reset-list;
            position: relative;
            bottom: auto;
            display: flex;
            justify-content: center;
            margin-top: 1em;

            .dot {
                width: 1.5em;
                height: 1.5em;
                margin-right: .2em;
                margin-left: .2em;
                border-radius: 53% 47% 64% 36% / 55% 59% 41% 45%;
                background-color: $color-grey;
                opacity: 1;
                transform: scale(.4);
                transition: all .3s $out-quad;

                &:hover {
                    transform: scale(.6) rotate(-20deg);
                }

                &.is-selected {
                    background-color: $color-dark;
                    transform: scale(1) rotate(20deg);
                    transition: all .3s $in-quad;
                }
            }
        }
*/

        &.is-dragging {

            .c-section-list__link {
                pointer-events: none;
            }
        }
    }

    @media #{md("sm")} {
        color: $color-grey;
    }
}

.c-section-list__item {

    @media #{md("sm", "max")} {
        width: calc(7/12 * 100vw);
        margin-right: var(--grid-gutter);
        margin-left: var(--grid-gutter);

        // Avoid blob overflow cuts
        padding-top: 5vw;
        padding-bottom: 5vw;

        text-align: center;

        &.is-active {
            .c-section-list__cta {
                opacity: 1;
                transition: opacity .2s ease-out 300ms;
            }
        }
    }

    @media #{md("xs", "max")} {
        width: calc(9/12 * 100vw);
    }

    @media #{md("sm")} {

        &.is-active {

            .c-section-list__label {
                color: $color-dark;
                transform: scale(1.02);
                transition: all .4s $out-quad;

                &.is-visible:after {
                    opacity: 1;
                }

                &:after {
                    transform: scale(1);
                    transition: opacity .2s ease-out calc(var(--a-at-in-duration) + var(--a-at-in-delay)), transform .6s $out-expo;
                }
            }
        }

        &:not(:first-child) {
            margin-top: 1.5em;
        }

        @for $i from 1 through 15 {

            &:nth-child(#{$i}) {
                $i: $i - 1;

               .c-section-list__label {
                    --a-at-in-delay: #{$i/15}s;
               }
            }
        }
    }
}

.c-section-list__link {
    display: inline-block;

    @media #{md("sm", "max")} {
        width: 100%;
    }

    @media #{md("sm")} {
    }
}

.c-section-list__cta {
    pointer-events: none;
    z-index: 0;
    opacity: 0;
    transition: opacity .1s ease-out 0ms;

    @media #{md("sm", "max")} {
        margin-top: 4vh;
    }

    @media #{md("sm")} {
        display: none;
        visibility: hidden;
    }
}

.c-section-list__label {
    --a-at-in-delay: .4s;

    z-index: 1;
    display: block;

    @media #{md("sm", "max")} {
        margin-bottom: 0.5em;
    }

    @media #{md("sm")} {
        transform-origin: 0 50%;
        transition: all .3s $out-quad;

        &:after {
            @include pseudo-el($height: 1px, $bg: currentColor);
            position: absolute;
            bottom: .13em;
            left: 0;
            opacity: 0;
            transform: scale(0, 1);
            transform-origin: 0 50%;
        }
    }
}

.c-section-list__blob {
    z-index: 0;

    @media #{md("sm")} {
        display: none;
        visibility: hidden;
    }
}

</style>
