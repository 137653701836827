<template>
    <div>
        <app-section>

            <heading
                :header="page.heading"
            />

            <section-list
                type="service"
                class="p-services__list"
            />

        </app-section>

        <builder :builder="page.builder" />

        <push />

    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import Push from 'components/Push';
import Heading from 'components/Heading';
import SectionList from 'components/SectionList';
import Builder from 'builder/Builder';

export default {
    name: 'Services',
    components: {
        AppSection,
        Push,
        Heading,
        SectionList,
        Builder,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: this.page.seo.title
        }
    },
};

</script>

<style lang="scss">

.p-services {}

.p-services__list {
    margin-top: var(--main-padding);
}

</style>
