<template>
    <div
        v-if="data"
        class="b-ordered-list"
    >
        <h2
            v-if="data.customTitle !== null"
            class="b-ordered-list__title"
            v-reveal="revealTitle"
        >
            <anim-text
                tag="h2"
                :text="data.customTitle"
                :reveal="false"
                :visible="titleIsRevealed"
                class="t-small"
            />

            <deco
                deco="curved-down"
                :visible="titleIsRevealed"
                class="b-ordered-list__arrow-title"
            />
        </h2>


        <div class="b-ordered-list__inner">

            <deco
                deco="up-right"
                :visible="listIsRevealed"
                class="b-ordered-list__arrow-list"
            />

            <ol
                v-if="data.contentList.length > 0"
                class="b-ordered-list__list"
                v-reveal="revealList"
            >
                <anim-text
                    v-for="(item, i) in data.contentList"
                    :key="`content-list-${_uid}-${i}`"
                    tag="li"
                    :text="item.item"
                    :reveal="false"
                    :visible="listIsRevealed"
                    class="b-ordered-list__item | t-t3-serif"
                />
            </ol>
        </div>
    </div>
</template>

<script>

import AnimText from 'objects/AnimText'
import Deco from 'objects/Deco'

export default {
    name: 'BlockOrderedList',
    components: {
        AnimText,
        Deco,
    },
    props: {
        data: false,
    },
    data: () => ({
        titleIsRevealed: false,
        listIsRevealed: false,
    }),
    methods: {
        revealTitle(state) {
            this.titleIsRevealed = state.isActive
        },
        revealList(state) {
            this.listIsRevealed = state.isActive
        }
    }
};

</script>

<style lang="scss">

.b-ordered-list {}

.b-ordered-list__title {
    font-size: inherit;
    margin-bottom: 2rem;
}

.b-ordered-list__arrow-title {
    position: absolute;
    top: calc(100% - .5em);
    right: calc(100% + 1em);
}

.b-ordered-list__list {
    @include reset-list;
    counter-reset: item;

    @media #{md("xs")} {
        columns: 2;
        column-gap: calc(var(--grid-gutter) * 2);
    }
}

.b-ordered-list__item {
    display: flex;
    counter-increment: item;
    padding-top: 1em;
    padding-bottom: 1em;
    break-inside: avoid;

    &:before {
        content: counter(item);
        top: -.175em;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 0;
        flex-shrink: 0;
        width: 1.5em;
        height: 1.5em;
        margin-right: .5em;
        line-height: 1;
        background-image: var(--main-gradient);
        border-radius: 50%;
        transform: scale(0);
        transform-origin: 50% 100%;
    }

    @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
            $i: $i - 1;
            --a-at-in-delay: #{$i/10}s;
        }
    }

    .is-safari & .o-at__l {
        overflow: visible;
    }

    &.is-visible {

        &:before {
            transform: scale(1);
            transition: transform calc(var(--a-at-in-duration)/2) $out-quad var(--a-at-in-delay);
        }
    }

    @media #{md("xs")} {
        break-inside: avoid;
    }
}

.b-ordered-list__arrow-list {

    position: absolute;
    top: calc(50% - var(--svg-height)/2);
    left: calc(50% - var(--svg-width)/2);

    &.-up-right {
        --svg-width: 2.5em;
    }

    @media #{md("md")} {

        &.-up-right {
            --svg-width: 5em;
        }
    }
}

</style>
