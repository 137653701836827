/**
** Round number with n decimals
*/

export const round = (x, n=2) => {
  const decimals = 10 ** n
  return Math.round(x * decimals)/decimals
}


/*
** Reduced motion var
*/

export const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches
