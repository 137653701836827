<template>
    <div
        v-if="data"
        class="b-row"
    >
        <span
            v-if="data.background !== null"
            class="b-row__bg"
            :class="`b-row__bg--${data.background}`"
        ></span>

        <div class="b-row__grid">
            <div
                v-for="(col, i) in data.children"
                :key="`col-${i}`"
                class="b-row__col"
            >
                <template v-for="(child, i) in col.children">
                    <!-- Defined child -->
                    <BlockStatsList
                        v-if="child.typeHandle === 'blockStatsList'"
                        :data="child"
                        :highlight="false"
                        class="b-row__item"
                    />
                    <component
                        v-else
                        :is="child.typeHandle"
                        :data="child"
                        class="b-row__item"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<script>

import BlockBlockQuote from 'builder/BlockBlockQuote'
import BlockImageList from 'builder/BlockImageList'
import BlockStatsList from 'builder/BlockStatsList'
import BlockImage from 'builder/BlockImage'
import BlockContent from 'builder/BlockContent'

export default {
    name: 'BlockRow',
    components: {
        BlockBlockQuote,
        BlockImageList,
        BlockStatsList,
        BlockImage,
        BlockContent,
    },
    props: {
        data: false,
    }
};

</script>

<style lang="scss">

.b-row {
}

.b-row__bg {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--main-color);

    &--left {
        right: 0;
        width: calc(100% + var(--main-padding) * 2);
    }

    &--right {
        left: 0;
        width: calc(100% + var(--main-padding) * 2);
    }

    & + .b-row__grid {
        --block-padding: var(--box-padding);
    }
}

.b-row__grid {
    --block-padding: 0;

    display: grid;
    grid-template-row: 1fr;
    grid-gap: var(--grid-gutter);
    padding: var(--block-padding);

    @media #{md("md")} {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 4em var(--box-padding);
    }
}

.b-row__item {

    &:not(:first-child) {
        margin-top: var(--box-padding);

        .b-content__heading {

            //&:only-child {
                padding-top: var(--box-padding);
                border-top: $border-grey;
            //}
        }
    }
}

</style>
