import transforms from './asset-transforms'


/*
** Cradft GraphQL Queries
*/


/*** Snippets ***/

// Default data
const defaultData = `
title
id
slug
`

// SEO
const seo = `
seo {
    title
    description
}
`

// Image
const imageData = (size=false) => {
    let data = `
        url
        title
        width
        height
        ... on images_Asset {
            alt
            desc
        }
    `


    let transform = transforms[size]


    if (size && typeof transform !== 'undefined') {
        data += `
            ${transform.url}
        `
    }

    return data
}

const image = (size) => {
    return `
        image {
            ${imageData(size)}
        }
    `
}

// Images
const images = (size) => {
    return `
        images {
            ${imageData(size)}
        }
    `
}


// Heading
const header = `
heading {
    ... on heading_BlockType {
        sup
        mainTitle
        desc
    }
}
`

const defaultSection = `
    ${defaultData}
    sectionHandle
`

// NEO Builder
const builder = `
builder {
    ... on builder_blockTeam_BlockType {
        typeHandle

    }
    ... on builder_blockBigText_BlockType {
        typeHandle

        desc
    }
    ... on builder_blockBlockQuote_BlockType {
        typeHandle

        quote {
            ...on quote_BlockType {
                author
                text
            }
        }
    }
    ... on builder_blockClients_BlockType {
        typeHandle

        customTitle
        selectedClients {
            ...on selectedClients_BlockType {
                clientId
            }
        }
    }
    ... on builder_blockDropdowns_BlockType {
        typeHandle

        customTitle

        dropdowns {
            ...on dropdowns_BlockType {
                heading
                sub
                body
                quote
            }
        }

    }
    ... on builder_blockFeedback_BlockType {
        typeHandle


        customTitle
        position
        desc
    }
    ... on builder_blockImageList_BlockType {
        typeHandle
        ${images()}
    }
    ... on builder_blockOrderedList_BlockType {
        typeHandle

        customTitle
        contentList {
            ... on contentList_BlockType {
                item
            }
        }
    }
    ... on builder_blockStatsList_BlockType {
        typeHandle

        headingList {
            ... on headingList_BlockType {
                sup
                mainTitle
                desc
            }
        }
    }
    ... on builder_blockSlider_BlockType {
        typeHandle
        selectedWorks {
            ...on work_work_Entry {
                ${defaultData}
                campaignName
                color
                ${image()}
            }
        }
    }


    ... on builder_blockRow_BlockType {
        typeHandle

        background
        children {
            ...on builder_blockColumn_BlockType {
                typeHandle

                children {
                    ...on builder_blockContent_BlockType {
                        typeHandle

                        ${header}
                        body
                        button {

                            ...on button_BlockType {
                                buttonLabel
                                buttonEntry {
                                    title
                                    uri
                                }
                            }
                        }
                    }
                    ...on builder_blockImage_BlockType {
                        typeHandle

                        ${image()}
                        blobImage
                        hasDescription
                    }
                    ... on builder_blockImageList_BlockType {
                        typeHandle

                        ${images()}
                    }
                    ... on builder_blockBlockQuote_BlockType {
                        typeHandle

                        quote {
                            ...on quote_BlockType {
                                author
                                text
                            }
                        }
                    }
                    ... on builder_blockStatsList_BlockType {
                        typeHandle

                        headingList {
                            ... on headingList_BlockType {
                                sup
                                mainTitle
                                desc
                            }
                        }
                    }
                }
            }
        }
    }
}
`


/*** Globals ***/

// Main navigation
export const nav = `{
    globalSet(handle: "nav") {
        ... on nav_GlobalSet {
            navNodes {
                ...on navNodes_BlockType {
                    entry {
                        ${defaultData}
                        sectionHandle
                    }
                    label
                }
            }
        }
    }
}`

// Social links
export const socials = `{
    globalSet(handle: "socials") {
        ... on socials_GlobalSet {
            socials {
                ...on socials_BlockType {
                    media
                    website
                }
            }
        }
    }
}`

// Client list
export const clients = `{
    globalSet(handle: "clients") {
        ... on clients_GlobalSet {
            clients {
                ...on clients_BlockType {
                    id
                    clientName
                    website
                    logo {
                        ${imageData()}
                    }
                }
            }
        }
    }
}`

// Footer
export const footer = `{
    socials: globalSet(handle: "socials") {
        ... on socials_GlobalSet {
            socials {
                ...on socials_BlockType {
                    media
                    website
                }
            }
        }
    }
    details: globalSet(handle: "details") {
        ... on details_GlobalSet {
            address
            hyperlink
            customTitle
        }
    }
}`

// Social links
export const general = `{
    globalSet(handle: "general") {
        ... on general_GlobalSet {
            customTitle
        }
    }
}`



/*** Pages ***/

// Homepage
export const home = `{
    entry(section: "home") {
        ${defaultSection}

        ...on home_home_Entry {
            ${header}
            servicesTitle
            aboutTitle
            worksTitle
            ${builder}
            ${seo}
        }
    }
}`

// Works
export const works = `{
    entry(section: "works") {
        ${defaultSection}

        ...on works_works_Entry {
            ${header}
            ${builder}
            ${seo}
        }
    }
}`

// Work
export const work = (slug) => `{
    entry(section: "work", slug: "${slug}") {
        ${defaultSection}

        ...on work_work_Entry {
            campaignName
            desc
            color
            goals {
                ...on goals_BlockType {
                    label
                    increase
                }
            }
            ${image('portrait')}
            selectedServices {
                ${defaultData}
            }
            customServices {
                label
            }
            ${builder}
            ${seo}
        }
    }
}`

// Services
export const services = `{
    entry(section: "services") {
        ${defaultSection}

        ...on services_services_Entry {
            ${header}
            ${builder}
            ${seo}
        }
    }
}`

// Service
export const service = (slug) => `{
    entry(section: "service", slug: "${slug}") {
        ${defaultSection}

        ...on service_service_Entry {
            customTitle
            desc
            color
            ${image('portrait')}
            ${builder}
            ${seo}
        }
    }
}`


// Blog
export const blog = `{
    entry(section: "blog") {
        ${defaultSection}

        ...on blog_blog_Entry {
            ${header}
            ${seo}
        }
    }
}`

// Blog Article
export const article = (slug) => `{
    entry(section: "article", slug: "${slug}") {
        ${defaultSection}

        author {
            fullName
            ...on User {
                desc
                ${image('user')}
            }
        }
        postDate

        ...on article_article_Entry {
            customTitle
            desc
            color
            articleCategories {
                ${defaultData}
            }
            ${image()}
            ${builder}
            ${seo}
        }
    }
}`

// Careers
export const careers = `{
    entry(section: "careers") {
        ${defaultSection}

        ...on careers_careers_Entry {
            ${header}
            ${seo}
        }
    }
}`

// Job
export const job = (slug) => `{
    entry(section: "job", slug: "${slug}") {
        ${defaultSection}
        postDate

        ...on job_job_Entry {
            customTitle
            desc
            color
            ${image()}
            ${builder}
            ${seo}
        }
    }
}`

// About
export const about = `{
    entry(section: "about") {
        ${defaultSection}

        ...on about_about_Entry {
            ${header}
            ${builder}
            ${seo}
        }
    }
}`

// Contact
export const contact = `{
    entry(section: "contact") {
        ${defaultSection}

        ...on contact_contact_Entry {
            ${header}
            ${image()}
            offices {
                ...on offices_BlockType {
                    city
                    desc
                    ${image()}
                }
            }
            ${seo}
        }
    }
}`

// Privacy
export const privacy = `{
    entry(section: "privacy") {
        ${defaultSection}

        ...on privacy_privacy_Entry {
            ${header}
            ${builder}
            ${seo}
        }
    }
}`

// Error 404
export const error404 = `{
    entry(section: "error404") {
        ${defaultSection}

        ...on error404_error404_Entry {
            ${header}
            ${seo}
        }
    }
}`


/*** Misc ***/

// Work List
export const workList =  `{
    entries(section: "work") {
        ${defaultSection}
        ...on work_work_Entry {
            color
            ${image()}
        }
    }
}`

// Service List
export const serviceList =  `{
    entries(section: "service", level: 1) {
        ${defaultSection}
        ...on service_service_Entry {
            customTitle
            color
            ${image()}
        }
    }
}`

// Team
export const team =  `{
    entries(section: "team") {
        ${defaultData}
        ... on team_team_Entry {
            position
            email
            phone
            desc
            signature {
                ${imageData()}
            }
            ${image()}
            socials {
                ...on socials_BlockType {
                    media
                    website
                }
            }
        }
    }
}`

// Blog list
export const blogList = `{
    entries(section: "article") {
        ${defaultSection}

        author {
            fullName
        }
        postDate

        ...on article_article_Entry {
            articleCategories {
                ${defaultData}
            }
            ${image()}
        }
    }
}`

// Article siblings
export const articleSiblings = (id) => `{
    prev: entry(prevSiblingOf: ${id}) {
        ${defaultData}
    }
    next: entry(nextSiblingOf: ${id}) {
        ${defaultData}
    }
}`

// Careers list
export const careersList = `{
    entries(section: "job") {
        ${defaultSection}

        author {
            fullName
        }
        postDate

        ...on job_job_Entry {
            ${image()}
        }
    }
}`




export default {
    // Globals
    nav,
    socials,
    clients,
    footer,
    general,

    // Pages
    home,
    works,
    work,
    services,
    service,
    blog,
    article,
    careers,
    job,
    about,
    contact,
    privacy,
    error404,

    // Misc
    workList,
    serviceList,
    team,
    blogList,
    careersList,
    articleSiblings,
}
