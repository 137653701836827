<template>
    <section
        v-if="builder.length > 0"
        class="b"
    >
        <div class="grid">
            <div
                v-for="(block, i) in builder"
                :key="`block-${i}`"
                class="b__item"
            >
                <!-- Defined block -->
                <component
                    :is="block.typeHandle"
                    :data="blockHasData(block.typeHandle) ? block : false"
                />

                <slot :name="`after-${i+1}`" />
            </div>
        </div>
    </section>
</template>

<script>

import BlockTeam from 'builder/BlockTeam'
import BlockBigText from 'builder/BlockBigText'
import BlockBlockQuote from 'builder/BlockBlockQuote'
import BlockClients from 'builder/BlockClients'
import BlockDropdowns from 'builder/BlockDropdowns'
import BlockFeedback from 'builder/BlockFeedback'
import BlockImageList from 'builder/BlockImageList'
import BlockOrderedList from 'builder/BlockOrderedList'
import BlockStatsList from 'builder/BlockStatsList'
import BlockSlider from 'builder/BlockSlider'
import BlockRow from 'builder/BlockRow'

export default {
    name: 'Builder',
    props: {
        builder: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        BlockTeam,
        BlockBigText,
        BlockBlockQuote,
        BlockClients,
        BlockDropdowns,
        BlockFeedback,
        BlockImageList,
        BlockOrderedList,
        BlockStatsList,
        BlockSlider,
        BlockRow,
    },
    methods: {
        blockHasData(blockType) {
            return blockType !== 'blockTeam'
        },
    },
};

</script>

<style lang="scss">

.b {
    margin-bottom: var(--section-padding);
}

.b__item {

    &:not(:first-child) {
        margin-top: var(--section-padding);

        .b-slider {
            padding-top: 0;
        }
    }
}

</style>
