<template>
    <div
        v-if="data"
        class="b-quote"
    >
        <deco
            deco="down-right"
            :visible="isRevealed"
            class="b-quote__arrow"
            :reveal="true"
        />
        <app-quote
            :data="data.quote"
            @reveal="revealHandler"
        />
        <deco
            deco="line-down"
            :visible="isRevealed"
            class="b-quote__line"
            :reveal="true"
        />
    </div>
</template>

<script>

import AppQuote from 'objects/AppQuote'
import Deco from 'objects/Deco'

export default {
    name: 'BlockBlockQuote',
    components: {
        AppQuote,
        Deco,
    },
    props: {
        data: false,
    },
    data: () => ({
        isRevealed: false
    }),
    methods: {
        revealHandler(revealed) {
            this.isRevealed = revealed
        }
    }
};

</script>

<style lang="scss">

.b-quote {

    @media #{md("xs")} {
        margin-right: auto;
        margin-left: auto;
        width: calc(5/6 * 100%);
    }

    @media #{md("md")} {
        width: calc(3/4 * 100%);
    }

    @media #{md("lg")} {
        width: calc(2/3 * 100%);
    }
}

.b-quote__arrow {
    left: calc(-1 * var(--svg-width));
}

.b-quote__line {
    padding-top: 1em;
    padding-left: 75%;
}

</style>
