<template>
    <span
        v-if="!isTouch"
        :class="className"
        :style="styles"
    >
        <span class="o-cursor__blob">
            <blob/>
        </span>
        <span
            v-if="label"
            class="o-cursor__label | t-small"
        >
            {{label}}
        </span>
    </span>
</template>


<script>


import Blob from 'objects/Blob';

import gsap from 'gsap';
import { EventBus } from 'src/event-bus'

export default {
    name: 'AppCursor',
    components: {
        Blob,
    },
    data: () => ({
        isHidden: false,
        isActive: false,
        state: [],
        isScrolling: false,
        scrollYPerc: 0,
        firstLoad: true
    }),
    mounted() {

        // Document events
        document.addEventListener('mousemove', (e) => {
            this.move(e.clientX, e.clientY)
        })

        // Window events
        window.addEventListener('mousedown', () => {
            this.isActive = true
        })

        window.addEventListener('mouseup', () => {
            this.isActive = false
        })

        window.addEventListener('scroll', this.scroll)

        // Hover
        EventBus.$on('cursor-hover-in', (params) => {

            this.state.push({
                type: params.type ? params.type : false,
                data: params.data ? params.data : false,
            })
        })

        EventBus.$on('cursor-hover-out', () => {
            this.state.pop()
        })

        // Set active
        EventBus.$on('cursor-set-active', (activate) => {
            this.isActive = activate
        })

        // Reset
        EventBus.$on('cursor-reset', this.reset)

        // Update position
        EventBus.$on('cursor-update-position', ({x, y}) => {
            this.move(x, y)
        })

        EventBus.$on('loaderStart', () => {
            this.isHidden = true
            this.reset()
        })
        EventBus.$on('loaderEnd', () => {
            this.isHidden = false
        })

        setTimeout(() => this.firstLoad = false, 1000);
    },
    computed: {
        isTouch() {
            return 'ontouchstart' in window
        },
        stateLength() {
            return this.state.length
        },
        type() {
            return this.stateLength > 0 ? this.state[this.stateLength - 1].type : false
        },
        data() {
            return this.stateLength > 0 ? this.state[this.stateLength - 1].data : false
        },
        label() {
            return this.data && this.data.label ? this.data.label : false
        },
        className() {
            let className = 'o-cursor'

            if (this.firstLoad) {
                className += '-firstload'
            } else if(this.type) {
                className += ` -${this.type}`
            }
            if (this.isActive) {
                className += ' is-active'
            }
            if (this.isHidden) {
                className += ' -hide'
            }

            return className
        },
        styles() {
            let styles = ''

            if (this.type === 'gradient' && this.data) {
                styles += `--main-gradient: var(${this.data.gradient});`
            }

            return styles
        },
    },
    methods: {
        move(x, y) {
            gsap.to(this.$el, .2, {
                x,
                y,
                ease: 'sine.out',
            })
        },
        reset() {
            this.isActive = false

            this.state = []
        }
    },
}

</script>

<style lang="scss">

.o-cursor {
    --size: 5.25em;

    z-index: 300;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: var(--size);
    height: var(--size);
    margin-top: calc(var(--size)/-2);
    margin-left: calc(var(--size)/-2);
    pointer-events: none;
    mix-blend-mode: overlay;
    will-change: transform;

    // Fix chrome bug
    &.-firstload {
        opacity: 0;

        .o-cursor__blob {
            transform: scale(1);
        }
    }

    // Highlight
    &.-hover {

        .o-cursor__blob {
            opacity: .5;
            transform: scale(.1);
        }
    }

    &.-gradient {
        mix-blend-mode: initial;

        .o-cursor__blob {
            transform: scale(1);

            .o-blob:after {
                opacity: 1;
            }
        }
    }

    // Hide
    &.-hide {

        .o-cursor__blob {
            transform: scale(0);
        }
    }
}

.o-cursor__blob {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    transform: scale(.2);
    transition: all .4s $out-circ;
    will-change: transform;

    .o-blob:after {
        @include pseudo-el;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity .4s ease-out;
    }
}

.o-cursor__label {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
}
</style>
