import { fetchApi } from 'src/graphql/config'
import queries from 'src/graphql/queries'

/*
** Pages
*/

// State
const state = {
    pages: [],
}

// Getters
const getters = {
    getPageById: state => id => state.pages.find(page => page.id === id),
    getPageByHandle: state => section => state.pages.find(page => page !== null && page.sectionHandle === section),
    getPageBySlug: state => slug => state.pages.find(page => page.slug === slug),
    getPagesCount: state => state.pages.length,
}

// Actions
const actions = {
    loadSingle(store, handle) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Search if page already exists
            const page = store.getters.getPageByHandle(handle)

            // If page already exists, resolve with the page value
            if(typeof page !== 'undefined') {
                resolve(page)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // If page doesn't exist, load page
            } else {

                fetchApi(queries[handle])
                    .then(r => {
                        store.commit('addPage', r.entry)
                        resolve(r.entry)

                        // End load
                        store.dispatch('loader/endLoad', null, {root: true});
                    })
            }

        })
    },
    loadEntry(store, {handle, slug}) {

        if (handle === 'article') {
            return store.dispatch('listing/loadArticle', slug, {root: true});
        } else if (handle === 'job') {
            return store.dispatch('listing/loadJob', slug, {root: true});
        } else {

            return new Promise((resolve, reject) => {

                // Start load
                store.dispatch('loader/startLoad', null, {root: true});

                // Search if page already exists
                const page = store.getters.getPageBySlug(slug)

                // If page already exists, resolve with the page value
                if(typeof page !== 'undefined') {
                    resolve(page)

                    // End load
                    store.dispatch('loader/endLoad', null, {root: true});

                // If page doesn't exist, load page
                } else {
                    fetchApi(queries[handle](slug))

                        .then(r => {

                            if ( r.entry === null ) {
                                reject(r)
                            } else {
                                store.commit('addPage', r.entry)
                                resolve(r.entry)
                            }

                            // End load
                            store.dispatch('loader/endLoad', null, {root: true});
                        })
                }

            })
        }
    },
}

// Mutations
const mutations = {
    addPage(state, page) {
        state.pages.push(page)
    },
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
