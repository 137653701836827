<template>
    <span
        :class="className"
        ref="loader"
    >
        <span
            class="o-loader__bg"
            ref="bg"
        ></span>
        <span
            class="o-loader__wave"
            ref="wave"
        ></span>
    </span>
</template>


<script>

import Variables from 'src/mixins/variables';

import { EventBus } from 'src/event-bus'

import gsap from 'gsap';

export default {
    name: 'Loader',
    mixins: [ Variables ],
    data: () => ({
        firstload: true,
        animationState: null,
    }),
    created() {
        EventBus.$emit('loaderStart')
    },
    mounted() {

        this.in()
        let firstLoadStart = new Date()

        // Watch load state
        this.$store.watch(() => this.$store.getters['loader/isLoading'], (loading) => {

            // Hide first loader when load is ended
            if (this.firstload && !loading) {
                let delay = (firstLoadStart - new Date) + 800
                delay = delay < 0 ? 0 : delay

                setTimeout(this.firstLoadOut, delay);
                return
            }

            if (loading) {
                this.in()
            } else if(!loading) {
                this.out()
            }
        })
    },
    computed: {
        className() {
            let classname = 'o-loader'

            if(this.firstload) {
                classname += ' first-load'
            }

            if(this.animationState) {
                classname += ` ${this.animationState} is-loading`
            }

            return classname
        }
    },
    methods: {
        firstLoadOut() {
            const $firstloadIcon = document.getElementById('firstload-icon')
            gsap.to($firstloadIcon, {
                duration: .3,
                opacity: 0,
                scale: 0,
                ease: 'power3.in',
                onComplete: () => {
                    $firstloadIcon.remove()
                    this.firstload = false
                }
            })

            this.out()
        },
        in() {
            EventBus.$emit('loaderStart')
            this.animationState = 'motion-in'
        },
        out() {
            // Scroll to top
            window.scroll(0, 0)

            this.animationState = 'motion-out'

            setTimeout(() => {
                this.animationState = null
                EventBus.$emit('loaderEnd')
            }, 800);
        }
    },
}

</script>

<style lang="scss">

.o-loader {
    z-index: 80;
    position: fixed;
    top: 0;
    left: 200%;
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: left 0s .6s;

    &.first-load {
        z-index: 100;

        .o-loader__bg {
            opacity: 1;
            transition: none;
        }
    }

    &.is-loading {
        left: 0;
        transition: none;

        .o-loader__wave {

            &:after {
                animation: a-loader-color 2s ease-in-out alternate infinite;
            }
        }
    }

    &.motion-in {

        .o-loader__wave {
            transform-origin: 50% 0;
            animation: a-loader-wave-in .8s $in-out-quad forwards;
        }

        .o-loader__bg {
            opacity: 1;
            transition: opacity 0s .8s;
        }
    }

    &.motion-out {

        .o-loader__wave {
            transform-origin: 50% 100%;
            animation: a-loader-wave-out .8s $in-out-quad forwards;
        }

        .o-loader__bg {
            opacity: 0;
            transition: opacity .4s ease-out .4s;
        }
    }
}

.o-loader__bg,
.o-loader__wave {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
}

.o-loader__bg {
    background-color: $color-light;
    opacity: 0;
}

.o-loader__wave {
    border-radius: 75% 75% 0 0 / 50% 50% 0 0;
    transform: translateY(50%) scaleY(.5);
    transform-origin: 50% 100%;
    will-change: transform;
    overflow: hidden;

    &:after {
        @include pseudo-el($width: 200vw, $bg: $color-blue-light linear-gradient($gradient-primary));
        position: absolute;
        top: 0;
        left: 0;
        will-change: transform;
    }
}

@keyframes a-loader-color {
    from {
        transform: translate(0);
    }
    to {
        transform: translate(-50%, 0);
    }
}

@keyframes a-loader-wave-in {
    from {
        border-radius: 0 0 75% 75% / 0 0 50% 50%;
        transform: translateY(-50%) scaleY(.5);
    }
    to {
        border-radius: 0;
        transform: translateY(0) scaleY(1);
    }
}

@keyframes a-loader-wave-out {
    from {
        border-radius: 0;
        transform: translateY(0) scaleY(1);
    }
    to {
        border-radius: 75% 75% 0 0 / 50% 50% 0 0;
        transform: translateY(50%) scaleY(.5);
    }
}

</style>
