import Vue from 'vue';
import VueRouter from 'vue-router'
import store from 'src/store'

import Home from 'templates/views/Home'
import About from 'templates/views/About'
import Contact from 'templates/views/Contact'
import Privacy from 'templates/views/Privacy'
import Services from 'templates/views/Services'
import Service from 'templates/views/Service'
import Works from 'templates/views/Works'
import Work from 'templates/views/Work'
import Listing from 'templates/views/Listing'
import Post from 'templates/views/Post'

import Error404 from 'templates/views/Error404'

import { EventBus } from 'src/event-bus'

// Use plugin
Vue.use(VueRouter)

const routes = [
    {
        name: 'home',
        title: 'Homepage',
        path: '/',
        component: Home,
        meta: {
            section: 'home',
            type: 'single',
        }
    },
    {
        name: 'works',
        title: 'Works',
        path: '/work',
        component: Works,
        meta: {
            section: 'works',
            type: 'single'
        }
    },
    {
        name: 'work',
        title: 'Work',
        path: '/work/:slug',
        component: Work,
        meta: {
            section: 'work',
            type: 'channel',
            parent: 'work'
        }
    },
    {
        name: 'services',
        title: 'Services',
        path: '/services',
        component: Services,
        meta: {
            section: 'services',
            type: 'single',
        }
    },
    {
        name: 'service',
        title: 'Service',
        path: '/services/:slug',
        component: Service,
        meta: {
            section: 'service',
            type: 'structure',
            parent: 'services'
        }
    },
    {
        name: 'subService',
        title: 'SubService',
        path: '/services/:parent/:slug',
        component: Service,
        meta: {
            section: 'service',
            type: 'structure',
            parent: 'services'
        }
    },
    {
        name: 'blog',
        title: 'Blog',
        path: '/blog',
        component: Listing,
        meta: {
            section: 'blog',
            type: 'single',
        }
    },
    {
        name: 'article',
        title: 'Article',
        path: '/blog/:slug',
        component: Post,
        meta: {
            section: 'article',
            type: 'channel'
        }
    },
    {
        name: 'careers',
        title: 'Careers',
        path: '/careers',
        component: Listing,
        meta: {
            section: 'careers',
            type: 'single',
        }
    },
    {
        name: 'job',
        title: 'job',
        path: '/careers/:slug',
        component: Post,
        meta: {
            section: 'job',
            type: 'channel'
        }
    },
    {
        name: 'about',
        title: 'About',
        path: '/about',
        component: About,
        meta: {
            section: 'about',
            type: 'single',
        }
    },
    {
        name: 'contact',
        title: 'Contact',
        path: '/contact',
        component: Contact,
        meta: {
            section: 'contact',
            type: 'single',
        }
    },
    {
        name: 'privacy',
        title: 'Privacy',
        path: '/privacy-policy',
        component: Privacy,
        meta: {
            section: 'privacy',
            type: 'single',
        }
    },
    {
        name: 'contents',
        title: 'contents',
        path: '/content',
        redirect: '/'
    },
    {
        name: 'error404',
        title: 'Error 404',
        path: '/404',
        component: Error404,
        meta: {
            section: 'error404',
            type: 'single',
        }
    },
    {
        path: '*',
        redirect: '/404'
    }
]

// Create the router with the routes
const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
    linkActiveClass: 'is-current',
})

// Before each route load
router.beforeEach((to, from, next) => {

    EventBus.$emit('cursor-reset')
    store.dispatch('loader/startLoad');
    store.dispatch('global/loadContent', to)

    // Loader cover duration
    setTimeout(() => {
        store.dispatch('global/updatePage', false)
        next()
    }, 800);

})

router.afterEach(() => {
    store.dispatch('loader/endLoad');
})

// Link resolver
const linkResolver = (handle) => {

    const route = routes.find(route => route.name === handle)

    if(route) {
        return route.path
    } else {
        return '/'
    }
}


// Export
export default router
export { router, routes, linkResolver }
