<template>
    <div
        v-if="data"
        class="b-dropdown"
    >
        <blob class="b-dropdown__blob b-dropdown__blob--1" :parallax="-.2" />
        <blob class="b-dropdown__blob b-dropdown__blob--2" :parallax=".6" />
        <blob class="b-dropdown__blob b-dropdown__blob--3" :parallax="-.7" />
        <h2
            v-if="data.customTitle !== null"
            class="b-dropdown__title | t-small"
        >
            {{ data.customTitle }}
        </h2>

        <accordion
            v-if="data.dropdowns.length > 0"
            v-for="(dropdown, i) in data.dropdowns"
            :key="`dropdowns-${_uid}-${i}`"
        >
            <template v-slot:title>
                <div>
                    <h3
                        v-if="dropdown.heading !== null"
                        class="t-t2-serif"
                    >
                        {{ dropdown.heading }}
                    </h3>
                    <h4
                        v-if="dropdown.sub !== null"
                        class="t-small"
                    >
                        {{ dropdown.sub }}
                    </h4>
                </div>
            </template>

            <div class="b-dropdown__content">
                <div
                    v-if="dropdown.body !== null"
                    class="t-cms"
                    v-html="dropdown.body"
                ></div>
                <app-quote
                    v-if="dropdown.quote !== null"
                    :data="{ text: dropdown.quote }"
                    :revealEnabled="false"
                />
            </div>
        </accordion>
    </div>
</template>

<script>

import Blob from 'objects/Blob'
import AnimText from 'objects/AnimText'
import AppQuote from 'objects/AppQuote'
import Accordion from 'components/Accordion'

export default {
    name: 'BlockDropdowns',
    components: {
        Blob,
        AnimText,
        AppQuote,
        Accordion,
    },
    props: {
        data: false,
    },
};

</script>

<style lang="scss">

.b-dropdown {}

.b-dropdown__blob {
    position: absolute;

    &--1 {
        --size: 5%;

        top: 20%;
        left: 0;
    }

    &--2 {
        --size: 8%;

        top: 5%;
        right: 0;
    }

    &--3 {
        --size: 40%;

        top: 25%;
        right: 0;
    }
}

.b-dropdown__title {
    margin-bottom: 2rem;
}

.b-dropdown__content {
    display: grid;
    grid-gap: 2em;

    @media #{md("sm")} {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: var(--box-padding);
    }
}

</style>
